import React, {useEffect} from 'react';
import {Dimensions, Image} from 'react-native';
const intro = require('@assets/img/intro.png');
import styled from 'styled-components/native';
import { LinearGradient } from 'expo-linear-gradient';
import {useNavigate} from 'react-router-dom';

const Intro = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(async () => {
      if (sessionStorage.accessToken) {
        navigate('/main');
      } else {
        navigate('/login');
      }
    }, 1200);
  }, []);

  return (
    <Background colors={['#0C0C2D', '#0D1220', '#333365']}>
      <Image source={intro} alt="intro" style={{ resizeMode: 'cover', width: '100px', height: '61px' }} />
    </Background>
  );
};

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;
const Background = styled(LinearGradient)`
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  justify-content: center;
  align-items: center;
  height: ${height};
  width: 100%;
  /* height: '100%',
      width: '100%', */
`;
export default Intro;
