import CustomAxios from '../common/CustomAxios';

export default class P2P {
  public static info = async () => {
    const res = await CustomAxios.get('/p2p/info/regist');
    return res.data;
  };

  public static regist = async (
    symbol: string,
    amount: number,
    type: string,
    bank: string,
    address: string,
    NONBasePrice: number,    
    p2pFeeRate: number,
    owner: string,
    hopedQuote: string,
    currentSale?: number,
  ) => {
    const payload = {
      symbol,
      amount,
      type,
      bank,
      address,
      NONBasePrice,      
      p2pFeeRate,
      owner,
      hopedQuote,
      currentSale,
    };
    const res = await CustomAxios.post('/p2p/regist', payload);
    return res.data;
  };

  public static cancel = async (pIdx: string) => {
    const res = await CustomAxios.put(`/p2p/cancel/regist/${pIdx}`, {});
    return res.data;
  };

  public static list = async (type: string, limit: number, offset: number) => {
    const res = await CustomAxios.get(
      '/p2p/history' + `/?type=${type}&limit=${limit}&offset=${offset}`,
    );
    return res.data;
  };

  public static detail = async (pIdx: string) => {
    const res = await CustomAxios.get(`/p2p/detail/${pIdx}`);
    return res.data;
  };

  public static globalList = async (
    type: string,
    limit: number,
    offset: number,
  ) => {
    const res = await CustomAxios.get(
      '/p2p/global/list' + `/?type=${type}&limit=${limit}&offset=${offset}`,
    );
    return res.data;
  };

  public static successList = async (
    type: string,
    limit: number,
    offset: number,
  ) => {
    const res = await CustomAxios.get(
      '/p2p/success/list' + `/?type=${type}&limit=${limit}&offset=${offset}`,
    );
    return res.data;
  };
}
