import Transaction from '@model/transaction/Transaction';

export default class TransactionController {
  static async transfer(
    coin: any,
    address: string,
    amount: string,
    gasPrice: number,
    gasLimit: number,
    code: string,
    setSuccess: React.Dispatch<React.SetStateAction<boolean>>,
    setTxHash: React.Dispatch<React.SetStateAction<string>>,
    coinBalance: any,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    t: any,
    myAddress: string,
  ) {
    try {
      setLoading(true);
      let res = await Transaction.transfer(
        coin.state,
        address,
        amount,
        gasPrice,
        gasLimit,
        code,
      );
      setSuccess(true);
      setLoading(false);
      setTxHash(res.txHash);
      coinBalance();
    } catch (err: any) {
      setLoading(false);
      if (err.response) {
        if (
          err.response.data.message ===
          'Returned error: insufficient funds for gas * price + value'
        ) {
          alert(t('withdraw.outOfGas') + myAddress + t('withdraw.outOfGas2'))
        } else {
          alert(err.response.data.message);
        }
      } else if (err.message) {
        alert(err.message);
      }
    }
  }

  static async gasOption(
    setGasOption: React.Dispatch<React.SetStateAction<{}>>,
    setGasPrice: React.Dispatch<React.SetStateAction<any>>,
    setGasLimit: React.Dispatch<React.SetStateAction<any>>,
  ) {
    try {
      const res = await Transaction.gas();
      setGasOption(res.result);
      setGasLimit(res.result.slow);
      setGasPrice(res.result.slow);
    } catch (err: any) {
      if (err.response) {
        console.log(err.response.data.message);
      } else if (err.message) {
        console.log(err.message);
      }
    }
  }
}
