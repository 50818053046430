import React, { useEffect, useState } from 'react';
import {
  View,
  Dimensions,
  Text,
  TouchableOpacity,
  Linking,
} from 'react-native';
import styled from 'styled-components/native';
import TopNav from '@components/topNav/TopNav';

import { Basic_Button } from '@components/button/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import Transaction from '@model/transaction/Transaction';
const Coin_symbol_ETH_61 = require('@assets/img/coin_symbol_ETH_61.png');
const Coin_symbol_BAGO61 = require('@assets/img/coin_symbol_BAGO_61.png');
const transaction_deposit = require('@assets/img/transaction_deposit.png');
const transaction_withdraw = require('@assets/img/transaction_withdraw.png');
const transaction_link = require('@assets/img/transaction_link.png');
import SignController from '@controller/Sign/SignController';
import { useTranslation } from 'react-i18next';

const TransactionHistory = () => {
  const { t } = useTranslation('locale');
  const navigate = useNavigate();
  const coin = useLocation();
  const listPerPage = 5;
  const [transactionHistroy, setTransactionHistory]: any = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const getTransactionHistroy = async () => {
    let res = await Transaction.history(
      coin.state,
      currentPage * listPerPage,
      listPerPage,
    );
    setTransactionHistory([...transactionHistroy, ...res.rows]);
    setTotalCount(res.totalCount);
  };

  const nextPage = () => setCurrentPage(currentPage + 1);

  useEffect(() => {
    getTransactionHistroy();
  }, [currentPage]);

  const [balanceList, setBalanceList] = useState([]);
  const [priceList, setPriceList] = useState({});
  const balance = () => SignController.balance(setBalanceList);
  const price = () => SignController.price(setPriceList);
  const regexp = (num: number) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const regexpWon = (num: number) => {
    num = Math.floor(num);
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const bogoxTrans = () => {
    if (coin.state === 'BAGO' || coin.state === 'BAGOX') {
      return 'BAGO';
    } else {
      return coin.state;
    }
  };
  useEffect(() => {
    balance();
    price();
  }, []);
  return (
    <Container>
      <TopNav navigate={navigate}>{coin.state}</TopNav>
      <Main_Wrap>
        <Token_Wrap>
          {coin.state === 'BNB' && <img src={Coin_symbol_ETH_61} alt="" />}
          {(coin.state === 'BAGO' ||
            coin.state === 'BAGOX' ||
            coin.state === 'BATY') && <img src={Coin_symbol_BAGO61} alt="" />}
          <Token_Balance>
            {balanceList[coin.state] && regexp(balanceList[coin.state])}{' '}
            {coin.state}
          </Token_Balance>
          <Token_Balance_Won>
            ₩{' '}
            {balanceList[coin.state] &&
              priceList[bogoxTrans()] &&
              regexpWon(balanceList[coin.state] * priceList[bogoxTrans()])}
          </Token_Balance_Won>
          <Transaction_Row>
            <Transaction_Group1
              onPress={() => navigate('/deposit', { state: coin.state })}>
              <img src={transaction_deposit} alt="" style={{ height: "19px", width: "19px" }} />

              <Transaction_Text>
                {t('transactionHistory.receive')}
              </Transaction_Text>
            </Transaction_Group1>
            <Transaction_Group
              onPress={() => navigate('/withdraw', { state: coin.state })}>
              <img src={transaction_withdraw} alt="" style={{ height: "25px", width: "25px" }}  />

              <Transaction_Text>
                {t('transactionHistory.send')}
              </Transaction_Text>
            </Transaction_Group>
          </Transaction_Row>
        </Token_Wrap>
        {transactionHistroy.length > 0 ? (
          <Main_Box>
            <Main_Bottom>
              <Main_Bottom_Box>
                {transactionHistroy.map((el, key) => (
                  <Transaction_Wrap key={key + 'history'}>
                    <Transaction_Title_Row>
                      <Transaction_Title_Text>
                        {el.symbol}{' '}
                        {el.type === 'OUT'
                          ? t('transactionHistory.withdraw')
                          : t('transactionHistory.deposit')}
                      </Transaction_Title_Text>
                      <Transaction_Title_Left_Wrap>
                        <Transaction_Title_Text2>
                          {t('transactionHistory.confirm')}
                        </Transaction_Title_Text2>
                        <Transaction_Content_Datetime>
                          {el.timeAt &&
                            el.timeAt.slice(0, 10) +
                            ' ' +
                            el.timeAt.slice(11, 19)}
                        </Transaction_Content_Datetime>
                      </Transaction_Title_Left_Wrap>
                    </Transaction_Title_Row>
                    <Transaction_Content>
                      <Transaction_Content_Detail_Row>
                        <Transaction_Content_Detail numberOfLines={1}>
                          {el.type === 'OUT' ? '0.0' : regexp(el.amount)}
                        </Transaction_Content_Detail>
                        <Transaction_Content_Detail numberOfLines={1}>
                          {el.type === 'OUT' ? regexp(el.amount) : '0.0'}
                        </Transaction_Content_Detail>
                      </Transaction_Content_Detail_Row>
                      <Transaction_Link
                        onPress={() =>
                          Linking.openURL(`https://bscscan.com/tx/${el.txHash}`)
                        }>
                        <img src={transaction_link} alt="" />
                      </Transaction_Link>
                    </Transaction_Content>
                  </Transaction_Wrap>
                ))}
                {totalCount > (currentPage + 1) * listPerPage ? (
                  <Basic_Button
                    onPress={nextPage}
                    marginBottom={25}
                    width="70%"
                    marginTop={10}>
                    {t('transactionHistory.viewMore')}
                  </Basic_Button>
                ) : (
                  ''
                )}
              </Main_Bottom_Box>
            </Main_Bottom>
          </Main_Box>
        ) : (
          <No_Content_Wrap>
            <No_Content_Text>
              {t('transactionHistory.noHistory')}
            </No_Content_Text>
          </No_Content_Wrap>
        )}
      </Main_Wrap>
    </Container>
  );
};

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

const No_Content_Wrap = styled(View)`
  width: 100%;
  margin-top: 30;
  justify-content: center;
  align-items: center;
`;
const No_Content_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 17;
  color: #000000;
`;

const Container = styled(View)`
  flex: 1;
  background-color: #f4f4f4;
  justify-content: center;
  align-items: center;
`;

const Main_Wrap = styled(View)`
  width: 100%;
  flex: 1;
`;

const Token_Wrap = styled(View)`
  width: 100%;
  padding-top: 26;
  padding-bottom: 31;
  align-items: center;
`;
const Token_Balance = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 700;
  font-size: 27;
  color: #000000;
  margin-top: 17;
`;
const Token_Balance_Won = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 17;
  color: #979797;
`;
const Transaction_Row = styled(View)`
  flex-direction: row;
  margin-top: 17;
`;
const Transaction_Group1 = styled(TouchableOpacity)`
  margin-right: 60;
  align-items: center;
`;
const Transaction_Group = styled(TouchableOpacity)`
  align-items: center;
`;
const Transaction_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  margin-top: 8;
  color: #6767e1;
`;
const Main_Box = styled(View)`
  width: 100%;
  background-color: #f4f4f4;
`;

const Main_Bottom = styled(View)`
  width: 100%;
  height: 317;
  padding-top: 26;
  padding-bottom: 31;
`;

const Main_Bottom_Box = styled(View)`
  width: 100%;
  background-color: #f4f4f4;
  align-items: center;
`;
const Transaction_Wrap = styled(View)`
  padding-left: 15;
  padding-right: 15;
  padding-top: 17;
  padding-bottom: 17;
  margin-bottom: 15;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
`;

const Transaction_Title_Row = styled(View)`
  justify-content: space-between;
`;
const Transaction_Title_Left_Wrap = styled(View)`
  flex-direction: row;
`;

const Transaction_Title_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  color: #000000;
`;
const Transaction_Title_Text2 = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 13;
  color: #00ccfd;
  margin-right: 4;
`;

const Transaction_Content = styled(View)`
  flex-direction: row;
  align-items: center;
`;

const Transaction_Content_Datetime_Row = styled(View)`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10;
`;
const Transaction_Content_Datetime = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 13;
  color: #c5c5c5;
`;
const Transaction_Content_Detail_Row = styled(View)`
  margin-right: 7;
`;
const Transaction_Link = styled(TouchableOpacity)``;
const Transaction_Content_Detail_Name = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;

  font-weight: 500;
  font-size: 13;
  line-height: 13;
  height: 24;
  color: #878787;
`;
const Transaction_Content_Detail_Name2 = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;

  font-weight: 500;
  font-size: 13;
  line-height: 13;
  height: 24;
  color: #878787;
`;
const Transaction_Content_Detail = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  color: #c5c5c5;
`;

export default TransactionHistory;
