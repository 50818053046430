import React, { useState, useEffect } from 'react';
import { View, Dimensions, Text, TouchableOpacity } from 'react-native';
import Sign from '@model/user/Sign';
import styled from 'styled-components/native';
import TopNav from '@components/topNav/TopNav';
import {
  Basic_TextInput,
  Basic_TextInput_Login,
  TextInput_Button,
  Visiable_Label_TextInput,
} from '@components/textInput/TextInput';
import { Basic_Button } from '@components/button/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Inqury = () => {
  const { t } = useTranslation('locale');
  const [totalCount, setTotalCount] = useState(0);
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();


  const getInquryList = async () => {
    const data = await Sign.inquryList(5, rows.length);    
    const rows2 = rows.concat(data.rows);
    setRows(rows2);
    setTotalCount(data.totalCount);    
  };


  useEffect(() => {
    getInquryList().then();
  }, []);
  return (
    <Container>
      <TopNav navigate={navigate}>1:1 문의</TopNav>
      <Main_Wrap>
        <Main_Box>
          {
            rows.length > 0 ?
              <Transaction_Box>
                <Transaction_List>
                  <Transaction_Row2>
                    <Transaction_Content_Row>
                      {['제목', '일자'].map(el => (
                        <Transaction_Coin_Wrap2>
                          <Transaction_Coin_Text>
                            {el}
                          </Transaction_Coin_Text>
                        </Transaction_Coin_Wrap2>
                      ))}
                    </Transaction_Content_Row>
                    <Transaction_Line />
                  </Transaction_Row2>
                  {rows.map((el: any, key: number) => (
                    <Transaction_Row3 key={`${key}list`}>
                      <Transaction_Content_Row>
                        <Transaction_Coin_Wrap2>
                          <div
                            onClick={() => navigate(`/inqury/${el.iIdx}`)}
                            style={{ cursor: 'pointer'}}
                          >
                            {el.title}
                          </div>
                        </Transaction_Coin_Wrap2>
                        <Transaction_Coin_Wrap2>
                          {el.inqureTime.split('T')[0]}
                        </Transaction_Coin_Wrap2>
                      </Transaction_Content_Row>
                    </Transaction_Row3>
                  )
                  )}
                  {totalCount <= rows.length || (
                    <Transaction_Box>
                      <Transaction_Button onPress={getInquryList}>
                        <Transaction_Button_Inner_Wrap>
                          <Transaction_Button_Inner_Text>
                            {t('P2P.more')}
                          </Transaction_Button_Inner_Text>
                        </Transaction_Button_Inner_Wrap>
                      </Transaction_Button>
                    </Transaction_Box>
                  )}
                </Transaction_List>
              </Transaction_Box>
              :
              <No_Content_Box>
                <No_Content> 문의내용이 없습니다</No_Content>
              </No_Content_Box>
          }

        </Main_Box>
        <Main_Box>
          {/* 새로운 문의하기 */}
          <Basic_Button
            placeholder={t('P2P.title')}
            onPress={() => navigate('/inqury/0')}
          >
            문의하기
          </Basic_Button>
        </Main_Box>
      </Main_Wrap >
    </Container >
  );
};

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;
const Container = styled(View)`
  flex: 1;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
`;

const No_Content_Box = styled(View)`
  height: 100;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 50;
`;

const No_Content = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  padding-left: 15;
  line-height: 15;
  color: #1e1e1e;
`;


const Main_Wrap = styled(View)`
  width: 100%;
  flex: 1;
  padding-top: 35;
  padding-left: 20;
  padding-right: 20;
`;

const Main_Box = styled(View)`
  width: 100%;
`;

export default Inqury;


const Transaction_Coin_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  text-align: center;
`;

const Transaction_Coin_Wrap2 = styled(View)`
  flex: 1;
`;

const Transaction_Content_Row = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
const Transaction_Line = styled(View)`
  border-color: #e7e7e7;
  border-width: 0.8px;
  width: 100%;
`;

const Transaction_Row2 = styled(View)`
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
`;
const Transaction_Row3 = styled(View)`
  width: 100%;
  margin-top: -10px;
  padding-top: 10px;
  margin-bottom: 10px;
`;
const Transaction_List = styled(View)`
  width: 100%;
`;
const Transaction_Box = styled(View)`
  width: 100%;
  background: #ffffff;
  padding-top: 30px;
  padding-bottom: 20px;
  padding-inline: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Transaction_Button = styled(TouchableOpacity)`
  margin-top: 30px;
  border: 1px solid #e7e7e7;
  border-width: 1px;
  border-color: #e7e7e7;
  border-radius: 35px;
  width: 107px;
  height: 42px;
  justify-content: center;
  align-items: center;
`;

const Transaction_Button_Inner_Wrap = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`;

const Transaction_Button_Inner_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #7e7e7e;
`;