import React, { useEffect, useState } from 'react';
import { View, Dimensions, Text, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import TopNav from '@components/topNav/TopNav';
import settings_link from '@assets/img/settings_link.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SignController from '@controller/Sign/SignController';
import Sign from '@model/user/Sign';
import { Bank_Info_Modal } from '@components/modal/BankInfoModal';
interface MainProps {
  navigation: any;
}
const Settings = () => {
  const [bankModal, setBankModal] = useState(false);
  const { t } = useTranslation('locale');
  const navigate = useNavigate();
  const logout = () => {
    if (confirm(t('settings.logoutQuestion'))) {
      sessionStorage.clear();
      localStorage.removeItem('autoLogin');
      navigate('/login', { replace: true });
    }
  };


  useEffect(() => {
  }, []);

  return (
    <Container>
      <Bank_Info_Modal t={t} visable={bankModal} setVisable={setBankModal} />
      <TopNav navigate={navigate}>My Page</TopNav>
      <Main_Wrap>
        <Settings_Menu>
          <Settings_Menu_Title onPress={() => navigate('/signupInfo')}>
            <Settings_Menu_Title_Text>
              {t('settings.signupInfo')}
            </Settings_Menu_Title_Text>
          </Settings_Menu_Title>
          <Settings_Menu_Title onPress={() => setBankModal(true)}>
            <Settings_Menu_Title_Text>
              {t('settings.bank')}
            </Settings_Menu_Title_Text>
          </Settings_Menu_Title>
          {/* <Settings_Menu_Title onPress={() => navigate('/googleOTP1')}>
            <Settings_Menu_Title_Text>
              {t('settings.OTPSettings')}
            </Settings_Menu_Title_Text>
          </Settings_Menu_Title> */}
          <Settings_Menu_Title onPress={() => navigate('/transferPwd')}>
            <Settings_Menu_Title_Text>
              {t('settings.transferPwd')}
            </Settings_Menu_Title_Text>
          </Settings_Menu_Title>
          <Settings_Menu_Title onPress={() => navigate('/inqury')}>
            <Settings_Menu_Title_Text>
              {'1:1 문의'}
            </Settings_Menu_Title_Text>
          </Settings_Menu_Title>
        </Settings_Menu>
        <Middle_Row>
          <Middle_Line />
        </Middle_Row>
        <Settings_Menu_Title onPress={logout}>
          <Settings_Menu_Title_Text>
            {t('settings.logout')}
          </Settings_Menu_Title_Text>
        </Settings_Menu_Title>
      </Main_Wrap>
    </Container>
  );
};

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;
const Container = styled(View)`
  flex: 1;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
`;

const Main_Wrap = styled(View)`
  width: 100%;
  flex: 1;
`;

const Settings_Menu = styled(View)`
  width: 100%;
`;
const Settings_Menu_Title = styled(TouchableOpacity)`
  width: 100%;
  height: 70px;
  padding-left: 20;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
`;
const Settings_Menu_Title_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 15;
  line-height: 20;
  color: #000000;
`;

const Visit_Icon = styled(View)`
  margin-left: 10;
`;

const Middle_Row = styled(View)`
  width: 100%;
  height: 1;
  padding-left: 20;
  padding-right: 20;
`;

const Middle_Line = styled(View)`
  width: 100%;
  height: 100%;
  background-color: #ededed;
`;

export default Settings;
