import React, { useState, useEffect } from 'react';
import {
  View,
  Dimensions,
  Text,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import styled from 'styled-components/native';
import { Rectangle, TopNav2 } from '@components/topNav/TopNav';
import { Basic_Button } from '@components/button/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Popable } from 'react-native-popable';
const staking_1_interest = require('@assets/img/staking_1_interest.png');
const staking_2_interestR = require('@assets/img/staking_2_interestR.png');
const staking_3_stakingS = require('@assets/img/staking_3_stakingS.png');
const staking_4_withdrawExchange = require('@assets/img/staking_4_withdrawExchange.png');
const staking_5_stakingExchange = require('@assets/img/staking_5_stakingExchange.png');
const staking_6_withdraw = require('@assets/img/staking_6_withdraw.png');
const staking_7_stakingFinish = require('@assets/img/staking_7_stakingFinish.png');
import Staking from '@model/staking/Staking';
import { Interface_All } from '@interface/Interface_All';
import BottomMenu from '@components/bottomMenu/BottomMenu';

const Staking3 = () => {
  const { t } = useTranslation('locale');
  const navigate = useNavigate();
  const [live, setLive] = useState(
    (new Date().getHours() * 60 * 60 +
      new Date().getMinutes() * 60 +
      new Date().getSeconds()) /
      86400,
  );
  const [info, setInfo] = useState({
    amount: 0,
    bauAmount: 0,
    dayInterest: 0,
    referalAmount: 0,
    referalDayInterest: 0,
    totalAmount: 0,
    userType: '',
  });

  const regexpWon = (num: number) => {
    num = Math.floor(num);
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const getInfo = async () => {
    try {
      const data = await Staking.stakingInfo();
      if (data) {
        setInfo(data);
      }
    } catch (err: any) {
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setLive(
        (new Date().getHours() * 60 * 60 +
          new Date().getMinutes() * 60 +
          new Date().getSeconds()) /
          86400,
      );
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    getInfo();
  }, []);

  const listPerPage = 5;
  const [transactionHistroy, setTransactionHistory]: any = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const getTransactionHistroy = async () => {
    let res = await Staking.list(currentPage * listPerPage, listPerPage);
    setTransactionHistory([...transactionHistroy, ...res.rows]);
    setTotalCount(res.totalCount);
  };

  const nextPage = () => setCurrentPage(currentPage + 1);

  const imageSet = {
    Y: staking_1_interest,
    X: staking_2_interestR,
    S: staking_3_stakingS,
    I: staking_4_withdrawExchange,
    C: staking_5_stakingExchange,
    W: staking_6_withdraw,
    M: staking_7_stakingFinish,
  };

  useEffect(() => {
    getTransactionHistroy();
  }, [currentPage]);
  return (
    <Container>
      <TopNav2 navigate={navigate}>{t('staking3.history')}</TopNav2>
      <Rectangle />
      <Main_Wrap>
        <Main_Box>
          <Content_Wrap>
            <Content_Title_Row>
              <Content_Title_Row_Text>
                {t('staking3.myAmount')}
              </Content_Title_Row_Text>
            </Content_Title_Row>
            <Content_Amount_Row>
              <Content_Amount_Text>
                {Math.floor(
                  (info.bauAmount +
                    info.amount +
                    info.totalAmount +
                    Number(info.dayInterest || 0) * live +
                    Number(info.referalDayInterest || 0) * live) *
                    1000000,
                ) / 1000000}{' '}
                BATY
              </Content_Amount_Text>
            </Content_Amount_Row>
          </Content_Wrap>

          {transactionHistroy.length > 0 ? (
            <Main_Box>
              <Main_Bottom>
                <Main_Bottom_Box>
                  {transactionHistroy.map((el, key) => (
                    <Transaction_Wrap key={key + 'history'}>
                      <Transaction_Title_Row>
                        <Transaction_Link>
                          <img
                            src={imageSet[el.DW as 'Y']}
                            alt=""
                            style={{ width: '40px' }}
                          />
                        </Transaction_Link>
                        <Transaction_Content>
                          <Transaction_Content_Detail_Row>
                            <Transaction_Content_Detail numberOfLines={1}>
                              {el.DW === 'Y' && t('staking3.stakingInterest')}
                              {el.DW === 'X' &&
                                t('staking3.stakingInterestReferal')}
                              {el.DW === 'S' && t('staking3.stakingStart')}
                              {el.DW === 'I' && t('staking3.stakingChange')}
                              {el.DW === 'C' && t('staking3.withdrawalChange')}
                              {el.DW === 'W' && t('staking3.withdrawal')}
                              {el.DW === 'M' && t('staking3.stakingFinish')}
                            </Transaction_Content_Detail>
                            <Transaction_Content_Detail numberOfLines={1}>
                              {el.batyAt && el.batyAt.slice(0, 10)}
                            </Transaction_Content_Detail>
                          </Transaction_Content_Detail_Row>
                        </Transaction_Content>
                      </Transaction_Title_Row>
                      <Transaction_Content>
                        <Transaction_Content_Detail_Row alignItem="flex-end">
                          <Transaction_Content_Detail numberOfLines={1}>
                            {el.amount &&
                              Math.floor(el.amount * 10000000) / 10000000}{' '}
                            BATY
                            {/* {el.type === 'OUT' ? '0.0' : regexp(el.amount)} */}
                          </Transaction_Content_Detail>
                          <Transaction_Content_Detail numberOfLines={1}>
                            ₩ {el.amount && regexpWon(el.amount * 1000)}
                            {/* {el.type === 'OUT' ? regexp(el.amount) : '0.0'} */}
                          </Transaction_Content_Detail>
                        </Transaction_Content_Detail_Row>
                      </Transaction_Content>
                    </Transaction_Wrap>
                  ))}
                  {totalCount > (currentPage + 1) * listPerPage ? (
                    <Basic_Button
                      onPress={nextPage}
                      marginBottom={25}
                      width="70%"
                      marginTop={10}>
                      {t('staking3.more')}
                    </Basic_Button>
                  ) : (
                    ''
                  )}
                </Main_Bottom_Box>
              </Main_Bottom>
            </Main_Box>
          ) : (
            <No_Content_Wrap>
              <No_Content_Text>{t('staking3.noHistory')}</No_Content_Text>
            </No_Content_Wrap>
          )}
        </Main_Box>
      </Main_Wrap>
      <BottomMenu navigate={navigate} currentP={'staking'} />
    </Container>
  );
};

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;
const Container = styled(View)`
  flex: 1;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
`;

const Main_Wrap = styled(ScrollView)`
  width: 100%;
  flex: 1;
  margin-top: 10;
  padding-top: 25;
  padding-left: 15;
  padding-right: 15;
  padding-bottom: 20;
  background-color: #ffffff;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
`;

const Main_Box = styled(View)`
  width: 100%;
`;

const Content_Wrap = styled(View)`
  width: 100%;
  margin-bottom: 20px;
`;
const Content_Amount_Text = styled(Text)`
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
`;
const Content_Amount_Row = styled(View)`
  width: 100%;
  margin-bottom: 10px;
  align-items: center;
`;
const Content_Title_Row_Text = styled(Text)`
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;
const Content_Title_Row = styled(View)`
  width: 100%;
  align-items: center;
`;

const Bottom_Menu = styled(View)`
  height: 75;
  width: 100%;
  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;

  padding-top: 10;
  padding-bottom: 15;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

const Bottom_Icon_Container = styled(View)`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 7;
`;

const Bottom_Icon_Nav = styled(TouchableOpacity)`
  align-items: center;
  flex: 1;
  height: 40;
  justify-content: space-between;
`;

const Bottom_Icon_Nav_R = styled(View)`
  align-items: center;
  height: 40;
  justify-content: space-between;
`;

const Bottom_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 13;
  line-height: 13;
  color: #1d1d59;
  margin-top: 5;
`;

const Popable_Ready = styled(Popable)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
`;

const No_Content_Wrap = styled(View)`
  width: 100%;
  margin-top: 30;
  justify-content: center;
  align-items: center;
`;
const No_Content_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 17;
  color: #000000;
`;

const Main_Bottom = styled(View)`
  width: 100%;
  height: 317;
  padding-bottom: 31;
`;

const Main_Bottom_Box = styled(View)`
  width: 100%;
  align-items: center;
`;
const Transaction_Wrap = styled(View)`
  margin-bottom: 15;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
`;

const Transaction_Title_Row = styled(View)`
  justify-content: space-between;
  flex-direction: row;
`;

const Transaction_Content = styled(View)`
  flex-direction: row;
  align-items: center;
`;

const Transaction_Content_Detail_Row = styled(View)<Interface_All>`
  margin-right: 7;
  align-items: ${props => props.alignItem};
`;
const Transaction_Link = styled(View)`
  margin-right: 10px;
`;

const Transaction_Content_Detail = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  color: #222222;
`;
export default Staking3;
