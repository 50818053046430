import CustomAxios from '../common/CustomAxios';

export default class Transaction {
  public static transfer = async (
    coin: string,
    to: string,
    value: string,
    gasprice: number,
    gaslimit: number,
    otpToken: string,
  ) => {
    const payload = {
      to,
      value,
      gasprice,
      gaslimit,
      otpToken,
    };
    const res = await CustomAxios.post(`/wallet/${coin}/transfer`, payload);
    return res.data;
  };

  public static history = async (
    symbol: string,
    offset: number,
    limit: number,
  ) => {
    const res = await CustomAxios.get(
      `/wallet/${symbol}/transaction/history?offset=${offset}&limit=${limit}`,
    );
    return res.data;
  };

  public static gas = async () => {
    const res = await CustomAxios.get('/wallet/gasinfo');
    return res.data;
  };
}
