import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,
  ScrollView,
} from 'react-native';
import styled from 'styled-components/native';
import { TopNav2, Rectangle } from '@components/topNav/TopNav';
import { Basic_Button } from '@components/button/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import staking_income from '@assets/img/staking_income.gif';
import Staking from '@model/staking/Staking';
import BottomMenu from '@components/bottomMenu/BottomMenu';
const Staking1 = () => {
  const { t } = useTranslation('locale');
  const navigate = useNavigate();
  const [amount, setAmount]: any = useState();
  const [live, setLive] = useState(
    (new Date().getHours() * 60 * 60 +
      new Date().getMinutes() * 60 +
      new Date().getSeconds()) /
      86400,
  );
  const [info, setInfo] = useState({
    amount: 0,
    bauAmount: 0,
    dayInterest: 0,
    referalAmount: 0,
    referalDayInterest: 0,
    totalAmount: 0,
    userType: '',
  });

  const getInfo = async () => {
    try {
      const data = await Staking.stakingInfo();
      if (data) {
        setInfo(data);
      }
    } catch (err: any) {
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  };

  const withdrawal = async () => {
    try {
      if (info.totalAmount < amount || !amount) {
        throw new Error(t('staking1.failedAmount') as '');
      }
      await Staking.withdrawal(amount.toString());
      alert(t('staking1.withdrew'));
      getInfo();
    } catch (err: any) {
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setLive(
        (new Date().getHours() * 60 * 60 +
          new Date().getMinutes() * 60 +
          new Date().getSeconds()) /
          86400,
      );
    }, 1400);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    getInfo();
  }, []);
  return (
    <Container>
      <TopNav2 navigate={navigate}>Staking</TopNav2>
      <Rectangle />
      <Main_Wrap>
        <Main_Box>
          <Content_Wrap>
            <Content_Title_Row>
              <Content_Title_Row_Text>
                {t('staking1.stakingAmount')}
              </Content_Title_Row_Text>
            </Content_Title_Row>
            <Content_Amount_Row>
              <Content_Amount_Text>{info.bauAmount} BATY</Content_Amount_Text>
            </Content_Amount_Row>
            <Content_Line />
            {/* {info.referalAmount ? (
              <Sub_Row>
                <Sub_Text>
                  {t('staking1.inducementQuantity')} : {info.referalAmount}BATY
                </Sub_Text>
              </Sub_Row>
            ) : (
              ''
            )} */}
          </Content_Wrap>
          {info.userType === 'S' && (
            <Content_Wrap>
              <Content_Title_Row>
                <Content_Title_Row_Text>
                  {t('staking1.inducementQuantity')}
                </Content_Title_Row_Text>
              </Content_Title_Row>
              <Content_Amount_Row>
                <Content_Amount_Text>
                  {info.referalAmount || 0} BATY
                </Content_Amount_Text>
              </Content_Amount_Row>
              <Content_Line />
            </Content_Wrap>
          )}
          <Content_Wrap_T onPress={() => navigate('/staking/list')}>
            <Content_Title_Row>
              <Content_Title_Row_Text>
                {t('staking1.miningAmount')}
              </Content_Title_Row_Text>
            </Content_Title_Row>
            <Content_Amount_Row>
              <img
                src={staking_income}
                style={{ width: '32px', height: '32px', marginRight: '10px' }}
                alt=""
              />
              <Content_Amount_Text>
                {Math.floor(
                  (info.amount +
                    info.totalAmount +
                    Number(info.dayInterest || 0) * live +
                    Number(info.referalDayInterest || 0) * live) *
                    1000000,
                ) / 1000000}{' '}
                BATY
              </Content_Amount_Text>
            </Content_Amount_Row>
            <Content_Line />
          </Content_Wrap_T>
          <Content_Wrap>
            <Content_Title_Row>
              <Content_Title_Row_Text>
                {t('staking1.available')}
              </Content_Title_Row_Text>
            </Content_Title_Row>
            <Content_Amount_Row>
              <Content_Amount_Text>
                {Math.floor(info.totalAmount * 1000000) / 1000000 || 0} BATY
              </Content_Amount_Text>
            </Content_Amount_Row>
            <Content_Line />
          </Content_Wrap>

          <Input_Wrap>
            <Input_TextArea
              value={amount}
              onChangeText={(e: string) => {
                setAmount(Number(e));
              }}
              placeholder={t('staking1.plzEnterWithdrawal') as ''}
              placeholderTextColor="#AAAAAA"
            />
            <Withdrawal_Button onPress={withdrawal}>
              <Withdrawal_Button_Text>
                {t('staking1.withdrawal')}
              </Withdrawal_Button_Text>
            </Withdrawal_Button>
          </Input_Wrap>
          <Basic_Button marginTop={55} onPress={() => navigate('/staking/2')}>
            {t('staking1.startStaking')}
          </Basic_Button>
        </Main_Box>
      </Main_Wrap>
      <BottomMenu navigate={navigate} currentP={'staking'} />
    </Container>
  );
};

const Container = styled(View)`
  flex: 1;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
`;

const Main_Wrap = styled(ScrollView)`
  width: 100%;
  flex: 1;
  margin-top: 10;
  padding-top: 35;
  padding-left: 15;
  padding-right: 15;
  padding-bottom: 20;
  background-color: #ffffff;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
`;

const Main_Box = styled(View)`
  width: 100%;
`;

const Content_Wrap = styled(View)`
  width: 100%;
  margin-bottom: 20px;
`;

const Content_Wrap_T = styled(TouchableOpacity)`
  width: 100%;
  margin-bottom: 20px;
`;
const Content_Amount_Text = styled(Text)`
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #000000;
`;
const Content_Amount_Row = styled(View)`
  width: 100%;
  margin-bottom: 10px;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
`;
const Content_Title_Row_Text = styled(Text)`
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;
const Content_Title_Row = styled(View)`
  width: 100%;
  margin-bottom: 10px;
`;

const Content_Line = styled(View)`
  border-width: 0.8px;
  border-color: #e7e7e7;
  width: 100%;
`;

const Input_Wrap = styled(View)`
  width: 100%;
  flex-direction: row;
`;
const Input_TextArea = styled(TextInput)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-inline: 17px;
  padding-block: 20px;
  height: 55px;
  width: 100%;
  background: #ffffff;
  border-width: 1px;
  border-color: #e7e7e7;
  box-shadow: 0px 4px 6px rgba(208, 207, 207, 0.15);
  border-radius: 10px;
  margin-right: 10px;
`;
const Withdrawal_Button = styled(TouchableOpacity)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-block: 20px;
  padding-inline: 5px;
  min-width: 66px;
  height: 54px;
  background: #adadca;
  border-radius: 10px;
`;

const Withdrawal_Button_Text = styled(Text)`
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
`;

export default Staking1;
