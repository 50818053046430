import React, { useState, useEffect } from 'react';
import { View, Dimensions, Text, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import TopNav from '@components/topNav/TopNav';
import {
  Label_TextInput,
  Visiable_Label_TextInput,
} from '@components/textInput/TextInput';
import { Basic_Button } from '@components/button/Button';
import { useNavigate } from 'react-router-dom';
import SignController from '@controller/Sign/SignController';
import { useTranslation } from 'react-i18next';
import Sign from '@model/user/Sign';

const Signup = () => {
  const { t } = useTranslation('locale');
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(true);
  const [branchIdx, setBranchIdx] = useState(0);
  const [branchName, setBranchName] = useState('');
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState(true);
  const [passwordCheck, setPasswordCheck] = useState('');
  const [passwordCheckValid, setPasswordCheckValid] = useState(true);
  const [passwordCheckVisible, setPasswordCheckVisible] = useState(true);
  const [tel, setTel] = useState('');
  const [name, setName] = useState('');
  const [nameValid, setNameValid] = useState(true);
  const [recommender, setRecommender] = useState('');
  const [ID, setID] = useState('');
  const [IDValid, setIDValid] = useState(true);
  const [phone, setPhone] = useState('');
  const [phoneValid, setPhoneValid] = useState(true);

  const nameRegex = /^[ㄱ-ㅎ|가-힣|a-z|A-Z]+$/;
  const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,16}$/;
  const phoneRegex = /^.{11,13}$/;
  const IDRegex = /^[a-z0-9]*$/;

  useEffect(
    () => setPasswordCheckValid(password === passwordCheck),
    [password, passwordCheck],
  );
  useEffect(() => {
    setPhone(
      phone
        .replace(/[^0-9]/g, '') // 숫자를 제외한 모든 문자 제거
        .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, '$1-$2-$3'),
    );
    if (phoneRegex.test(phone)) {
      setPhoneValid(true);
    } else if (phone !== '') {
      setPhoneValid(false);
    }
  }, [phone]);

  const signup = () =>
    SignController.signup(
      email,
      password,
      passwordCheck,
      name,
      branchIdx,
      recommender,
      IDValid,
      passwordValid,
      passwordCheckValid,
      nameValid,
      emailSuccess,
      tel,
      navigate,
      ID,
      phone,
      phoneValid,      
      t,
    );

  useEffect(() => {
    if (sessionStorage.accessToken) {
      navigate('/main', { replace: true });
    }


    async function setBranchCode() {
      if (sessionStorage.getItem('branchCode')) {
        let branchCode = sessionStorage.getItem('branchCode');
        let res = await Sign.branchInfo(branchCode);

        return res;
      }
    };

    setBranchCode().then((res) => {
      if (res) {
        setBranchName(res.branchName);
        setBranchIdx(res.bIdx);
      }
    });


  }, []);
  return (
    <Container>
      <TopNav navigate={navigate}>{t('signup.signup')}</TopNav>
      <Main_Wrap>
        <Main_Box>
          <Label_TextInput
            title={t('signup.id')}
            value={ID}
            setValue={setID}
            placeholder={t('signup.id')}
            color="#ABABAB"
            marginTop={35}
            regex={IDRegex}
            setValid={setIDValid}
          />
          {!IDValid && (
            <Valid_Label_Row>
              <Valid_Label>{t('signup.IDCondition')}</Valid_Label>
            </Valid_Label_Row>
          )}
          <Visiable_Label_TextInput
            title={t('signup.pwd')}
            value={password}
            setValue={setPassword}
            visible={passwordVisible}
            setVisible={setPasswordVisible}
            placeholder={t('signup.plzEnterPwd')}
            regex={passwordRegex}
            setValid={setPasswordValid}
            marginTop={35}
            color="#ABABAB"
          />
          {!passwordValid && (
            <Valid_Label_Row>
              <Valid_Label>
                {t('signup.pwdCondition')}
                {/*, 특수기호 */}
              </Valid_Label>
            </Valid_Label_Row>
          )}
          <Visiable_Label_TextInput
            title={t('signup.checkPwd')}
            value={passwordCheck}
            setValue={setPasswordCheck}
            visible={passwordCheckVisible}
            setVisible={setPasswordCheckVisible}
            placeholder={t('signup.plzEnterPwdAgain')}
            marginTop={35}
            color="#ABABAB"
          />
          {!passwordCheckValid && (
            <Valid_Label_Row>
              <Valid_Label>{t('signup.pwdNotMatched')}</Valid_Label>
            </Valid_Label_Row>
          )}
          <Label_TextInput
            title={t('signup.name')}
            value={name}
            setValue={setName}
            placeholder={t('signup.plzEnterName')}
            marginTop={35}
            regex={nameRegex}
            setValid={setNameValid}
            color="#ABABAB"
          />
          {!nameValid && (
            <Valid_Label_Row>
              <Valid_Label>{t('signup.nameCondition')}</Valid_Label>
            </Valid_Label_Row>
          )}
          <Label_TextInput
            title={t('signup.tel')}
            value={phone}
            setValue={setPhone}
            placeholder={t('signup.plzEnterTel')}
            marginTop={35}
            regex={phoneRegex}
            setValid={false}
            color="#ABABAB"
          />
          {!phoneValid && (
            <Valid_Label_Row>
              <Valid_Label>{t('signup.plzEntertel2')}</Valid_Label>
            </Valid_Label_Row>
          )}
          {branchName &&
            <Label_TextInput
              title={'지점'}
              value={branchName}
              placeholder={t('signup.plzEnterReferal')}
              setValid={false}
              marginTop={35}
              color="#ABABAB"
            />
          }
          <Label_TextInput
            title={t('signup.referal')}
            value={recommender}
            setValue={setRecommender}
            placeholder={t('signup.plzEnterReferal')}
            setValid={false}
            marginTop={35}
            color="#ABABAB"
          />
        </Main_Box>
        <Basic_Button marginBottom={50} onPress={signup}>
          {t('signup.signup')}
        </Basic_Button>
      </Main_Wrap>
    </Container>
  );
};

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;
const Container = styled(View)`
  flex: 1;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
`;

const Main_Wrap = styled(View)`
  width: 100%;
  padding-top: 35;
  padding-bottom: 45;
  flex: 1;
  padding-left: 20;
  padding-right: 20;
`;

const Main_Box = styled(View)`
  width: 100%;
  align-items: center;
  margin-bottom: 70;
`;

const Valid_Label_Row = styled(View)`
  width: 100%;
  align-items: flex-start;
  margin-top: 15;
`;

const Valid_Label = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 400;
  font-size: 12;
  line-height: 12;
  text-transform: capitalize;
  color: #1fcadb;
`;

export default Signup;
