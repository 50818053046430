import { Interface_All } from '@interface/Interface_All';
import React, { useEffect, useState } from 'react';
import { Text, View, TouchableOpacity, Image } from 'react-native';
import styled from 'styled-components/native';
const main_bottom_1 = require('@assets/img/main_bottom1.png');
const main_bottom_2 = require('@assets/img/main_bottom2.png');
const main_bottom_3 = require('@assets/img/main_bottom3.png');
const main_bottom_4 = require('@assets/img/main_bottom4.png');
const main_bottom_5 = require('@assets/img/main_bottom5.png');
const main_bottom_6 = require('@assets/img/main_bottom6.png');
const main_bottom_7 = require('@assets/img/main_bottom7.png');
const main_bottom_8 = require('@assets/img/main_bottom8.png');
import { useTranslation } from 'react-i18next';
import SignController from '@controller/Sign/SignController';
import Auth from '@model/common/Auth';
import { Popable } from 'react-native-popable';
interface MainProps extends Interface_All {
  navigate: any;
  currentP: any;
}

const BottomMenu = ({ navigate, currentP }: MainProps) => {
  const { t } = useTranslation('locale');
  const auth = Auth.getInstance();
  auth.checkSession();
  const [stakingAvailable, setStakingAvailable] = useState(false);
  const [info, setInfo] = useState({
    address: { email: '', name: '', tel: '', referal: '', role: '' },
  });

  
  useEffect(() => {
    if (!sessionStorage.accessToken) {
      navigate('/', { replace: true });
    }
  }, []);

  useEffect(() => {
    
  }, [info]);

  let icon1st = currentP === 'main' ? main_bottom_1 : main_bottom_6;
  let icon2nd = main_bottom_2;
  let icon3rd = currentP === 'p2p' ? main_bottom_8 : main_bottom_3;
  let icon4th = currentP === 'staking' ? main_bottom_7 : main_bottom_4;
  let icon5th = main_bottom_5;

  const BottomIconNav = ({ title, img, onP }: any) => (
    <Bottom_Icon_Nav
      onPress={() => {
        if (currentP !== onP.split('/')[1]) {
          navigate(onP);
        }
      }}>
      <Image source={img} alt="" style={{ resizeMode: 'cover', width: '16px', height: '16px' }} />
      <Bottom_Text>{title}</Bottom_Text>
    </Bottom_Icon_Nav>
  );

  const PopableNav = ({ title, img, message }: any) => (
    <View style={{ flex: 1 }}>
      <Popable_Ready
        content={t(`main.${message}`) as ''}
        backgroundColor="#858585">
        <Bottom_Icon_Nav_R>
          <Image source={img} alt="" />
          <Bottom_Text>{title}</Bottom_Text>
        </Bottom_Icon_Nav_R>
      </Popable_Ready>
    </View>
  );

  return (
    <Bottom_Menu>
      <Bottom_Icon_Container>
        <BottomIconNav title="Wallet" img={icon1st} onP="/main" />
        {/* <PopableNav title="NFT" img={icon2nd} message="onReady" /> */}
        <BottomIconNav title="P2P" img={icon3rd} onP="/p2p" />
        {/* {stakingAvailable ? (
          <BottomIconNav title="Staking" img={icon4th} onP="/staking/1" />
        ) : (
          <PopableNav title="Staking" img={icon4th} message="onReady" />
        )} */}
        <BottomIconNav title="My Page" img={icon5th} onP="/settings" />
      </Bottom_Icon_Container>
    </Bottom_Menu>
  );
};
const Bottom_Menu = styled(View)`
  height: 75;
  width: 100%;
  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  padding-top: 10;
  padding-bottom: 15;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

const Bottom_Icon_Container = styled(View)`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 7;
`;

const Bottom_Icon_Nav = styled(TouchableOpacity)`
  align-items: center;
  height: 40;
  flex: 1;
  justify-content: space-between;
`;

const Bottom_Icon_Nav_R = styled(View)`
  align-items: center;
  height: 40;
  justify-content: space-between;
`;

const Bottom_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 13;
  line-height: 13;
  color: #1d1d59;
  margin-top: 5;
`;

const Popable_Ready = styled(Popable)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  flex: 1;
  font-size: 15px;
`;

export default BottomMenu;
