import CustomAxios from '../common/CustomAxios';

export default class Sign {
  public static signup = async (
    email: string,
    pwd: string,
    name: string,
    tel: string,
    bIdx: number,
    referal: string,
  ) => {
    const payload = {
      email,
      pwd,
      name,
      tel,
      bIdx,
      referal,
    };
    const res = await CustomAxios.post('/wallet/createuser', payload);
    return res.data;
  };

  public static login = async (email: string, pwd: string) => {
    const payload = { email, pwd };
    const res = await CustomAxios.post('/wallet/login', payload);
    return res.data;
  };

  public static branchInfo = async (branchCode: string) => {
    const res = await CustomAxios.get(`/wallet/code/${branchCode}`);
    return res.data;
  };


  public static emailCheck = async (email: string) => {
    const res = await CustomAxios.get(`/wallet/check/${email}`);
    return res.data;
  };

  public static emailCheck2 = async (email: string, code: string) => {
    const payload = { email, code };
    const res = await CustomAxios.post('/wallet/check/email', payload);
    return res.data;
  };

  public static emailCheckForOTP = async () => {
    const res = await CustomAxios.get('/wallet/otp/check');
    return res.data;
  };

  public static emailCheckForOTP2 = async (code: string) => {
    const payload = { code };
    const res = await CustomAxios.post('/wallet/otp/check', payload);
    return res.data;
  };

  public static pwdUpdate = async (oldPwd: string, newPwd: string) => {
    const payload = { oldPwd, newPwd };
    const res = await CustomAxios.post('/wallet/pwd/update', payload);
    return res.data;
  };

  public static info = async () => {
    const res = await CustomAxios.get('/wallet/info');
    return res.data;
  };

  public static address = async () => {
    const res = await CustomAxios.get('/wallet/address');
    return res.data;
  };

  public static coinBalance = async (coin: string) => {
    const res = await CustomAxios.get(`/wallet/${coin}/balance`);
    return res.data;
  };

  public static coinBalanceAvailable = async (coin: string) => {
    const res = await CustomAxios.get(`/wallet/${coin}/available`);
    return res.data;
  };

  public static balance = async () => {    
    const res = await CustomAxios.get('/wallet/balance');
    return res.data;
  };

  public static price = async () => {
    const res = await CustomAxios.get('/wallet/coin/price');
    return res.data;
  };

  public static findAddress = async (email: string) => {
    const res = await CustomAxios.get(`/wallet/find/${email}`);
    return res.data;
  };

  public static getContractCheck = async () => {
    const res = await CustomAxios.get('/wallet/contract/check');
    return res.data;
  };

  public static registContract = async () => {
    const res = await CustomAxios.get('/wallet/contract/regist');
    return res.data;
  };

  public static updateBankInfo = async (
    bank: string,
    accountAddress: string,
    owner: string,
  ) => {
    const payload = { bank, accountAddress, owner };
    const res = await CustomAxios.post('/wallet/bank/update', payload);
    return res.data;
  };

  public static bankInfo = async () => {
    const res = await CustomAxios.get('/wallet/bank/info');
    return res.data;
  };

  public static transferPwdSet = async (transferPwd: string, pwd: string) => {
    const payload = { transferPwd, pwd };
    const res = await CustomAxios.post('/wallet/transfer/pwd/set', payload);
    return res.data;
  };

  public static popupNotice = async () => {
    const res = await CustomAxios.get('/wallet/popup');
    return res.data;
  };

  public static newInqury = async (title: string, content: string) => {
    const payload = { title, content };    
    const res = await CustomAxios.post('/wallet/inqury', payload);
    return res.data;
  };

  public static inquryList = async (limit:number, offset:number) => {
    const res = await CustomAxios.get(`/wallet/inqury/list?limit=${limit}&offset=${offset}`);
    return res.data;
  };

  public static inquryDetail = async (idx:number) => {
    const res = await CustomAxios.get(`/wallet/inqury/${idx}`);
    return res.data;
  };

  public static inquryAnswer = async (idx:number, content:string) => {
    const payload = { content };
    const res = await CustomAxios.post(`/wallet/inqury/${idx}/answer`, payload);
    return res.data;
  };

  public static inquryReplyList = async (idx:number) => {
    const res = await CustomAxios.get(`/wallet/inqury/${idx}/reply`);
    return res.data;
  }

  public static inquryReply = async (idx:number, reply:string) => {
    const payload = { reply };
    const res = await CustomAxios.post(`/wallet/inqury/${idx}/reply`, payload);
    return res.data;
  }
}
