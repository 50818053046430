import Sign from '@model/user/Sign';

export default class SignController {
  static async info(
    setInfo: React.Dispatch<
      React.SetStateAction<{
        address: {
          email: string;
          name: string;
          tel: string;
          referal: string;          
          role: string;
        };
      }>
    >,
  ) {
    try {
      const res = await Sign.info();
      setInfo(res);
    } catch (err) {
      console.log(err);
    }
  }

  static async address(
    setAddress: React.Dispatch<React.SetStateAction<string>>,
  ) {
    try {
      const res = await Sign.address();
      setAddress(res);
    } catch (err) {
      console.log(err);
    }
  }

  static async balance(
    setBalanceList: React.Dispatch<
      React.SetStateAction<{
        NON: number;
        BNB: number;        
      }>
    >,
  ) {
    try {
      const res = await Sign.balance();
      setBalanceList(res);
    } catch (err : any) {      
      console.log(err);
      if(err.response.data.code == "NO_USER"){
        alert("로그인이 필요합니다.");
        sessionStorage.clear();
      }      
    }
  }

  static async coinBalance(
    coin: any,
    setBalanceList: React.Dispatch<React.SetStateAction<{}>>,
    balanceList: {},
  ) {
    try {
      const res = await Sign.coinBalance(coin);
      setBalanceList({ ...balanceList, [coin]: res.balance });
    } catch (err) {
      console.log(err);
    }
  }

  static async coinBalanceOnly(
    coin: any,
    setBalan: React.Dispatch<React.SetStateAction<number>>,
  ) {
    try {
      const res = await Sign.coinBalance(coin);
      setBalan(res.balance);
    } catch (err) {
      console.log(err);
    }
  }

  static async coinBalanceAvailable(
    coin: any,
    setBalan: React.Dispatch<React.SetStateAction<number>>,
    setOnSale?: React.Dispatch<React.SetStateAction<number>>,
  ) {
    try {
      const res = await Sign.coinBalanceAvailable(coin);
      setBalan(res.available);
      if (setOnSale) {
        setOnSale(res.p2pOnSale[0].currentSale);
      }
    } catch (err) {
      console.log(err);
    }
  }

  static async price(
    setPriceList: React.Dispatch<
      React.SetStateAction<{
        NON: number;
        BNB: number;        
      }>
    >,
  ) {
    try {
      const res = await Sign.price();
      setPriceList({ ...res });
    } catch (err) {
      console.log(err);
    }
  }

  static async signup(
    email: string,
    password: string,
    passwordCheck: string,
    name: string,
    bIdx: string,
    recommender: string,
    IDValid: boolean,
    passwordValid: boolean,
    passwordCheckValid: boolean,
    nameValid: boolean,
    emailSuccess: boolean,
    tel: string,
    navigate: any,
    ID: any,
    phone: any,
    phoneValid: any,    
    t: any,
  ) {
    try {
      if (
        ID === '' ||
        password === '' ||
        passwordCheck === '' ||
        phone === '' ||
        name === ''
      ) {
        throw Error(t('controller.plzEnterAllSection'));
      }      
      if (
        !phoneValid ||
        !passwordValid ||
        !passwordCheckValid ||
        // !nameValid ||
        !IDValid
      ) {
        throw Error(t('controller.plzMatchFormat'));
      }
      await Sign.signup(ID, password, name, phone, bIdx, recommender);
      alert("회원가입이 완료되었습니다.");
      navigate('/login');
    } catch (err: any) {
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  }

  static async postEmailCheck2(
    email: string,
    emailCheckCode: string,
    setEmailCheck: React.Dispatch<React.SetStateAction<boolean>>,
    setEmailSuccess: React.Dispatch<React.SetStateAction<boolean>>,
    minutes: number,
    seconds: number,
    t: any,
  ) {
    try {
      if (minutes === 0 && seconds === 0) {
        throw Error('시간이 만료되었습니다.');
      }
      await Sign.emailCheck2(email, emailCheckCode);
      setEmailCheck(false);
      setEmailSuccess(true);
    } catch (err: any) {
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  }

  static async getEmailCheck(
    email: string,
    setEmailCheck: React.Dispatch<React.SetStateAction<boolean>>,
    setIsCountdown: React.Dispatch<React.SetStateAction<boolean>>,
    setMinutes: React.Dispatch<React.SetStateAction<number>>,
    setSeconds: React.Dispatch<React.SetStateAction<number>>,
  ) {
    try {
      await Sign.emailCheck(email);
      setEmailCheck(true);
      setIsCountdown(true);
      setMinutes(3);
      setSeconds(0);
    } catch (err: any) {
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  }
  static async pwdUpdate(
    currentPassword: string,
    password: string,
    passwordValid: boolean,
    passwordCheckValid: boolean,
    navigate: any,
    t: any,
  ) {
    try {
      if (currentPassword === '' || password === '') {
        throw Error(t('controller.plzEnterAllSection'));
      }
      if (!passwordValid || !passwordCheckValid) {
        throw Error(t('controller.plzMatchFormat'));
      }
      await Sign.pwdUpdate(currentPassword, password);
      navigate('/main');
    } catch (err: any) {
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  }

  static async postEmailCheckForOTP2(
    emailCheckCode: string,
    minutes: number,
    seconds: number,
    removeOTP: any,
    t: any,
  ) {
    try {
      if (minutes === 0 && seconds === 0) {
        throw Error('시간이 만료되었습니다.');
      }
      await Sign.emailCheckForOTP2(emailCheckCode);
      await removeOTP();
    } catch (err: any) {
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  }

  static async getEmailCheckForOTP(
    setEmailCheck: React.Dispatch<React.SetStateAction<boolean>>,
    setIsCountdown: React.Dispatch<React.SetStateAction<boolean>>,
    setMinutes: React.Dispatch<React.SetStateAction<number>>,
    setSeconds: React.Dispatch<React.SetStateAction<number>>,
  ) {
    try {
      await Sign.emailCheckForOTP();
      setEmailCheck(true);
      setIsCountdown(true);
      setMinutes(3);
      setSeconds(0);
    } catch (err: any) {
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  }

  static async findAddress(
    findAddressText: string,
    setIsFind: React.Dispatch<React.SetStateAction<boolean>>,
    setFindAddressResult: React.Dispatch<
      React.SetStateAction<{ id: string; name: string; address: string }>
    >,
    findAddressProto: { id: string; name: string; address: string },
  ) {
    try {
      const data = await Sign.findAddress(findAddressText);
      if (data === null) {
        setIsFind(true);
        setFindAddressResult(findAddressProto);
      } else {
        setFindAddressResult({
          id: data.email,
          name: data.name,
          address: data.address,
        });
      }
    } catch (err: any) {
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  }

  static async transferPwdSet(
    currentPassword: string,
    password: string,
    passwordValid: boolean,
    passwordCheckValid: boolean,
    navigate: any,
    t: any,
  ) {
    try {
      if (currentPassword === '' || password === '') {
        throw Error(t('controller.plzEnterAllSection'));
      }
      if (!passwordValid || !passwordCheckValid) {
        throw Error(t('controller.plzMatchFormat'));
      }
      await Sign.transferPwdSet(password, currentPassword);
      alert(t('controller.success'));
      navigate('/main');
    } catch (err: any) {
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  }
}
