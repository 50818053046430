import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, SafeAreaView, View, Dimensions, Alert } from 'react-native';
import React, { useEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import './i18n';
import Main from '@views/Main';
import Intro from '@views/intro/Intro';
import styled from 'styled-components/native';
import Login from '@views/pages/login/Login';
import Signup from '@views/pages/signup/Signup';
import Code from '@views/pages/signup/Code';
import Settings from '@views/pages/settings/Settings';
import SignupInfo from '@views/pages/signupInfo/SignupInfo';
import GoogleOTP1 from '@views/pages/googleOTP/GoogleOTP1';
import Deposit from '@views/pages/transaction/Deposit';
import Withdraw from '@views/pages/transaction/Withdraw';
import TransactionHistory from '@views/pages/transaction/TransactionHistory';
import { isBrowser } from 'react-device-detect';
import ScrollToTop from '@views/scroll/ScrollToTop';
import { useTranslation } from 'react-i18next'
import { ToastProvider } from 'react-native-toast-notifications';
import Contract from '@views/pages/contract/Contract';
import Staking1 from '@views/pages/staking/Staking1';
import Staking2 from '@views/pages/staking/Staking2';
import Transaction from '@views/pages/p2p/Transaction';
import Request from '@views/pages/p2p/Request';
import ViewP2P from '@views/pages/p2p/View';
import Staking3 from '@views/pages/staking/Staking3';
import TransferPwd from '@views/pages/setTransferPwd/setTransferPwd';
import Inqury from '@views/pages/inqury/Inqury';
import InquryView from '@views/pages/inqury/View';
import { nanoid } from 'nanoid';


export default function App() {
  const { i18n } = useTranslation();
  const getLocale = () => {
    const locale = localStorage.getItem('i18nextLng');
    if (locale === 'kor' || locale === 'eng') {
      localStorage.setItem('i18nextLng', locale);
      i18n.changeLanguage(locale);
    } else {
      localStorage.setItem('i18nextLng', 'kor');
      i18n.changeLanguage('kor');
    }
  };
  useEffect(() => {    
    getLocale();
  }, []);
  return (
    <AppContainer>
    <AppBrowserRouter>
      <BrowserRouter>
        <ToastProvider>
          <ScrollToTop />
          <Routes>
            <Route element={<Intro />} path="/" />
            <Route element={<Main />} path="/main" />
            <Route element={<Login />} path="/login" />
            <Route element={<Signup />} path="/signup" />
            <Route element={<Code />} path="/code/:branchCode" />
            <Route element={<Settings />} path="/settings" />
            <Route element={<SignupInfo />} path="/signupInfo" />
            <Route element={<GoogleOTP1 />} path="/googleOTP1" />
            <Route element={<Deposit />} path="/deposit" />
            <Route element={<Withdraw />} path="/withdraw" />
            <Route
              element={<TransactionHistory />}
              path="/transactionHistory"
            />
            <Route element={<Contract />} path="/contract" />
            <Route element={<Staking1 />} path="/staking/1" />
            <Route element={<Staking2 />} path="/staking/2" />
            <Route element={<Staking3 />} path="/staking/list" />
            <Route element={<Transaction />} path="/p2p" />
            <Route element={<Request />} path="/p2p/request" />
            <Route element={<ViewP2P />} path="/p2p/view" />
            <Route element={<Inqury />} path="/inqury" />
            <Route element={<InquryView />} path="/inqury/:idx" />            
            <Route element={<TransferPwd />} path="/transferPwd" />
          </Routes>
        </ToastProvider>
      </BrowserRouter>
    </AppBrowserRouter>
  </AppContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;
const AppContainer = styled(SafeAreaView)`
  flex: 1;
  height: ${_ => (isBrowser ? 700 : height)};
  background-color: #ffffff;
  align-items: center;
`;
const AppBrowserRouter = styled(View)`
  height: ${_ => (isBrowser ? '100%' : height)};
  width: ${_ => (isBrowser ? 375 : '100%')};
`;
