import axios from 'axios';
import Auth from './Auth';

const auth = Auth.getInstance();
auth.checkSession();
export const customAxios: any = axios.create({
  baseURL: 'https://api.nocoin.kr/',
  // baseURL :'http://3.34.189.245/',  
  headers: {
    accept: '*/*',
    'content-type': 'application/x-www-form-urlencoded',
    withCredentials: true,
  },
});

export default class CustomAxios {
  public static async download(endPoint: string) {
    const blob = 'blob';
    const config = {responseType: blob, headers: auth.getHeaderAuth()};
    const result = await customAxios.get(endPoint, config);
    return result.data;
  }

  public static async get(endPoint: string) {
    const config = {headers: auth.getHeaderAuth()};
    const result = await customAxios.get(endPoint, config);
    return result.data;
  }

  public static async put(endPoint: string, payload: any) {
    const config = {headers: auth.getHeaderAuth()};
    const result = await customAxios.put(endPoint, payload, config);
    return result.data;
  }

  public static async patch(endPoint: string, payload: any) {
    const config = {headers: auth.getHeaderAuth()};
    const result = await customAxios.patch(endPoint, payload, config);
    return result.data;
  }

  public static async post(endPoint: string, payload: any) {
    const config = {headers: auth.getHeaderAuth()};
    const result = await customAxios.post(endPoint, payload, config);
    return result.data;
  }

  public static async delete(endPoint: string, data: any) {
    const config = {headers: auth.getHeaderAuth()};
    Object.assign(config, {data: data});
    const result = await customAxios.delete(endPoint, config);
    return result.data;
  }
}
