import React, { useState, useEffect, useRef } from 'react';
import {
  View,
  Dimensions,
  Text,
  TouchableOpacity,
  ScrollView,
  Animated,
  Image,
} from 'react-native';
import styled from 'styled-components/native';
const main_lang = require('@assets/img/main_lang.png');
const Coin_symbol_BAGO30 = require('@assets/img/coin_NON_30.png');
const main_r_arrow = require('@assets/img/main_r_arrow.png');
const Coin_symbol_ETH_30 = require('@assets/img/coin_symbol_ETH_30.png');
const main_check = require('@assets/img/main_check.png');
import { Interface_All } from '@interface/Interface_All';
import { useNavigate } from 'react-router-dom';
import Auth from '@model/common/Auth';
import SignController from '@controller/Sign/SignController';
import '../../App.css';
import { useTranslation } from 'react-i18next';
import { LinearGradient } from 'expo-linear-gradient';
import { Pop_UP_Modal } from '@components/modal/PopupModal';
import BottomMenu from '@components/bottomMenu/BottomMenu';

const Main = () => {
  const animation1 = useRef(new Animated.Value(0)).current;
  const animation2 = useRef(new Animated.Value(0)).current;
  const animation3 = useRef(new Animated.Value(0)).current;
  const animation4 = useRef(new Animated.Value(0)).current;
  const { i18n } = useTranslation('locale');
  const auth = Auth.getInstance();
  auth.checkSession();
  const navigate = useNavigate();
  const [selectedLanguage, setSelectedLanguage]: any = useState('kor');
  const [balanceList, setBalanceList] = useState({
    NON: 0,
    BNB: 0,    
  });
  const [priceList, setPriceList] = useState({
    NON: 0,
    BNB: 0,    
  });
  const [visible, setVisible] = useState(false);
  const [noticeVisible, setNoticeVisible] = useState(false);
  const [stakingAvailable, setStakingAvailable] = useState(false);  
  const [info, setInfo] = useState({
    address: { email: '', name: '', tel: '', referal: '', role: '' },
  });

  const balance = () => SignController.balance(setBalanceList);
  const price = () => SignController.price(setPriceList);

  const regexp = (num: number) =>  {
    num = Math.floor(num * 1000000) / 1000000;    
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const regexpWon = (num: number) => {
    num = Math.floor(num);
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const languageChanger = (itemValue: string) => {
    setSelectedLanguage(itemValue);
    chgLocale(itemValue);
  };

  const chgLocale = (lang: any) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('i18nextLng', lang);
  };


  useEffect(() => {
    if (localStorage.i18nextLng) {
      setSelectedLanguage(localStorage.i18nextLng);
    }
    if (!sessionStorage.accessToken) {
      navigate('/', { replace: true });
    }
  }, []);

  useEffect(() => {
    
  }, [info]);

  useEffect(() => {
    balance();
    price();
  }, []);

  useEffect(() => {
    Animated.timing(animation1, {
      toValue: 1,
      useNativeDriver: true,
      duration: 400,
    }).start();
    Animated.timing(animation2, {
      toValue: 1,
      useNativeDriver: true,
      duration: 400,
      delay: 500,
    }).start();
    Animated.timing(animation3, {
      toValue: 1,
      useNativeDriver: true,
      duration: 400,
      delay: 1000,
    }).start();
    Animated.timing(animation4, {
      toValue: 1,
      useNativeDriver: true,
      duration: 400,
      delay: 1500,
    }).start();
  }, []);

  const LanguageList = ({ lang, title }: any) => (
    <List
      disabled={!visible}
      onPress={() => {
        languageChanger(lang);
        setVisible(false);
      }}
      background={selectedLanguage === lang ? '#EDEDED' : '#FFFFFF'}>
      <Language>{title}</Language>
      {selectedLanguage === lang && <Image source={main_check as any} alt="" style={{ resizeMode: 'cover', width: '11px', height: '8px' }}  />}
    </List>
  );

  const CoinBasic = ({
    symbol,
    animation,
    img,
    content1,
    content2,
    content3,
  }: any) => (
    <Animated.View style={{ opacity: animation }}>
      <Coin_Row
        onPress={() => navigate('/transactionHistory', { state: symbol })}
        style={{ borderRadius: 10 }}
      >
        <Coin_Row_Inner>
          <Coin_Symbol_Container_Large>
            <Image source={img} alt="" style={{ resizeMode: 'cover', width: '30px', height: '30px' }}  />
          </Coin_Symbol_Container_Large>
          <Coin_List_Balance_Container>
            <Coin_List_Balance_Text_Container>
              <Coin_List_Balance_Section_Left>
                <Coin_Symbol_Text_Large>{symbol}</Coin_Symbol_Text_Large>
                <Coin_List_Balance_Text>{content1}</Coin_List_Balance_Text>
              </Coin_List_Balance_Section_Left>
              <Image source={main_r_arrow as any} alt="" height={15} />
            </Coin_List_Balance_Text_Container>
            <Coin_List_Balance_Text_Symbol_Row>
              <Coin_List_Balance_Text_Symbol>
                = ￦{content2}
              </Coin_List_Balance_Text_Symbol>
              <Coin_List_Balance_Text_Symbol>
                {content3}
              </Coin_List_Balance_Text_Symbol>
            </Coin_List_Balance_Text_Symbol_Row>
          </Coin_List_Balance_Container>
        </Coin_Row_Inner>
      </Coin_Row>
    </Animated.View>
  );

  return (
    <Container>
      <Pop_UP_Modal visable={noticeVisible} setVisable={setNoticeVisible} />
      <Rectangle
        // colors={['#1d1d56 8%', '#1d1d56 43.24%', '#3f3fa7 89.63%']}
        colors={['#1d1d56', '#1d1d56', '#3f3fa7']}
        start={{ x: 0, y: 0 }}
        end={{ x: 0.5, y: 0.5}}
        // useAngle
        // angle={113.99}
        >
        <Circle style={{ borderRadius: 100 }} />
        <Circle2  style={{ borderRadius: 70 }}  />
      </Rectangle>
      <Top_Bar_Container>
        <Top_Bar>
          <Top_Bar_Element_Middle />
          <Pl onPress={() => setVisible(!visible)}>
            <Image source={main_lang} alt="" style={{ resizeMode: 'cover', width: '24px', height: '24px' }}  />
          </Pl>
          <List_Box visible={visible} style={{ borderRadius: 10 }}>
            <LanguageList lang="kor" title="한국어" />
            <Line />
            <LanguageList lang="eng" title="English" />
          </List_Box>
        </Top_Bar>
      </Top_Bar_Container>
      <Main_Wrap contentInsetAdjustmentBehavior="automatic">
        <Wallet_List>
          <Title_Wrap>
            <Top_Title>Non global exchange</Top_Title>
            <Main_Title_Row>
              <Main_Title>NON </Main_Title>
              <Main_Title2>wallet</Main_Title2>
            </Main_Title_Row>
          </Title_Wrap>
          <Wallet_List_Box>
            <Coin_Visual>
              <CoinBasic
                symbol="NON"
                animation={animation1}
                img={Coin_symbol_BAGO30}
                content1={`${balanceList.NON && regexp(balanceList.NON)
                  } NON`}
                content2={`${balanceList.NON &&
                  priceList.NON &&
                  regexpWon(balanceList.NON * priceList.NON)
                  }`}
                content3={`NON= ￦${priceList.NON && regexp(priceList.NON)
                  }`}
              />
              <CoinBasic
                symbol="BNB"
                animation={animation2}
                img={Coin_symbol_ETH_30}
                content1={`${balanceList.BNB && regexp(balanceList.BNB)
                } BNB`}
                content2={`${balanceList.BNB &&
                  priceList.BNB &&
                  regexpWon(balanceList.BNB * priceList.BNB)
                  }`}
                content3={`1BNB= ￦${priceList.BNB && regexp(priceList.BNB)}`}
              />
              {/* <CoinBasic
                symbol="BAGOX"
                animation={animation3}
                img={Coin_symbol_BAGO30}
                content1={`${balanceList.BAGOX && regexp(balanceList.BAGOX)
                  }${' '}BAGOX`}
                content2={`${balanceList.BAGOX &&
                  priceList.BAGO &&
                  regexpWon(balanceList.BAGOX * priceList.BAGO)
                  }`}
                content3={`1BAGOX= ￦${priceList.BAGO && regexp(priceList.BAGO)
                  }`}
              />
              <CoinBasic
                symbol="BATY"
                animation={animation4}
                img={Coin_symbol_BAGO30}
                content1={`${balanceList.BATY && regexp(balanceList.BATY)
                  } BATY`}
                content2={`${balanceList.BATY && 1000 && regexpWon(balanceList.BATY * 1000)
                  }`}
                content3={`1BATY= ￦${1000 && regexp(1000)}`}
              /> */}
            </Coin_Visual>
          </Wallet_List_Box>
        </Wallet_List>
      </Main_Wrap>
      <BottomMenu navigate={navigate} currentP={'main'} />
    </Container>
  );
};

const height = Dimensions.get('window').height;
const Container = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #f4f3fc;
`;


const Rectangle = styled(LinearGradient)`  
  width: 100%;
  height: 276;
  z-index: -5;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  border-end-end-radius: 70px;
  border-end-start-radius: 70px;
`;

//border-radius: 50%;
const Circle = styled(View)`
  width: 191px;
  height: 191px;
  z-index: -1;
  background: rgba(183, 183, 223, 0.12);
  
  position: absolute;
  right: -100;
  top: -20;
`;

//border-radius: 50%;
const Circle2 = styled(View)`
  width: 191px;
  height: 191px;
  z-index: -1;
  background: rgba(183, 183, 223, 0.12);  
  position: absolute;
  right: -100;
  top: 110;
`;
const Top_Bar_Container = styled(View)`
  height: 52;
  width: 100%;
  z-index: 10;
  padding-right: 10;
  padding-top: 10;
  padding-left: 10;
`;
const Top_Bar = styled(View)`
  height: 100%;
  width: 100%;

  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

const Top_Bar_Element_Middle = styled(View)`
  /* margin-right: 20; */
`;

const Main_Wrap = styled(ScrollView)`
  width: 100%;
  height: ${height - 77 - 72};
  padding-bottom: 40;
  overflow-x: hidden;
`;

const Wallet_List = styled(View)`
  width: 100%;
  flex: 1;
  padding-top: 25;
  padding-left: 15;
  padding-right: 15;
`;

const Wallet_List_Box = styled(View)`
  width: 100%;
`;

const Coin_Visual = styled(View)`
  width: 100%;
  margin-top: 31;
`;


const Coin_Row = styled(TouchableOpacity) <Interface_All>`
  width: 100%;
  height: 102;
  border-bottom: 1;
  border-bottom: #ededed;
  background-color: #ffffff;
  padding-left: 20;
  padding-right: 16;
  padding-top: 16;
  padding-bottom: 16;  
  margin-bottom: 10;
  box-shadow: 0px 4px 6px rgba(95, 95, 95, 0.25);
  transition-delay: '1s';
`;
const Coin_Row_Inner = styled(View)`
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
`;

const Coin_Symbol_Container_Large = styled(View)`
  height: 68;
  width: 28;
  justify-content: flex-start;
  padding-top: 4;
  align-items: center;
  margin-right: 10;
`;

const Coin_Symbol_Text_Large = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 17;
  color: #1e1e1e;
`;
const Coin_List_Balance_Container = styled(View)`
  align-items: flex-start;
  justify-content: space-between;
  width: 88%;
  height: 100%;
`;
const Coin_List_Balance_Text_Container = styled(View)`
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 38;
`;
const Coin_List_Balance_Section_Left = styled(View)`
  height: 38;
`;
const Coin_List_Balance_Text_Symbol_Row = styled(View)`
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;
const Coin_List_Balance_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 20;
  line-height: 22;
  color: #1e1e1e;
`;
const Coin_List_Balance_Text_Symbol = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 18;
  color: #aaaaaa;
`;

const Title_Wrap = styled(View)`
  margin-left: 14;
`;
const Top_Title = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  color: #6f6f6f;
`;

const Main_Title_Row = styled(View)`
  flex-direction: row;
`;

const Main_Title = styled(Text)`
  font-family: 'Roboto, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 35;
  letter-spacing: -0.01;
  color: rgba(255, 255, 255, 0.9); ;
`;

const Main_Title2 = styled(Text)`
  font-family: 'Roboto, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 35;
  letter-spacing: -0.01;
  color: rgba(0, 204, 253, 0.9);
`;

const List_Box = styled(View) <{ visible: boolean }>`
  display: flex;
  border-width: 1;
  z-index: 5;
  
  overflow: hidden;
  border-color: #e7e7e7;
  flex-direction: column;
  align-items: center;
  padding: 0;
  width: 110;
  height: 90;
  right: 0;
  top: 42;
  position: absolute;
  transition: opacity 0.5s ease-in-out;
  opacity: ${props => (props.visible ? 1 : 0)};
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.1));
`;

const List = styled(TouchableOpacity) <{ background: string }>`
  box-sizing: border-box;
  display: flex;
  z-index: 5;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-block: 15;
  padding-inline: 20;
  width: 110;
  height: 44;
  background: ${props => props.background};
  flex: none;
`;
const Language = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 16;
  color: #000000;
`;

const Pl = styled(TouchableOpacity)`
  flex-direction: row;
  gap: 5;
  padding-block: 12;
  padding-inline: 12;
  cursor: pointer;
`;

const Line = styled(View)`
  border-color: #ececec;
  border-width: 1;
  width: 110;
`;

export default Main;
