import React, { useEffect, useState } from 'react';
import {
  View,
  Dimensions,
  Text,
  TouchableOpacity,
  Linking,
} from 'react-native';
import styled from 'styled-components/native';
import TopNav from '@components/topNav/TopNav';
import googleOTP_appstore from '@assets/img/googleOTP_appstore.png';
import googleOTP_playstore from '@assets/img/googleOTP_playstore.png';
import { Label_TextInput } from '@components/textInput/TextInput';
import { Basic_Button } from '@components/button/Button';
import { useNavigate } from 'react-router-dom';
import OTPController from '@controller/OTP/OTPController';
import { useTranslation } from 'react-i18next';

const GoogleOTP1 = () => {
  const { t } = useTranslation('locale');
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const [secret, setSecret] = useState('');
  const [img, setImg] = useState('');
  const [OTPPossession, setOTPPossession] = useState(false);

  const checkOTP = () =>
    OTPController.checkOTP(setOTPPossession, setImg, setSecret);
  const setOTP = () => OTPController.setOTP(secret, code, navigate);
  const removeOTP = () => OTPController.removeOTP(navigate);

  useEffect(() => {
    checkOTP();
  }, []);
  return (
    <Container>
      <TopNav navigate={navigate}> {t('googleOTP.setOTP')}</TopNav>
      <Main_Wrap>
        {!OTPPossession ? (
          <Main_Box>
            <Frame>
              <Section_Title_Row>
                <Section_Number_Icon>
                  <Section_Number_Icon_Text>1</Section_Number_Icon_Text>
                </Section_Number_Icon>
                <Section_Title_Text>
                  {t('googleOTP.description1')}
                </Section_Title_Text>
              </Section_Title_Row>
              <Section_Content_Wrap>
                <Section_Store_Wrap>
                  <Section_Store_Button
                    onPress={() =>
                      Linking.openURL(
                        'https://apps.apple.com/kr/app/google-authenticator/id388497605',
                      )
                    }>
                    <img src={googleOTP_appstore} alt="googleOTP_appstore" />
                  </Section_Store_Button>
                  <Section_Store_Button
                    onPress={() =>
                      Linking.openURL(
                        'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
                      )
                    }>
                    <img src={googleOTP_playstore} alt="googleOTP_playstore" />
                  </Section_Store_Button>
                </Section_Store_Wrap>
              </Section_Content_Wrap>
            </Frame>
            <Frame>
              <Section_Title_Row>
                <Section_Number_Icon>
                  <Section_Number_Icon_Text>2</Section_Number_Icon_Text>
                </Section_Number_Icon>
                <Section_Title_Text>
                  {t('googleOTP.description2')}
                </Section_Title_Text>
              </Section_Title_Row>
              <Section_Content_Wrap>
                <QR_Code_Wrap>
                  <img src={img} alt="googleOTP_qrcode" />
                </QR_Code_Wrap>
                <Label_TextInput
                  title={t('googleOTP.description3')}
                  width="100%"
                  color="#000000"
                  value={secret}
                  readonly
                  setValue={false}
                  fontColor="rgba(255, 255, 255, 0.4)"
                  placeholder="코드를 입력해 주세요"
                />
              </Section_Content_Wrap>
            </Frame>
            <Frame>
              <Section_Title_Row>
                <Section_Number_Icon>
                  <Section_Number_Icon_Text>3</Section_Number_Icon_Text>
                </Section_Number_Icon>
                <Section_Title_Text>
                  {t('googleOTP.enterOTPCode')}
                </Section_Title_Text>
              </Section_Title_Row>
              <Section_Content_Wrap>
                <Label_TextInput
                  title={t('googleOTP.OTPcode')}
                  width="100%"
                  value={code}
                  setValue={setCode}
                  placeholder={t('googleOTP.plzEnterOTPCode')}
                />
              </Section_Content_Wrap>
            </Frame>
            <Basic_Button onPress={setOTP} marginTop={20} marginBottom={50}>
              {t('googleOTP.set')}
            </Basic_Button>
          </Main_Box>
        ) : (
          <Main_Box>
            <Valid_Title>{t('googleOTP.cancelOTP1')}</Valid_Title>
            <Basic_Button onPress={removeOTP} marginTop={35} marginBottom={35}>
              {t('googleOTP.cancelOTP2')}
            </Basic_Button>
          </Main_Box>
        )}
      </Main_Wrap>
    </Container>
  );
};

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;
const Container = styled(View)`
  flex: 1;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
`;

const Main_Wrap = styled(View)`
  width: 100%;
  flex: 1;
`;

const Main_Box = styled(View)`
  width: 100%;
  margin-top: 35;
  padding-left: 20;
  padding-right: 20;
`;

const Frame = styled(View)`
  width: 100%;
  margin-bottom: 50;
`;

const Section_Title_Row = styled(View)`
  height: 22;
  flex-direction: row;
  align-items: center;
`;


//border-radius: 100;
const Section_Number_Icon = styled(View)`
  width: 22;
  height: 22;  
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 204, 253, 0.5);
`;

const Section_Number_Icon_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 17;
  color: #ffffff;
`;

const Section_Title_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 17;
  line-height: 17;
  color: #000000;
  margin-left: 7;
`;

const Section_Content_Wrap = styled(View)`
  margin-top: 25;
  width: 100%;
`;

const Section_Store_Wrap = styled(View)`
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;
const Section_Store_Button = styled(TouchableOpacity)`
  width: 50%;
`;
const QR_Code_Wrap = styled(View)`
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 25;
`;

const Re_Send_Row = styled(View)`
  width: 100%;
  align-items: flex-end;
  margin-top: 15;
`;

const Re_Send_Button = styled(TouchableOpacity)``;

const Re_Send_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 400;
  font-size: 12;
  line-height: 12;
  text-align: center;
  color: #949494;
`;

const Timer = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 400;
  font-size: 15;
  margin-right: 20;
  line-height: 15;
  color: rgba(10, 132, 255, 0.8);
`;

const Valid_Title = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20;
  color: #000000;
`;
export default GoogleOTP1;
