import React from 'react';
import { Text, TouchableOpacity, Image } from 'react-native';
import { View, TextInput } from 'react-native';
import styled from 'styled-components/native';
import { Interface_All } from '@interface/Interface_All';
const input_vision = require('@assets/img/input_vision.png');
const input_invision = require('@assets/img/input_invision.png');
const input_close = require('@assets/img/input_close.png');
const  login_email = require('@assets/img/login_email.svg');
const  login_pwd = require('@assets/img/login_pwd.svg');

interface Basic_TextInput_Login extends Interface_All {
  placeholderTextColor?: string;
  placeholder?: string;
  children?: any;
  secureTextEntry?: boolean;
  readonly?: any;
  value: any;
  setValue: any;
  regex?: any;
  setValid?: any;
  IDError?: any;
  frontIcon?: any;
  IDChanger?: any;
}
interface Basic_TextInput extends Interface_All {
  placeholderTextColor?: string;
  placeholder?: string;
  children?: any;
  secureTextEntry?: boolean;
  readonly?: any;
  value: any;
  setValue: any;
  regex?: any;
  setValid?: any;
  IDChanger?: any;
}

interface Label_TextInput extends Interface_All {
  placeholderTextColor?: string;
  placeholder?: any;
  children?: any;
  secureTextEntry?: boolean;
  readonly?: any;
  value: any;
  setValue: any;
  title: string;
  regex?: any;
  setValid?: any;
  IDChanger?: any;
}

interface Visiable_TextInput extends Interface_All {
  placeholderTextColor?: string;
  placeholder?: any;
  visible: boolean;
  setVisible: Function;
  readonly?: any;
  value: any;
  regex?: any;
  setValid?: any;
  setValue: any;
  title?: any;
  frontIcon?: string;
}

interface Closable_TextInput extends Interface_All {
  placeholderTextColor?: string;
  placeholder?: string;
  readonly?: any;
  frontIcon?: string;
  value: any;
  IDError?: any;
  setValue: any;
  IDChanger?: any;
}

interface TextInput_Button extends Interface_All {
  title: string;
  onPress?: any;
  indicator?: boolean;
}
export const Basic_TextInput_Login = ({
  placeholderTextColor,
  placeholder,
  children,
  secureTextEntry,
  value,
  setValue,
  disabled,
  readonly,
  regex,
  setValid,
  frontIcon,
  IDChanger,
  IDError,
  ...style
}: Basic_TextInput_Login) => {
  return (
    <TextInput_Box_login {...style}>
      {frontIcon === 'email' && <Image source={login_email} alt="input_close"  style={{ resizeMode: 'cover', width: '16px', height: '17px' }}  />}
      {frontIcon === 'pwd' && <Image source={login_pwd} alt="input_close"  style={{ resizeMode: 'cover', width: '16px', height: '17px' }}   />}
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          marginLeft: 15,
        }}>
        <TextInput_Text
          placeholderTextColor={placeholderTextColor || '#ABABAB'}
          placeholder={placeholder}
          secureTextEntry={secureTextEntry || false}
          disabled={disabled}
          editable={!readonly}
          onChangeText={(text: string) => {
            if (setValue) {
              if (IDChanger) {
                const engNum = /^[a-z0-9]*$/;
                if (engNum.test(text)) {
                  setValue(text.trim());
                } else {
                  IDError();
                }
              } else {
                setValue(text.trim());
              }
            }
            if (setValid) {
              if (regex.test(text)) {
                setValid(true);
              } else {
                setValid(false);
              }
            }
          }}
          value={value}
          width={style.width}
        />
        <Image_Box>{children}</Image_Box>
      </View>
    </TextInput_Box_login>
  );
};
export const Basic_TextInput = ({
  placeholderTextColor,
  placeholder,
  children,
  secureTextEntry,
  value,
  setValue,
  disabled,
  readonly,
  regex,
  IDChanger,
  setValid,
  ...style
}: Basic_TextInput) => {
  return (
    <TextInput_Box {...style}>
      <TextInput_Text
        placeholderTextColor={placeholderTextColor || '#AAAAAA'}
        placeholder={placeholder}
        secureTextEntry={secureTextEntry || false}
        disabled={disabled}
        editable={!readonly}
        onChangeText={(text: string) => {
          if (setValue) {
            if (IDChanger) {
              const engNum = /^[a-z0-9]*$/;
              if (engNum.test(text)) {
                setValue(text.trim());
              }
            } else {
              setValue(text.trim());
            }
          }
          if (setValid) {
            if (regex.test(text)) {
              setValid(true);
            } else {
              setValid(false);
            }
          }
        }}
        value={value}
        width={style.width}
      />
      <Image_Box>{children}</Image_Box>
    </TextInput_Box>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Visiable_TextInput = ({
  placeholderTextColor = '#AAAAAA',
  placeholder,
  visible,
  setVisible,
  value,
  setValue,
  ...style
}: Visiable_TextInput) => {
  return (
    <Basic_TextInput
      placeholderTextColor={placeholderTextColor}
      placeholder={placeholder}
      width="90%"
      secureTextEntry={visible}
      value={value}
      setValue={setValue}
      {...style}>
      <Icon_Box onPress={() => setVisible(!visible)}>
        <Image source={!visible ? input_vision : input_invision} alt="" style={{ resizeMode: 'cover', width: '20px', height: '14px' }}   />
      </Icon_Box>
    </Basic_TextInput>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Closable_TextInput = ({
  placeholderTextColor = '#AAAAAA',
  placeholder,
  value,
  setValue,
  ...style
}: Closable_TextInput) => {
  return (
    <Basic_TextInput
      placeholderTextColor={placeholderTextColor}
      placeholder={placeholder}
      width="90%"
      value={value}
      setValue={setValue}
      {...style}>
      {value && (
        <Icon_Box onPress={() => setValue('')}>
          <Image source={input_close} alt="input_close" style={{ resizeMode: 'cover', width: '18px', height: '19px' }}  />
        </Icon_Box>
      )}
    </Basic_TextInput>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Label_TextInput = ({
  placeholderTextColor,
  placeholder,
  children,
  secureTextEntry,
  value,
  setValue,
  title,
  regex,
  readonly,
  disabled,
  setValid,
  IDChanger,
  ...style
}: Label_TextInput) => {
  return (
    <Label_TextInput_Box {...style}>
      <Label_TextInput_Text {...style}>{title}</Label_TextInput_Text>
      <Basic_TextInput
        placeholderTextColor={placeholderTextColor}
        placeholder={placeholder}
        width="90%"
        IDChanger={IDChanger}
        setValid={setValid}
        value={value}
        disabled={disabled}
        readonly={readonly}
        secureTextEntry={secureTextEntry || false}
        setValue={setValue}
        regex={regex}
        {...style}
        marginTop={15}>
        {children}
      </Basic_TextInput>
    </Label_TextInput_Box>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Visiable_Label_TextInput = ({
  placeholderTextColor = '#AAAAAA',
  placeholder,
  visible,
  setVisible,
  value,
  setValue,
  title,
  regex,
  setValid,
  ...style
}: Visiable_TextInput) => {
  return (
    <Label_TextInput
      placeholderTextColor={placeholderTextColor}
      placeholder={placeholder}
      width="90%"
      secureTextEntry={visible}
      value={value}
      setValue={setValue}
      regex={regex}
      setValid={setValid}
      title={title || ''}
      {...style}>
      <Icon_Box onPress={() => setVisible(!visible)}>
        <img src={!visible ? input_vision : input_invision} alt="" />
      </Icon_Box>
    </Label_TextInput>
  );
};

export const Visiable_TextInput_Login = ({
  placeholderTextColor = '#737373',
  placeholder,
  visible,
  setVisible,
  value,
  setValue,
  ...style
}: Visiable_TextInput) => {
  return (
    <Basic_TextInput_Login
      placeholderTextColor={placeholderTextColor}
      placeholder={placeholder}
      width="80%"
      secureTextEntry={visible}
      value={value}
      setValue={setValue}
      {...style}>
      <Icon_Box onPress={() => setVisible(!visible)}>
        <img src={!visible ? input_vision : input_invision} alt="" />
      </Icon_Box>
    </Basic_TextInput_Login>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Closable_TextInput_Login = ({
  placeholderTextColor = '#737373',
  placeholder,
  value,
  setValue,
  IDChanger,
  IDError,
  ...style
}: Closable_TextInput) => {
  return (
    <Basic_TextInput_Login
      placeholderTextColor={placeholderTextColor}
      placeholder={placeholder}
      width="80%"
      value={value}
      IDChanger={IDChanger}
      setValue={setValue}
      IDError={IDError}
      {...style}>
      {value ? (
        <Icon_Box onPress={() => setValue('')}>
          <Image source={input_close} alt="input_close" style={{ resizeMode: 'cover', width: '18px', height: '19px' }}  />
        </Icon_Box>
      ) : (
        <></>
      )}
    </Basic_TextInput_Login>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TextInput_Button = ({ title, ...style }: TextInput_Button) => {
  return (
    <Button {...style}>
      <Button_Text>{title}</Button_Text>
    </Button>
  );
};
// const width = Dimensions.get('window').width;
// const height = Dimensions.get('window').height;
const TextInput_Box_login = styled(View)<Interface_All>`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 20;
  width: 100%;
  height: 55;
  /* background: ${props => props.backgroundColor || '#373737'}; */
  /* border-width: 1; */
  border-bottom-width: 1;
  border-bottom-color: #dfdfdf;
  /* border-color: ${props => props.borderColor || '#434343'}; */
  margin-top: ${props => props.marginTop || 0};
  margin-bottom: ${props => props.marginBottom || 0};
`;

const TextInput_Box = styled(View)<Interface_All>`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 7;
  padding-left: 20;
  width: 100%;
  height: 55;
  background: ${props => props.backgroundColor || '#ffffff'};
  border-width: 1;
  border-color: ${props => props.borderColor || '#e7e7e7'};
  elevation: 2;
  border-radius: 10;
  margin-top: ${props => props.marginTop || 0};
  margin-bottom: ${props => props.marginBottom || 0};
  box-shadow: 0px 4px 6px rgba(208, 207, 207, 0.15);
`;

const Image_Box = styled(View)`
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: -20;
`;

const Icon_Box = styled(TouchableOpacity)`
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding-right: 20;
`;

const TextInput_Text = styled(TextInput)<Interface_All>`
  width: ${props => props.width || '100%'};
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 14;
  color: #1e1e1e;
  outline-width: 0;
`;

const Label_TextInput_Box = styled(View)<Interface_All>`
  width: 100%;
  height: 88;
  margin-top: ${props => props.marginTop || 0};
  margin-bottom: ${props => props.marginBottom || 0};
`;

const Label_TextInput_Text = styled(Text)<Interface_All>`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 20;
  color: ${props => props.color || '#000000'};
`;

const Button = styled(TouchableOpacity)<Interface_All>`
  justify-content: center;
  align-items: center;
  width: ${props => props.width || 117};
  height: 35;
  margin-left: -20;
  margin-right: ${props => props.marginRight || 25};
  background-color: ${props => props.backgroundColor || '#fafafa'};
  border-width: 1;
  border-color: ${props => props.borderColor || '#ededed'};
  border-radius: 20;
`;
const Button_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 20;
  color: #78787a;
`;
