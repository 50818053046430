import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import { kor, eng } from '@locales';

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    resources: { kor, eng },
    fallbackLng: 'kor',
    ns: ['translations'],
    defaultNS: 'translations',
    debug: false,
    interpolation: { escapeValue: false, formatSeparator: ',' },
    // detection: { order: ['path', 'navigator'] },
    react: { useSuspense: true },
  });

export default i18n;
