import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
  ScrollView,
} from 'react-native';
import styled from 'styled-components/native';
import { Rectangle, TopNav2 } from '@components/topNav/TopNav';
import { Basic_Button } from '@components/button/Button';
import { useNavigate } from 'react-router-dom';
import SignController from '@controller/Sign/SignController';
import { useTranslation } from 'react-i18next';
const select_arrow = require('@assets/img/select_arrow.png');
const main_check = require('@assets/img/main_check.png');
import Staking from '@model/staking/Staking';
import BottomMenu from '@components/bottomMenu/BottomMenu';
import StakingController from '@controller/Staking/StakingController';

const Staking2 = () => {
  const { t } = useTranslation('locale');
  const navigate = useNavigate();

  const [bagoAvailable, setBAGOAvailable] = useState(0);
  const [bauAvailable, setBAUAvailable] = useState(0);
  const [amount, setAmount]: any = useState();
  const [loading, setLoading] = useState(false);
  const [coinVisible, setCoinVisible] = useState(false);
  const [periodVisible, setPeriodVisible] = useState(false);
  const [coin, setCoin] = useState('BAGO');
  const [info, setInfo] = useState({
    stakingExchangeRate: 0,
    stakingInterestRate: 0,
    stakingInterestRateTypeU6Months: 0,
    stakingInterestRateTypeU1Year: 0,
    stakingPeriod: 0,
    userType: '',
  });
  const [periodTypeU, setPeriodTypeU] = useState(360);

  const selectCoin = (itemValue: string) => {
    setCoin(itemValue);
    setCoinVisible(false);
  };

  const selectPeriod = (itemValue: number) => {
    setPeriodTypeU(itemValue);
    setPeriodVisible(false);
  };

  const regexp = (num: number) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const coinBalanceAvailable = (coin2: string, setCoin2: any) =>
    SignController.coinBalanceAvailable(coin2, setCoin2);

  const batyBalanceAvailable = (coin2: string, setCoin2: any) =>
    SignController.coinBalanceOnly(coin2, setCoin2);

  const getInfo = async () => await StakingController.getInfo(setInfo);

  const regist = async () => {
    try {
      setLoading(true);
      if (!amount || amount <= 0) {
        throw new Error(t('staking2.failedAmount') as '');
      } else if (coin === 'BAGO' && Number(amount) > Number(bagoAvailable)) {
        throw new Error(t('staking2.coinLow') as '');
      } else if (coin === 'BAU' && Number(amount) > Number(bauAvailable)) {
        throw new Error(t('staking2.coinLow') as '');
      }
      let coinStr = coin;
      if (coin === 'BAU') {
        coinStr = 'BATY';
      }

      await Staking.regist(
        coinStr,
        amount,
        info.stakingInterestRate,
        info.stakingExchangeRate,
        info.stakingPeriod,
      );
      alert(t('staking2.startStaking'));
      setLoading(false);
      navigate('/staking/1');
    } catch (err: any) {
      setLoading(false);
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  };

  const registTypeU = async () => {
    try {
      setLoading(true);
      if (!amount || amount <= 0) {
        throw new Error(t('staking2.failedAmount') as '');
      } else if (coin === 'BAGO' && Number(amount) > Number(bagoAvailable)) {
        throw new Error(t('staking2.coinLow') as '');
      } else if (coin === 'BAU' && Number(amount) > Number(bauAvailable)) {
        throw new Error(t('staking2.coinLow') as '');
      }
      let coinStr = coin;
      if (coin === 'BAU') {
        coinStr = 'BATY';
      }

      let interesRate = info.stakingInterestRateTypeU1Year;
      if (Number(periodTypeU) === 180) {
        interesRate = info.stakingInterestRateTypeU6Months;
      }

      await Staking.registTypeU(
        coinStr,
        amount,
        interesRate,
        info.stakingExchangeRate,
        periodTypeU,
      );
      alert(t('staking2.startStaking'));
      setLoading(false);
      navigate('/staking/1');
    } catch (err: any) {
      setLoading(false);
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  };

  useEffect(() => {
    coinBalanceAvailable('BAGO', setBAGOAvailable);
    batyBalanceAvailable('BATY', setBAUAvailable);
    getInfo();
  }, []);

  const CoinListItem = ({ symbol }: any) => (
    <List
      disabled={!coinVisible}
      onPress={() => {
        selectCoin(symbol);
      }}
      background={coin === symbol ? '#EDEDED' : '#FFFFFF'}>
      <Language>{symbol === 'BAU' ? 'BATY' : symbol}</Language>
      {coin === symbol && <img src={main_check as any} alt="" />}
    </List>
  );

  const PeriodListItem = ({ date }: any) => (
    <List2
      disabled={!periodVisible}
      onPress={() => {
        selectPeriod(date);
      }}
      background={periodTypeU === date ? '#EDEDED' : '#FFFFFF'}>
      <Language>
        {date} {t('staking2.date')}
      </Language>
      {periodTypeU === date && <img src={main_check as any} alt="" />}
    </List2>
  );

  return (
    <Container>
      <TopNav2 navigate={navigate}>Staking</TopNav2>
      <Rectangle />
      <Main_Wrap>
        <Main_Box>
          <Frame>
            <Top_Amount>
              <Top_Amount_Text>{t('staking2.available')}</Top_Amount_Text>
            </Top_Amount>
            <Top_Available>
              <Top_Available_Text>
                {regexp(bagoAvailable)} BAGO
              </Top_Available_Text>
              <Top_Available_Text>
                {regexp(bauAvailable)} BATY
              </Top_Available_Text>
            </Top_Available>
            <Top_Line />
            <Warn_Fram>
              <Warn_Text>{t('staking2.autoSwap')}</Warn_Text>
            </Warn_Fram>
          </Frame>
          <Frame>
            <Bottom_Ratio>
              <Bottom_Ratio_Text>
                {t('staking2.swapRatio')} 1 BAGO = {info.stakingExchangeRate}{' '}
                BATY
              </Bottom_Ratio_Text>
            </Bottom_Ratio>
            <Bottom_Input_Box>
              <Select_Box onPress={() => setCoinVisible(!coinVisible)}>
                <Inner_Text>{coin === 'BAU' ? 'BATY' : coin}</Inner_Text>
                <img src={select_arrow as any} alt="" />
              </Select_Box>
              <List_Box visible={coinVisible}>
                <CoinListItem symbol="NON" />
                <Line />
                {/* <CoinListItem symbol="BAU" /> */}
              </List_Box>
              <Price_Input
                value={amount}
                keyboardType="numeric"
                onChangeText={text => setAmount(text.replace(/[^0-9]/g, ''))}
                placeholder={t('staking2.plzEnterPrice') as ''}
                placeholderTextColor="#AAAAAA"
              />
            </Bottom_Input_Box>

            {info.userType === 'U' && (
              <Bottom_Input_Box2>
                <Select_Box2 onPress={() => setPeriodVisible(!periodVisible)}>
                  <Inner_Text>
                    {periodTypeU} {t('staking2.date')}
                  </Inner_Text>
                  <img src={select_arrow as any} alt="" />
                </Select_Box2>
                <List_Box2 visible={periodVisible}>
                  <PeriodListItem date={180} />
                  <Line />
                  <PeriodListItem date={360} />
                </List_Box2>
              </Bottom_Input_Box2>
            )}

            <Bottom_Content_Box>
              <Bottom_Content_Row>
                <Bottom_Content_Left>
                  {t('staking2.amount')}
                </Bottom_Content_Left>
                <Bottom_Content_Right>
                  {coin === 'BAGO'
                    ? info.stakingExchangeRate * (amount || 0)
                    : amount || 0}{' '}
                  BATY
                </Bottom_Content_Right>
              </Bottom_Content_Row>
              <Bottom_Content_Row>
                <Bottom_Content_Left>
                  {t('staking2.period')}
                </Bottom_Content_Left>
                <Bottom_Content_Right>
                  {info.userType === 'S' ? info.stakingPeriod : periodTypeU}{' '}
                  {t('staking2.date')}
                </Bottom_Content_Right>
              </Bottom_Content_Row>
              <Bottom_Content_Row>
                <Bottom_Content_Left>
                  {t('staking2.interestRate')}
                </Bottom_Content_Left>
                {info.userType === 'S' && (
                  <Bottom_Content_Right>
                    {localStorage.i18nextLng === 'eng'
                      ? `${info.stakingInterestRate}% ${t('staking2.month')}`
                      : `${t('staking2.month')} ${info.stakingInterestRate}%`}
                  </Bottom_Content_Right>
                )}
                {info.userType === 'U' &&
                  (periodTypeU === 360 ? (
                    <Bottom_Content_Right>
                      {localStorage.i18nextLng === 'eng'
                        ? `${info.stakingInterestRateTypeU1Year}% ${t(
                            'staking2.month',
                          )}`
                        : `${t('staking2.month')} ${
                            info.stakingInterestRateTypeU1Year
                          }%`}
                    </Bottom_Content_Right>
                  ) : (
                    <Bottom_Content_Right>
                      {localStorage.i18nextLng === 'eng'
                        ? `${info.stakingInterestRateTypeU6Months}% ${t(
                            'staking2.month',
                          )}`
                        : `${t('staking2.month')} ${
                            info.stakingInterestRateTypeU6Months
                          }%`}
                    </Bottom_Content_Right>
                  ))}
              </Bottom_Content_Row>
            </Bottom_Content_Box>
          </Frame>
          <Button_Wrapper>
            {/* {info.userType === 'S' ? ( */}
            {true ? (
              <Basic_Button onPress={regist} disabled={loading} marginTop={20}>
                {loading ? <ActivityIndicator /> : t('staking2.staking')}
              </Basic_Button>
            ) : (
              <Basic_Button
                onPress={registTypeU}
                disabled={loading}
                marginTop={20}>
                {loading ? <ActivityIndicator /> : t('staking2.staking')}
              </Basic_Button>
            )}
          </Button_Wrapper>
        </Main_Box>
      </Main_Wrap>
      <Bottom_Menu_Wrapper>
        <BottomMenu navigate={navigate} currentP={'staking'} />
      </Bottom_Menu_Wrapper>
    </Container>
  );
};

const Container = styled(View)`
  flex: 1;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
`;

const Main_Wrap = styled(ScrollView)`
  width: 100%;
  flex: 1;
  margin-top: 10;
  padding-bottom: 20;
  padding-top: 35;
  background-color: #ffffff;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
`;

const Main_Box = styled(View)`
  width: 100%;
`;

const Frame = styled(View)`
  padding-inline: 20;
  width: 100%;
`;

const Top_Amount = styled(View)`
  width: 100%;
  margin-bottom: 10;
`;

const Bottom_Ratio = styled(View)`
  width: 100%;
  margin-top: 25px;
  align-items: flex-end;
`;

const Bottom_Ratio_Text = styled(Text)`
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #f959b7;
`;

const Top_Amount_Text = styled(Text)`
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

const Top_Available = styled(View)`
  width: 100%;
  align-items: flex-end;
`;

const Top_Available_Text = styled(Text)`
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 6px;
`;

const Top_Line = styled(View)`
  border-width: 0.8px;
  margin-top: 9px;
  margin-bottom: 15px;
  border-color: #e7e7e7;
  width: 100%;
`;

const Warn_Fram = styled(View)`
  width: 100%;
  flex-direction: row;
  margin-bottom: 25px;
`;

const Warn_Text = styled(Text)`
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: #979797;
`;

const Button_Wrapper = styled(View)`
  width: 100%;
  padding-inline: 20;
`;

const Bottom_Menu_Wrapper = styled(View)`
  width: 100%;
`;

const Bottom_Input_Box = styled(View)`
  margin-top: 15;
  width: 100%;
  flex-direction: row;
  margin-bottom: 30px;
  z-index: 5;
`;

const Bottom_Input_Box2 = styled(View)`
  width: 100%;
  margin-top: -15px;
  flex-direction: row;
  margin-bottom: 30;
  z-index: 3;
`;

const Select_Box = styled(TouchableOpacity)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-block: 20px;
  padding-inline: 15px;
  width: 95px;
  height: 54px;
  background: #ffffff;
  border-width: 1px;
  border-color: #e7e7e7;
  border-radius: 10px;
  margin-right: 10px;
`;

const Select_Box2 = styled(TouchableOpacity)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-block: 20px;
  padding-inline: 15px;
  width: 100%;
  height: 54px;
  background: #ffffff;
  border-width: 1px;
  border-color: #e7e7e7;
  border-radius: 10px;
  margin-right: 10px;
`;

const Inner_Text = styled(Text)`
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-right: 18px;
  color: #484d55;
`;

const Price_Input = styled(TextInput)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-block: 17px;
  padding-inline: 20px;
  height: 55px;
  width: 100%;
  background: #ffffff;
  border-width: 1px;
  border-color: #e7e7e7;
  box-shadow: 0px 4px 6px rgba(208, 207, 207, 0.15);
  border-radius: 10px;
`;

const Bottom_Content_Row = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
`;

const Bottom_Content_Left = styled(Text)`
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #979797;
`;

const Bottom_Content_Right = styled(Text)`
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;
const List_Box = styled(View)<{ visible: boolean }>`
  display: flex;
  border-width: 1;
  z-index: 5;
  border-radius: 10;
  overflow: hidden;
  border-color: #e7e7e7;
  flex-direction: column;
  align-items: center;
  padding: 0;
  width: 110;
  height: 90;
  left: 0;
  top: 42;
  position: absolute;
  transition: opacity 0.5s ease-in-out;
  opacity: ${props => (props.visible ? 1 : 0)};
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.1));
`;
const List_Box2 = styled(View)<{ visible: boolean }>`
  display: flex;
  border-width: 1;
  z-index: 3;
  border-radius: 10;
  overflow: hidden;
  border-color: #e7e7e7;
  flex-direction: column;
  align-items: center;
  padding: 0;
  width: 100%;
  height: 90;
  left: 0;
  top: 42;
  position: absolute;
  transition: opacity 0.5s ease-in-out;
  opacity: ${props => (props.visible ? 1 : 0)};
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.1));
`;

const List = styled(TouchableOpacity)<{ background: string }>`
  box-sizing: border-box;
  display: flex;
  z-index: 5;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-block: 15;
  padding-inline: 20;
  width: 110;
  height: 44;
  background: ${props => props.background};
  flex: none;
`;
const List2 = styled(TouchableOpacity)<{ background: string }>`
  box-sizing: border-box;
  display: flex;
  z-index: 5px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-block: 15;
  padding-inline: 20;
  width: 100%;
  height: 44;
  background: ${props => props.background};
  flex: none;
`;
const Language = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 16;
  color: #000000;
`;

const Line = styled(View)`
  border-color: #ececec;
  border-width: 1;
  width: 110;
`;
const Bottom_Content_Box = styled(View)`
  padding-top: 20px;
  padding-bottom: 5px;
  padding-inline: 16px;
  border-width: 1px;
  border-color: #e7e7e7;
  border-radius: 10px;
`;
export default Staking2;
