import React, { useState, useEffect } from 'react';
import { View, Text, ScrollView } from 'react-native';
import styled from 'styled-components/native';
import { Rectangle, TopNav2 } from '@components/topNav/TopNav';
import { Basic_Button } from '@components/button/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BottomMenu from '@components/bottomMenu/BottomMenu';
import P2PController from '@controller/P2P/P2PController';
const ViewP2P = () => {
  const { t } = useTranslation('locale');
  const navigate = useNavigate();
  const { state } = useLocation();
  const cStat = { R: 'transaction', S: 'success', C: 'cancel' };
  const [transactionData, setTransactionData] = useState({
    amount: 0,
    charge: 0,
    price: 0,
    type: '',
    registAt: '0000-00-00T00:00:00.000Z',
    status: '',
    symbol: '',
  });

  const viewTransaction = async () => {
    P2PController.viewTransaction(state, setTransactionData);
  };

  const cancelTransaction = async () => {
    P2PController.cancelTransaction(state, navigate);
  };

  useEffect(() => {
    if (!state) {
      navigate('/p2p');
    }
    viewTransaction();
  }, []);

  const BottomContentRow = ({ message, content }: any) => (
    <Bottom_Content_Row>
      <Bottom_Content_Right>
        {t(`viewP2P.${message}`) || ''}
      </Bottom_Content_Right>
      <Bottom_Content_Right>{content || ''}</Bottom_Content_Right>
    </Bottom_Content_Row>
  );

  return (
    <Container>
      <TopNav2 navigate={navigate}>{t('viewP2P.viewP2P')}</TopNav2>
      <Rectangle />
      <Main_Wrap>
        <Main_Box>
          <Detail_Box>
            <Bottom_Content_Row>
              <Bottom_Content_Right>
                {transactionData.type === 'S'
                  ? t('viewP2P.sell')
                  : t('viewP2P.buy')}
              </Bottom_Content_Right>
              <Bottom_Content_Right>
                {transactionData.amount}{' '}
                {transactionData.symbol === 'BAU'
                  ? 'BATY'
                  : transactionData.symbol}
              </Bottom_Content_Right>
            </Bottom_Content_Row>
            {[
              {
                message: 'price',
                content: `${transactionData.price} ${t('viewP2P.won')}`,
              },
              {
                message: 'fee',
                content: `${transactionData.charge} ${t('viewP2P.won')}`,
              },
              {
                message: 'requestAt',
                content:
                  `${transactionData.registAt.slice(0, 10)} ` +
                  `${transactionData.registAt.slice(11, 19)}`,
              },
              {
                message: 'status',
                content: `${t(`P2P.${cStat[transactionData.status as 'R']}`)}`,
              },
            ].map(el => (
              <BottomContentRow message={el.message} content={el.content} />
            ))}
          </Detail_Box>
          {transactionData.status === 'R' && (
            <Basic_Button marginTop={130} onPress={cancelTransaction}>
              {t('viewP2P.cancelTransaction')}
            </Basic_Button>
          )}
        </Main_Box>
      </Main_Wrap>
      <BottomMenu navigate={navigate} currentP={'p2p'} />
    </Container>
  );
};

const Container = styled(View)`
  flex: 1;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
`;

const Main_Wrap = styled(ScrollView)`
  width: 100%;
  flex: 1;
  margin-top: 10;
  padding-top: 35;
  padding-left: 15;
  padding-right: 15;
  padding-bottom: 20;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background-color: #ffffff;
`;

const Main_Box = styled(View)`
  width: 100%;
`;

const Bottom_Content_Row = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
`;

const Bottom_Content_Right = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

const Detail_Box = styled(View)`
  border-top-width: 1;
  border-top-color: #dadada;
  border-bottom-width: 1;
  border-bottom-color: #dadada;
  padding-top: 25px;
  padding-bottom: 10px;
`;

export default ViewP2P;
