const locale = {
  controller: {
    plzEnterAllSection: 'Please enter all fields',
    plzMatchFormat: 'please fill out the form',
    success: 'Success',
  },

  main: {
    deposit: 'Deposit',
    withdraw: 'Withdraw',
    myWalletList: 'My Wallet List',
    onReady: 'Getting Ready',
  },

  withdraw: {
    cancel: 'Cancel',
    withdrawal: 'Withdraw',
    OTPforWithdraw: 'OTP registration is required for withdrawal.',
    registOTP: 'Register OTP',
    PwdforWithdraw: 'Set Transfer Password is required for withdrawal.',
    registPwd: 'Set Transfer Password',
    available: 'Withdrawable amount',
    address: 'Address',
    plzEnterAddress: 'Please enter your address',
    QRcode: 'Scan',
    amount: 'Amount',
    plzEnterAmount: 'Please enter amount',
    sendSpeed: 'Transfer Speed',
    fast: 'Fast',
    average: 'Normal',
    slow: 'Slow',
    plzEnterOTP: 'Please enter OTP',
    plzEnterTransferPwd: 'Please enter Transfer Password',
    withdraw: 'Withdraw',
    confirmWithdraw: 'Withdrawal confirmation',
    withdrawDescription: `Withdrawal has been processed.  ${'\n'}Withdrawal completion may be delayed depending on network conditions.`,
    confirmTransaction: 'Transaction confirmation',
    findAddressInput: 'Please enter your ID correctly',
    findAddressFindBtn: 'Find',
    findAddressId: 'ID',
    findAddressName: 'NAME',
    findAddressAddress: 'ADDRESS',
    findAddressNothing: 'No results were found for your search',
    findAddressConfirm: 'Confirm',
    findAddressFindById: 'Find an address by ID',
    outOfGas: "I'm running out of gas money, please top up BNB(",
    outOfGas2: ')',
  },

  transactionHistory: {
    history: 'Deposit and Withdrawal History',
    noHistory: 'There is no deposit / withdrawal history',
    viewMore: 'View more',
    withdraw: 'Withdraw',
    deposit: 'Deposit',
    checkTransaction: 'Transaction confirmation',
    receive: 'Receive',
    send: 'Send',
    confirm: 'Confirm',
  },

  deposit: {
    toastMessage: 'Copied the address.',
    deposit: 'Deposit',
    plzCheckAddress: `Check the mainnet(Binace Smart Chain) ${'\n'}and address when depositing.`,
    depositAddress: 'Deposit address',
    address: 'Address',
    copy: 'Copy',
    QRcode: 'QR code',
  },

  signupInfo: {
    signupInfo: 'Subscription Information',
    email: 'Email',
    ID: 'ID',
    name: 'Name',
    referal: 'Recommender',
    currentPwd: 'Current Password',
    newPwd: 'New Password',
    plzEnterNewPwd: 'Please enter your new password',
    pwdCondition:
      '*Must contain 8 to 16 letters, uppercase and lowercase letters, and numbers.',
    checkNewPwd: 'New password confirmation',
    plzEnterNewPwdAgain: 'Enter your new password again',
    newPwdCondition: '*Passwords do not match.',
    changeSuccess: 'Change done',
    change: 'Change',
  },

  signup: {
    signup: 'Sign Up',
    email: 'Email',
    id: 'ID',
    IDCondition: '*4 to 30 English lowercase letters, numbers only.',
    authenticate: 'Authenticate',
    emailCondition: '*Invalid email format.',
    successAuthenticate: '*Certified.',
    plzEnterAuthenticateCode: 'Enter verification code',
    checkAuthentication: 'Authentication check',
    resendAuthenticateCode: 'Resend verification code',
    pwd: 'Password',
    plzEnterPwd: 'Please enter a password',
    pwdCondition:
      '*Must contain 8 to 16 letters, uppercase and lowercase letters, and numbers.',
    checkPwd: 'Verify password',
    plzEnterPwdAgain: 'Please enter your password again',
    pwdNotMatched: '*Passwords do not match.',
    name: 'Name',
    plzEnterName: 'Please enter your name',
    nameCondition:
      '*Only uppercase and lowercase letters in Korean and English are allowed. (No special symbols or spaces)',
    referal: 'Recommender',
    plzEnterReferal: 'If you have a referral, please enter your referral',
    notUsedID: '*This ID does not exist.',
    tel: 'Phone number',
    plzEnterTel: 'Please enter your phone number',
    plzEntertel2: 'Please enter your phone number',
  },

  settings: {
    logout: 'Log out',
    logoutQuestion: 'Are you sure you want to log out?',
    cancel: 'Cancel',
    confirm: 'Confirm',
    settings: 'Settings',
    signupInfo: 'Subscription information',
    OTPSettings: 'OTP settings',
    visitSite: 'Site Visit',
    contract: 'BAGO purchasing contract',
    sign: 'Sign',
    successSign: 'Success Signed.',
    bank: 'Bank Account Registration',
    transferPwd: 'Set Transfer Password',
  },

  login: {
    email: 'Email',
    pwd: 'Password',
    saveEmail: 'Save Email',
    login: 'Login',
    findPwd: 'Find Password',
    doNotHaveAcount: `Don${"'"}t have an account?`,
    signup: 'Sign Up',
    ifBlank: 'Please enter your ID and password',
    saveId: 'Save ID',
    autoLogin: 'Auto Login',
  },

  findPwd: {
    getIssuedTemporaryPwd: 'Issuance of temporary password',
    temporaryPwdDescription: `Issue a temporary password. ${'\n'}Temporarily issued passwords are kept for 1 hour.`,
    email: 'Email',
  },

  googleOTP: {
    setOTP: 'Google OTP settings',
    description1: 'Install the Google Authenticator app.',
    description2: 'Scan the QR code or enter the key below.',
    description3: 'If you cannot scan the QR code, enter the key below',
    plzEnterCode: 'Please enter your code',
    copy: 'Copy',
    enterOTPCode: 'Enter the Google OTP code.',
    OTPcode: 'OTP code',
    plzEnterOTPCode: 'Enter your OTP code',
    set: 'Set Up',
    cancelOTP1: 'Cancel OTP registration.',
    cancelOTP2: 'Cancel OTP registration',
  },

  staking1: {
    stakingAmount: 'staked quantity',
    miningAmount: 'real-time revenue',
    available: 'Withdrawable',
    plzEnterWithdrawal: 'Enter the amount to withdraw.',
    withdrawal: 'withdrawal',
    startStaking: 'Start Staking',
    inducementQuantity: 'GB',
    withdrew: 'Withdrew.',
    failedAmount: 'Please enter the correct coin amount.',
  },

  staking2: {
    available: 'Amount available for staking',
    autoSwap: 'BAGO is automatically swapped to BATY when staking.',
    swapRatio: '*swap rate',
    plzEnterPrice: 'Please enter amount',
    amount: 'quantity',
    period: 'period',
    interestRate: 'interest rate',
    date: 'days',
    month: 'per month',
    staking: 'staking',
    failedAmount: 'Please enter the correct coin amount.',
    coinLow: 'More than the available coin amount.',
    startStaking: 'Start staking.',
  },

  staking3: {
    history: 'Staking History',
    myAmount: 'My BATY Balance',
    stakingInterest: 'staking revenue',
    stakingInterestReferal: 'staking revenue',
    stakingStart: 'start staking',
    stakingChange: 'staking conversion',
    withdrawalChange: 'withdrawal conversion',
    withdrawal: 'withdrawal',
    stakingFinish: 'staking finish',
    more: 'See More',
    noHistory: 'There is no staking / withdrawal history',
  },

  P2P: {
    all: 'my transaction',
    buy: 'buy',
    sell: 'sell',
    success: 'transaction completed',
    transaction: 'trading',
    cancel: 'Cancel transaction',
    more: 'See More',
    request: 'Transaction request',
    noContents: 'No Trnasaction',
    id: 'ID',
    amount: 'Sale Late',
    quote: 'Unit Sales Price',
    price: 'Total Price',
    registAt: 'Regist Date',
    successAt: 'Success Date',
  },

  requestP2P: {
    requestP2P: 'P2P transaction request',
    coinPrice: 'coin price',
    P2PTransaction: 'P2P trading',
    buy: 'buy',
    sell: 'sell',
    plzEnterPrice: 'Please enter amount',
    available: 'maximum available for sale',
    tel: 'contact',
    price: 'transaction amount',
    fee: 'charge',
    won: '￦',
    feeGuide: '* Fees are expenses to support safe transactions.',
    requestTransaction: 'Transaction request',
    selectCoin: 'coin selection',
    transactionAmount: 'transaction volume & hoped price',
    plzEnterTranasactionAmount: 'Please enter quantity',
    bank: 'bank',
    selectBank: 'bank selection',
    account: 'account number',
    plzEnterAccount: 'Please enter your account number',
    plzEnterBank: 'Please enter your bank',
    plzEnterOwner: 'Please enter your account holder',
    plzEnterHopedPrice: 'Please enter your hoped price',
    owner: 'Account Holder',
    nameWarn: '* If it is not in your name, the transaction may be rejected.',
    success:
      'Registered. If there is no transaction for 3 days, it will be automatically canceled.',
    failedAmount: 'Please enter the correct coin amount.',
    coinLow: 'More than the available coin amount.',
    bankChange: 'Account Address Change',
  },

  viewP2P: {
    viewP2P: 'View P2P transactions',
    buy: 'buy',
    sell: 'sell',
    price: 'transaction amount',
    fee: 'charge',
    requestAt: 'Request date',
    status: 'transaction status',
    success: 'transaction completed',
    transaction: 'trading',
    cancel: 'Cancel transaction',
    won: '￦',
    cancelTransaction: 'cancel the transaction',
  },

  bankInfo: {
    save: 'Save',
    check: 'Are you sure what you entered is correct?',
  },

  transferPwd: {
    signupInfo: 'Set Transfer Password',
    currentPwd: 'Password',
    newPwd: 'Transfer Password',
    plzEnterNewPwd: 'Please enter your Transfer password',
    pwdCondition: '*Must contain 6 letters, and numbers.',
    checkNewPwd: 'Transfer password confirmation',
    plzEnterNewPwdAgain: 'Enter your Transfer password again',
    newPwdCondition: '*Passwords do not match.',
    changeSuccess: 'Change done',
    change: 'Change',
  },
};

export default locale;
