import CustomAxios from '../common/CustomAxios';

export default class OTP {
  public static set = async (secret: string, code: string) => {
    const payload = { secret, token: code };
    const res = await CustomAxios.post('/wallet/otp/set', payload);
    return res.data;
  };

  public static img = async () => {
    const res = await CustomAxios.get('/wallet/otp/img');
    return res.data;
  };

  public static remove = async () => {
    const res = await CustomAxios.get('/wallet/otp/remove');
    return res.data;
  };

  public static check = async () => {
    const res = await CustomAxios.get('/wallet/otp/check');
    return res.data;
  };
}
