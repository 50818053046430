import React, { useState, useEffect } from 'react';
import {
  View,
  Dimensions,
  Text,
  TouchableOpacity,
  Image,
  TextInput,
} from 'react-native';
import styled from 'styled-components/native';

const login_logo = require('@assets/img/login_logo.png');
const  main_dropdown  = require('@assets/img/main_dropdown.png');
const  main_check  = require('@assets/img/main_check.png');
import {
  Closable_TextInput_Login,
  Visiable_TextInput_Login,
} from '@components/textInput/TextInput';
import { Basic_Button } from '@components/button/Button';
import { useNavigate } from 'react-router-dom';
import Sign from '@model/user/Sign';
import { useTranslation } from 'react-i18next';
import { Popable, Popover } from 'react-native-popable';
import { useToast } from 'react-native-toast-notifications';

const Login = () => {
  const toast = useToast();
  const { t, i18n } = useTranslation('locale');
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [selectedLanguage, setSelectedLanguage]: any = useState('kor');
  const [visible, setVisible] = useState(false);
  const [IDConditionDuration, setIDConditionDuration] = useState(false);
  const [saveID, setSaveID] = useState(false);
  const [autoLogin, setAutoLogin] = useState(false);

  const languageChanger = (itemValue: string) => {
    setSelectedLanguage(itemValue);
    chgLocale(itemValue);
  };
  const chgLocale = (lang: any) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('i18nextLng', lang);
  };

  const isSave = () => {
    if (localStorage.saveID) {
      setEmail(localStorage.saveID);
      setSaveID(true);
    }
    if (localStorage.autoLogin) {
      setAutoLogin(true);
      const autoLogin = JSON.parse(localStorage.autoLogin);
      sessionStorage.setItem('accessToken', autoLogin.accessToken);
      sessionStorage.setItem('checkSum', autoLogin.checkSum);
      navigate('/main', { replace: true });
    }
  };

  const login = async () => {
    try {
      if (email === '' || password === '') {
        throw Error(t('login.ifBlank'));
      }
      const result = await Sign.login(email, password);
      sessionStorage.setItem('accessToken', result.accessToken);
      sessionStorage.setItem('checkSum', result.checkSum);

      if (saveID) {
        localStorage.setItem('saveID', email);
      } else {
        localStorage.removeItem('saveID');
      }

      if (autoLogin) {
        localStorage.setItem(
          'autoLogin',
          JSON.stringify({
            accessToken: result.accessToken,
            checkSum: result.checkSum,
          }),
        );
      } else {
        localStorage.removeItem('autoLogin');
      }

      navigate('/main', { replace: true });
    } catch (err) {
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  };
  useEffect(() => {
    if (localStorage.i18nextLng) {
      setSelectedLanguage(localStorage.i18nextLng);
    }
    if (sessionStorage.accessToken) {
      navigate('/main', { replace: true });
    }
    isSave();
    console.log(login_logo);
  }, []);
  return (
    <Container>
      <Language_Box>
        <Pl onPress={() => setVisible(!visible)}>
          <PlText>{selectedLanguage === 'kor' ? '한국어' : 'English'}</PlText>
          <Image source={main_dropdown} alt="" style={{ resizeMode: 'cover', width: '9px', height: '5px' }}  />
        </Pl>
        <List_Box visible={visible}>
          <List
            disabled={!visible}
            onPress={() => {
              languageChanger('kor');
              setVisible(false);
            }}
            background={selectedLanguage === 'kor' ? '#EDEDED' : '#FFFFFF'}>
            <Language>한국어</Language>
            {selectedLanguage === 'kor' && <Image source={main_check} alt="" />}
          </List>
          <Line />
          <List
            disabled={!visible}
            onPress={() => {
              languageChanger('eng');
              setVisible(false);
            }}
            background={selectedLanguage === 'eng' ? '#EDEDED' : '#FFFFFF'}>
            <Language>English</Language>
            {selectedLanguage === 'eng' && <img src={main_check} alt="" />}
          </List>
        </List_Box>
      </Language_Box>
      <Main_Wrap>
        <Main_Top>
          <Title_Wrap>
            {/* <img src={login_logo} alt="login_logo" /> */}
            <Image source={login_logo}  style={{ resizeMode: 'cover', width: '200px', height: '43px' }}  />
          </Title_Wrap>
          <Main_Login_Wrap>
            <Main_Login_Input_Wrap>
              <Main_Login_Text_Input_Wrap>
                <Closable_TextInput_Login
                  placeholder="ID"
                  value={email}
                  setValue={setEmail}
                  marginBottom={20}
                  frontIcon="email"
                  IDChanger
                  IDError={() => {
                    if (!IDConditionDuration) {
                      setIDConditionDuration(true);
                      toast.show(t('signup.IDCondition'), {
                        type: 'danger',
                        placement: 'top',
                        duration: 2000,
                        animationType: 'slide-in',
                      });
                      setTimeout(() => setIDConditionDuration(false), 1000);
                    }
                  }}
                />
                <Visiable_TextInput_Login
                  visible={passwordVisible}
                  setVisible={setPasswordVisible}
                  placeholder="Password"
                  value={password}
                  setValue={setPassword}
                  frontIcon="pwd"
                />
                <Check_Row>
                  <Check_Box_Button
                    onPress={() => {
                      setSaveID(!saveID);
                    }}>
                    <input
                      type="checkbox"
                      id="checkbox"
                      name="checkbox"
                      checked={saveID}
                      onChange={() => {
                        setSaveID(!saveID);
                      }}
                    />
                    <Check_Box_Label> {t('login.saveId')}</Check_Box_Label>
                  </Check_Box_Button>
                  <Check_Box_Button
                    onPress={() => {
                      setAutoLogin(!autoLogin);
                    }}>
                    <input
                      type="checkbox"
                      id="checkbox"
                      name="checkbox"
                      checked={autoLogin}
                      onChange={() => {
                        setAutoLogin(!autoLogin);
                      }}
                    />
                    <Check_Box_Label> {t('login.autoLogin')}</Check_Box_Label>
                  </Check_Box_Button>
                </Check_Row>
              </Main_Login_Text_Input_Wrap>
            </Main_Login_Input_Wrap>
            <Bottom_Btn_Wrap>
              <Basic_Button marginBottom={20} onPress={login}>
                {t('login.login')}
              </Basic_Button>
              <Pwd_Search_Row>
                <Pwd_Search_Row1 onPress={() => navigate('/signup')}>
                  <Pwd_Search>{t('login.signup')}</Pwd_Search>
                </Pwd_Search_Row1>
                <Center_Line />
                <Pwd_Search_Row1>
                  <Pwd_Search>{t('login.findPwd')}</Pwd_Search>
                </Pwd_Search_Row1>
              </Pwd_Search_Row>
            </Bottom_Btn_Wrap>
          </Main_Login_Wrap>
        </Main_Top>
      </Main_Wrap>
    </Container>
  );
};

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;
const Container = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Main_Wrap = styled(View)`
  padding-top: 115;
  width: 100%;
  flex: 1;
`;
const Main_Top = styled(View)`
  width: 100%;
`;

const Title_Wrap = styled(View)`
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 59px;
`;

const Main_Login_Wrap = styled(View)`
  margin-top: 55;
  width: 100%;
  padding-left: 50;
  padding-right: 50;
  height: 288;
`;

const Main_Login_Input_Wrap = styled(View)`
  width: 100%;
  height: 150;
`;

const Main_Login_Text_Input_Wrap = styled(View)`
  width: 100%;
  height: 130px;
  margin-bottom: 20;
  align-items: center;
`;

const Center_Line = styled(View)`
  width: 10;
  border-width: 1;
  border-color: #dadada;
  transform: rotate(90deg);
`;

const Popable_ID = styled(Popover)`
  width: 100%;
`;

const Bottom_Btn_Wrap = styled(View)`
  width: 100%;
  height: 90px;
  margin-top: 40;
`;

const Pwd_Search_Row = styled(View)`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Pwd_Search_Row1 = styled(TouchableOpacity)`
  width: 50%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Pwd_Search = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 14;
  letter-spacing: -0.02;
  color: #6f6f6f;
`;
const Language_Box = styled(View)`
  width: 100%;
  align-items: flex-end;
  gap: 20;
  z-index: 10;
  margin-bottom: -60;
`;

//border-radius: 10;
const List_Box = styled(View) <{ visible: boolean }>`
  display: flex;
  border-width: 1;
  z-index: 5;
  
  overflow: hidden;
  border-color: #e7e7e7;
  flex-direction: column;
  align-items: center;
  padding: 0;
  width: 110;
  height: 90;
  transition: opacity 0.5s ease-in-out;
  opacity: ${props => (props.visible ? 1 : 0)};
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.1));
`;

const List = styled(TouchableOpacity) <{ background: boolean }>`
  box-sizing: border-box;
  display: flex;
  z-index: 5;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-block: 15;
  padding-inline: 20;
  width: 110;
  height: 44;
  cursor: pointer;
  background: ${props => props.background};
  flex: none;
`;
const Language = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 16;
  color: #000000;
`;

const Pl = styled(TouchableOpacity)`
  flex-direction: row;
  width: 100%;
  margin-top: 20;
  margin-bottom: -20;
  justify-content: flex-end;
  gap: 5;
  padding-block: 12;
  padding-inline: 12;
  cursor: pointer;
`;
const PlText = styled(Text)`
  font-weight: 500;
  font-size: 14;
  line-height: 16;
  color: #6f6f6f;
`;
const Line = styled(View)`
  border-color: #ececec;
  border-width: 1;
  width: 110;
`;
const Check_Box_Button = styled(TouchableOpacity)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 20;
`;
const Check_Box_Label = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 18;
  text-transform: capitalize;
  color: #6f6f6f;
`;
const Check_Row = styled(View)`
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  margin-top: 20;
`;
export default Login;
