import CustomAxios from '../common/CustomAxios';

export default class Staking {
  public static info = async () => {
    const res = await CustomAxios.get('/staking/info/regist');
    return res.data;
  };

  public static regist = async (
    symbol: string,
    amount: number,
    interestRate: number,
    exchangeRate: number,
    period: number,
  ) => {
    const payload = {
      symbol,
      amount,
      interestRate,
      exchangeRate,
      period,
    };
    await CustomAxios.post('/staking/regist', payload);
    return;
  };

  public static registTypeU = async (
    symbol: string,
    amount: number,
    interestRate: number,
    exchangeRate: number,
    period: number,
  ) => {
    const payload = {
      symbol,
      amount,
      interestRate,
      exchangeRate,
      period,
    };
    await CustomAxios.post('/staking/regist/type/u', payload);
    return;
  };

  public static withdrawal = async (amount: number) => {
    const payload = {
      amount,
    };
    await CustomAxios.post('/staking/withdrawal', payload);
    return;
  };

  public static stakingInfo = async () => {
    const data = await CustomAxios.get('/staking/info');
    return data.data;
  };

  public static list = async (offset: number, limit: number) => {
    const res = await CustomAxios.get(
      '/staking/history' + `/?limit=${limit}&offset=${offset}`,
    );
    return res.data;
  };
}
