import React, { useState, useEffect } from 'react';
import { View, Dimensions, Text, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Code = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.accessToken) {
      navigate('/main', { replace: true });
    }

    try {
      let code = location.pathname.split('/')[2];      
      sessionStorage.setItem('branchCode', code);            
    } catch (e) {
      console.log(e);
    }

    navigate('/signup', { replace: true });

  }, []);
  return (
    <Container>
      <Main_Wrap>
        <div>가입코드입니다</div>
      </Main_Wrap>
    </Container>
  );
};

const Container = styled(View)`
  flex: 1;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
`;

const Main_Wrap = styled(View)`
  width: 100%;
  padding-top: 35;
  padding-bottom: 45;
  flex: 1;
  padding-left: 20;
  padding-right: 20;
`;


export default Code;
