import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, Dimensions } from 'react-native';
import styled from 'styled-components/native';
import { Basic_Button } from '@components/button/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BottomMenu from '@components/bottomMenu/BottomMenu';
import P2PController from '@controller/P2P/P2PController';
import TopNav from '@components/topNav/TopNav';
import {
  Label_TextInput,
  Visiable_Label_TextInput,
  Basic_TextInput,
} from '@components/textInput/TextInput';
import Sign from '../../../model/user/Sign';


const InquryView = () => {
  const location = useLocation();

  const { t } = useTranslation('locale');

  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [inqureTime, setInqureTime] = useState('');
  const [status, setStatus] = useState('');
  const [content, setContent] = useState(''); // [sic
  const [reply, setReply] = useState('');
  const [mode, setMode] = useState('NEW');
  const [iIdx, setIIdx] = useState(0);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    let code = location.pathname.split('/')[2];
    setIIdx(Number(code));
    if (code !== undefined && code !== '0') {

      Sign.inquryDetail(Number(code)).then((res: any) => {
        setTitle(res.title);
        setMode('EDIT');
        setInqureTime(res.inqureTime.split('T')[0]);
        let status = '';
        if (res.status === 'A') {
          status = '접수';
        } else if (res.status === 'P') {
          status = '처리중';
        } else if (res.status === 'C') {
          status = '완료';
        }
        setStatus(status);
        setContent(res.content);

        Sign.inquryReplyList(Number(code)).then((res: any) => {
          if (res !== undefined) {
            setRows(res);
          }
        });
      });
    };
  }, []);


  return (
    <Container>
      <TopNav navigate={navigate}>문의하기</TopNav>
      <Main_Wrap>
        <Main_Box>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span style={{ width: "100px", paddingTop: "35px" }} >제목</span>
            <Basic_TextInput
              title={"제목"}
              value={title}
              setValue={setTitle}
              placeholder={"제목을 입력해주세요."}
              color="#ABABAB"
              marginTop={35}
              style={{ width: '80%' }}
            />
          </div>
          {mode === 'NEW' || (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span style={{ width: "100px", paddingTop: "15px" }} >일자</span>
              <Basic_TextInput
                title={"일자"}
                value={inqureTime}
                placeholder={"제목을 입력해주세요."}
                color="#ABABAB"
                marginTop={15}
                style={{ width: '80%' }}
              />
            </div>)}
          {mode === 'NEW' || (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span style={{ width: "100px", paddingTop: "15px" }} >상태</span>
              <Basic_TextInput
                title={"제목"}
                value={status}
                placeholder={"제목을 입력해주세요."}
                color="#ABABAB"
                marginTop={15}
                style={{ width: '80%' }}
              />
            </div>)}
          <div
            style={{ width: '100%', marginTop: '15px', padding: '10px' }}
          >
            <span style={{ width: "100px", paddingTop: "15px" }} >문의내용</span>
            <textarea
              style={{
                width: '100%', height: '200px', marginTop: '15px', padding: '10px'
                , border: '1px solid #ededed', borderRadius: '5px'
                // placeholderTextColor = '#AAAAAA',

              }}
              placeholder={"문의내용을 입력해주세요."}
              value={content}
              onChange={(e) => {
                if (mode === 'NEW') {
                  setContent(e.target.value);
                }
              }}

            >
            </textarea>
          </div>
        </Main_Box>
        <Basic_Button
          placeholder={t('P2P.title')}
          style={{ display: mode === 'EDIT' ? 'none' : 'block' }}
          onPress={() => {
            if (title === '') {
              alert('제목을 입력해주세요.');
              return;
            }
            if (content === '') {
              alert('문의내용을 입력해주세요.');
              return;
            }
            Sign.newInqury(title, content).then((res: any) => {
              if (res.result === "success") {
                alert('문의가 등록되었습니다.');
                navigate('/inqury');
              }
            });
          }}
        >
          문의하기
        </Basic_Button>
        {mode === 'NEW' || (
        <Main_Box>
          {rows.map((el: any, key: number) => (
            <div 
              key={`${key}list`}
              style={{ width: '100%', marginTop: '15px'
                , border: '1px solid #ededed', borderRadius: '5px'
                // 질문이면 그린, 답변이면 블루
                , backgroundColor: `${el.rType === 'A' ? '#f7fff5' : '#e5f7ff'}`
                // , background : `${el.rType === 'A' ? '#' : '#e5f7ff'}`
                , marginRight: `${el.rType === 'A' ? '0px' : '20px'}`
                , marginLeft: `${el.rType === 'A' ? '20px' : '0px'}`
               }}
              dangerouslySetInnerHTML={{ __html: el.reply.replace(/(?:\r\n|\r|\n)/g, '<br />') }}>
            </div>
          )
          )}

          <textarea
            style={{
              width: '100%', height: '200px', marginTop: '15px', padding: '10px'
              , border: '1px solid #ededed', borderRadius: '5px',
              // placeholderTextColor = '#AAAAAA',
              display: mode === 'EDIT' && status == '처리중' ? 'block' : 'none'
            }}
            placeholder={"추가 문의내용을 입력해주세요."}
            value={reply}
            onChange={(e) => {
              setReply(e.target.value);
            }}
          >
          </textarea>
          <Basic_Button
            placeholder={t('P2P.title')}
            style={{ display: mode === 'EDIT' && status == '처리중' ? 'block' : 'none', marginTop: '15px'}}
            onPress={() => {
              if (reply === '') {
                alert('추가 문의 내용을 입력해주세요.');
                return;
              }
              Sign.inquryReply(iIdx, reply).then((res: any) => {
                if (res.result === "success") {
                  alert('문의가 등록되었습니다.');
                  navigate('/inqury');
                }
              });
            }}
          >
            추가 문의하기
          </Basic_Button>
        </Main_Box>)}
      </Main_Wrap >
    </Container >
  );
};



const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;
const Container = styled(View)`
  flex: 1;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
`;

const No_Content_Box = styled(View)`
  height: 100;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 50;
`;

const No_Content = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  padding-left: 15;
  line-height: 15;
  color: #1e1e1e;
`;


const Main_Wrap = styled(View)`
  width: 100%;  
  padding-bottom: 45;
  flex: 1;
  padding-left: 20;
  padding-right: 20;
`;

const Main_Box = styled(View)`
  width: 100%;
  align-items: center;
  margin-bottom: 20;
`;

const Valid_Label_Row = styled(View)`
  width: 100%;
  align-items: flex-start;
  margin-top: 15;
`;

const Valid_Label = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 400;
  font-size: 12;
  line-height: 12;
  text-transform: capitalize;
  color: #1fcadb;
`;


// const Main_Wrap = styled(View)`
//   width: 100%;
//   flex: 1;
//   padding-top: 35;
//   padding-left: 20;
//   padding-right: 20;
// `;

// const Main_Box = styled(View)`
//   width: 100%;
// `;


const Transaction_Coin_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  text-align: center;
`;

const Transaction_Coin_Wrap2 = styled(View)`
  flex: 1;
`;

const Transaction_Content_Row = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
const Transaction_Line = styled(View)`
  border-color: #e7e7e7;
  border-width: 0.8px;
  width: 100%;
`;

const Transaction_Row2 = styled(View)`
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
`;
const Transaction_Row3 = styled(View)`
  width: 100%;
  margin-top: -10px;
  padding-top: 10px;
  margin-bottom: 10px;
`;
const Transaction_List = styled(View)`
  width: 100%;
`;
const Transaction_Box = styled(View)`
  width: 100%;
  background: #ffffff;
  padding-top: 30px;
  padding-bottom: 20px;
  padding-inline: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Transaction_Button = styled(TouchableOpacity)`
  margin-top: 30px;
  border: 1px solid #e7e7e7;
  border-width: 1px;
  border-color: #e7e7e7;
  border-radius: 35px;
  width: 107px;
  height: 42px;
  justify-content: center;
  align-items: center;
`;

const Transaction_Button_Inner_Wrap = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`;

const Transaction_Button_Inner_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #7e7e7e;
`;

export default InquryView;
