import P2P from '@model/p2p/P2P';
import Sign from '@model/user/Sign';

export default class P2PController {
  static async getList(
    isMore: boolean,
    type: string,
    listPerPage: number,
    currentPage: number,
    setTransactionList: any,
    transactionList: any,
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>,
    setTotalCount: React.Dispatch<React.SetStateAction<number>>,
  ) {
    try {
      if (isMore) {
        const data = await P2P.list(
          type,
          listPerPage,
          listPerPage * currentPage,
        );
        setTransactionList([...transactionList, ...data.rows]);
        setCurrentPage(currentPage + 1);
      } else {
        const data = await P2P.list(type, listPerPage, 0);
        setTransactionList(data.rows);
        setTotalCount(data.totalCount);
        setCurrentPage(1);
      }
    } catch (err: any) {
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  }

  static async getGlobalList(
    isMore: boolean,
    type: string,
    listPerPage: number,
    currentPage: number,
    setTransactionList: any,
    transactionList: any,
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>,
    setTotalCount: React.Dispatch<React.SetStateAction<number>>,
  ) {
    try {
      if (isMore) {
        const data = await P2P.globalList(
          type,
          listPerPage,
          listPerPage * currentPage - 2,
        );
        setTransactionList([...transactionList, ...data.rows]);
        setCurrentPage(currentPage + 1);
      } else {
        const data = await P2P.globalList(type, listPerPage - 2, 0);
        setTransactionList(data.rows);
        setTotalCount(data.totalCount);
        setCurrentPage(1);
      }
    } catch (err: any) {
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  }

  static async getSuccessList(
    isMore: boolean,
    type: string,
    listPerPage: number,
    currentPage: number,
    setTransactionList: any,
    transactionList: any,
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>,
    setTotalCount: React.Dispatch<React.SetStateAction<number>>,
  ) {
    try {
      if (isMore) {
        const data = await P2P.successList(
          type,
          listPerPage,
          listPerPage * currentPage - 3,
        );
        setTransactionList([...transactionList, ...data.rows]);
        setCurrentPage(currentPage + 1);
      } else {
        const data = await P2P.successList(type, listPerPage - 3, 0);
        setTransactionList(data.rows);
        setTotalCount(data.totalCount);
        setCurrentPage(1);
      }
    } catch (err: any) {
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  }

  static async viewTransaction(
    state: any,
    setTransactionData: React.Dispatch<
      React.SetStateAction<{
        amount: number;
        charge: number;
        price: number;
        type: string;
        registAt: string;
        status: string;
        symbol: string;
      }>
    >,
  ) {
    try {
      const data = await P2P.detail(state);
      setTransactionData(data);
    } catch (err: any) {
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  }

  static async cancelTransaction(state: any, navigate: any) {
    try {
      await P2P.cancel(state);
      navigate(-1);
    } catch (err: any) {
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  }

  static async getFeeInfo(
    setFeeInfo: React.Dispatch<
      React.SetStateAction<{
        NONBasePrice: number;        
        p2pFeeRate: number;
      }>
    >,
    setHopedQuote: any,
  ) {
    try {
      const data = await P2P.info();      
      setFeeInfo(data);
      setHopedQuote(data.NONBasePrice);
    } catch (err: any) {
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  }

  static async registTransaction(
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    transactionAmount: any,
    t: any,
    type: any,
    coin: string,
    bank: any,
    account: string,
    feeInfo: {
      NONBasePrice: number;      
      p2pFeeRate: number;
    },
    owner: string,
    hopedQuote: any,
    available: number,
    currentSale: number,
    BATY: number,
    navigate: any,
  ) {
    try {
      setIsLoading(true);
      if (Number(transactionAmount) <= 0 || !transactionAmount) {
        throw new Error(t('requestP2P.failedAmount') as '');
      }
      if (type === 'B') {
        await P2P.regist(
          coin,
          transactionAmount,
          type,
          bank,
          account,
          feeInfo.NONBasePrice,          
          feeInfo.p2pFeeRate,
          owner,
          hopedQuote,
        );
      } else {
        if (
          (coin === 'BAGO' &&
            Number(transactionAmount) >
            Number(available) - Number(currentSale)) ||
          (coin === 'BAU' &&
            Number(transactionAmount) > Number(BATY) - Number(currentSale))
        ) {
          throw new Error(t('requestP2P.coinLow') as '');
        } else if (!account) {
          throw new Error(t('requestP2P.plzEnterAccount') as '');
        } else if (!bank) {
          throw new Error(t('requestP2P.plzEnterBank') as '');
        } else if (!owner) {
          throw new Error(t('requestP2P.plzEnterOwner') as '');
        } else if (!hopedQuote) {
          throw new Error(t('requestP2P.plzEnterHopedPrice') as '');
        }
        await P2P.regist(
          coin,
          transactionAmount,
          type,
          bank,
          account,
          feeInfo.NONBasePrice,          
          feeInfo.p2pFeeRate,
          owner,
          hopedQuote,
          currentSale,
        );
      }
      alert(t('requestP2P.success'));
      navigate(-1);
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  }

  static async bankInfo(
    setBank: any,
    setAccount: React.Dispatch<React.SetStateAction<string>>,
    setOwner: React.Dispatch<React.SetStateAction<string>>,
  ) {
    try {
      const data = await Sign.bankInfo();
      setBank(data.bank);
      setAccount(data.accountAddress);
      setOwner(data.owner);
    } catch (err: any) {
      console.log(err);
    }
  }
}
