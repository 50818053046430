import Staking from '@model/staking/Staking';

export default class StakingController {
  static async getInfo(
    setInfo: React.Dispatch<
      React.SetStateAction<{
        stakingExchangeRate: number;
        stakingInterestRate: number;
        stakingInterestRateTypeU6Months: number;
        stakingInterestRateTypeU1Year: number;
        stakingPeriod: number;
        userType: string;
      }>
    >,
  ) {
    try {
      const data = await Staking.info();
      setInfo(data);
    } catch (err: any) {
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  }
}
