import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,
  ScrollView,
  ActivityIndicator,
  Modal,
} from 'react-native';
import styled from 'styled-components/native';
import { Rectangle, TopNav2 } from '@components/topNav/TopNav';
import { Basic_Button } from '@components/button/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import SignController from '@controller/Sign/SignController';
import { useTranslation } from 'react-i18next';
const select_arrow = require('@assets/img/select_arrow.png');
const main_check = require('@assets/img/main_check.png');
import { Interface_All } from '@interface/Interface_All';
import { Bank_Info_Modal } from '@components/modal/BankInfoModal';
import BottomMenu from '@components/bottomMenu/BottomMenu';
import P2PController from '@controller/P2P/P2PController';

const Request = () => {
  const { t } = useTranslation('locale');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [bankModal, setBankModal] = useState(false);
  const sale = useLocation();
  const [coin, setCoin] = useState('NON');
  const [bank, setBank]: any = useState();
  const [transactionAmount, setTransactionAmount]: any = useState();
  const [hopedQuote, setHopedQuote]: any = useState();
  const [available, setAvailable] = useState(0);
  let saleType = sale.state;
  if (saleType === '') {
    saleType = 'B';
  }
  const [type, setType] = useState(saleType || 'B');
  const [account, setAccount] = useState('');
  const [owner, setOwner] = useState('');
  const [coinVisible, setCoinVisible] = useState(false);
  const [NON, setNON] = useState(0);
  const [currentSale, setCurrentSale] = useState(0);
  const [info, setInfo] = useState({
    address: { email: '', name: '', tel: '', referal: '', role: '' },
  });
  const [feeInfo, setFeeInfo] = useState({    
    NONBasePrice: 0,
    p2pFeeRate: 0,
  });

  const nonBalance = () => SignController.coinBalanceOnly('NON', setNON);

  const selectCoin = (itemValue: string) => {
    setCoin(itemValue);
    setCoinVisible(false);
  };

  const getInfo = () => SignController.info(setInfo);

  const coinBalanceAvailable = () =>
    SignController.coinBalanceAvailable(coin, setAvailable, setCurrentSale);

  const getFeeInfo = async () =>
    await P2PController.getFeeInfo(setFeeInfo, setHopedQuote);

  const registTransaction = async () =>
    await P2PController.registTransaction(
      setIsLoading,
      transactionAmount,
      t,
      type,
      coin,
      bank,
      account,
      feeInfo,
      owner,
      hopedQuote,
      available,
      currentSale,
      NON,
      navigate,
    );

  const bankInfo = async () =>
    await P2PController.bankInfo(setBank, setAccount, setOwner);

  useEffect(() => {
    getInfo();
    getFeeInfo();
  }, []);

  useEffect(() => {
    bankInfo();
  }, [bankModal]);

  useEffect(() => {
    nonBalance();
    coinBalanceAvailable();
  }, [coin]);

  const BottomContentRight = ({ message }: any) => (
    <Bottom_Content_Right>{t(`requestP2P.${message}`)}</Bottom_Content_Right>
  );

  const BottomContentRight2 = ({ message }: any) => (
    <Bottom_Content_Right>{message}</Bottom_Content_Right>
  );

  const BottomContentRow = ({ message, content }: any) => (
    <Bottom_Content_Row>
      <BottomContentRight message={message} />
      <Bottom_Content_Right>{content}</Bottom_Content_Right>
    </Bottom_Content_Row>
  );

  const BottomContentRow2 = ({ message }: any) => (
    <Bottom_Content_Row>
      <BottomContentRight message={message} />
    </Bottom_Content_Row>
  );

  const ListItem = ({ symbol }: any) => (
    <List
      disabled={!coinVisible}
      onPress={() => {
        selectCoin(symbol);
        setHopedQuote(feeInfo[`${symbol}Price` as 'NONBasePrice']);
      }}
      background={coin === symbol ? '#EDEDED' : '#FFFFFF'}>
      <Language>{symbol}</Language>
      {coin === symbol && <img src={main_check as any} alt="" />}
    </List>
  );

  return (
    <Container>
      <Modal transparent={true} visible={isLoading}>
        <ActivityIndicator
          size={'large'}
          style={{
            flex: 1,
            backgroundColor: 'rgba(0,0,0,0.5)',
          }}
        />
      </Modal>
      <Bank_Info_Modal t={t} visable={bankModal} setVisable={setBankModal} />
      <TopNav2 navigate={navigate}>{t('requestP2P.requestP2P')}</TopNav2>
      <Rectangle />
      <Main_Wrap>
        <Main_Box>
          <Frame>
            <BottomContentRow2 message="coinPrice" />
            <Bottom_Content_Row_End>
              <BottomContentRight2
                message={`1 NON = ${feeInfo.NONBasePrice} ${t(
                  'requestP2P.won',
                )}`}
              />
              {/* <BottomContentRight2
                message={`1 BATY = ${feeInfo.BAUBasePrice} ${t(
                  'requestP2P.won',
                )}`}
              /> */}
            </Bottom_Content_Row_End>
            <Middle_Line_Thin />
            <BottomContentRow2 message="P2PTransaction" />
            <Bottom_Content_Row_End>
              <Bottom_Content_Row_Row>
                <Send_Speed_Radio_Button
                  onPress={() => setType('B')}
                  style={{ marginRight: '10px' }}>
                  <input
                    type="radio"
                    id="radio"
                    name="radio"
                    checked={type === 'B'}
                    onChange={() => setType('B')}
                  />
                  <Send_Speed_Radio_Label>
                    {t('requestP2P.buy')}
                  </Send_Speed_Radio_Label>
                </Send_Speed_Radio_Button>
                <Send_Speed_Radio_Button onPress={() => setType('S')}>
                  <input
                    type="radio"
                    id="radio"
                    name="radio"
                    checked={type === 'S'}
                    onChange={() => setType('S')}
                  />
                  <Send_Speed_Radio_Label>
                    {t('requestP2P.sell')}
                  </Send_Speed_Radio_Label>
                </Send_Speed_Radio_Button>
              </Bottom_Content_Row_Row>
            </Bottom_Content_Row_End>
            <Bottom_Input_Box>
              <Bottom_Content_Right2>
                {t('requestP2P.transactionAmount')}
              </Bottom_Content_Right2>
            </Bottom_Input_Box>
            <Bottom_Content_Row style={coinVisible && { zIndex: 5 }}>
              <Price_Input
                value={transactionAmount}
                keyboardType="numeric"
                style={{ textAlign: 'left' }}
                onChangeText={text =>
                  setTransactionAmount(text.replace(/[^0-9]/g, ''))
                }
                placeholder={t('requestP2P.plzEnterTranasactionAmount') as ''}
                placeholderTextColor="#AAAAAA"
              />
              <Select_Box onPress={() => setCoinVisible(!coinVisible)}>
                <Inner_Text>{coin === 'BAU' ? 'BATY' : coin}</Inner_Text>
                <img src={select_arrow as any} alt="" />
              </Select_Box>
              <List_Box visible={coinVisible}>
                <ListItem symbol="NON" />
                <Line />
                {/* <ListItem symbol="BAU" /> */}
              </List_Box>
            </Bottom_Content_Row>
            <Bottom_Content_Row3>
              <Price_Input2
                value={hopedQuote}
                keyboardType="numeric"
                style={{ textAlign: 'left' }}
                onChangeText={text =>
                  setHopedQuote(text.replace(/[^0-9]/g, ''))
                }
                placeholderTextColor="#AAAAAA"
              />
              <Bottom_Content_Right2>
                {t('requestP2P.won')} = 1 {coin === 'BAU' ? 'BATY' : coin}
              </Bottom_Content_Right2>
            </Bottom_Content_Row3>

            {type === 'S' && (
              <>
                <Bottom_Content_Row_End>
                  <Bottom_Content_Right_Blue>
                    *{t('requestP2P.available')}{' '}
                    {Number(coin === 'BAU' ? BATY : available) -
                      Number(currentSale)}{' '}
                    {coin === 'BAU' ? 'BATY' : coin}
                  </Bottom_Content_Right_Blue>
                </Bottom_Content_Row_End>

                <BottomContentRow message="selectBank" content={bank} />
                <BottomContentRow message="account" content={account} />
                <BottomContentRow message="owner" content={owner} />

                <Button_Wrapper>
                  <Basic_Button
                    marginTop={10}
                    marginBottom={10}
                    onPress={() => setBankModal(true)}>
                    {t('requestP2P.bankChange')}
                  </Basic_Button>
                </Button_Wrapper>
              </>
            )}

            <Warn_Fram>
              <Warn_Text>{t('requestP2P.nameWarn')}</Warn_Text>
            </Warn_Fram>
          </Frame>

          <Middle_Line_Thin />

          <Frame>
            <BottomContentRow2 message="tel" />
            <Bottom_Content_Row_End>
              <Bottom_Content_Right>{info.address.tel}</Bottom_Content_Right>
            </Bottom_Content_Row_End>

            <Price_Box>
              <Bottom_Content_Row2>
                <Bottom_Content_Left>
                  {t('requestP2P.price')}
                </Bottom_Content_Left>
                <Bottom_Content_Right>
                  {Number(transactionAmount || 0) * Number(hopedQuote)}{' '}
                  {t('requestP2P.won')}
                </Bottom_Content_Right>
              </Bottom_Content_Row2>
              <Bottom_Content_Row>
                <Bottom_Content_Left>{t('requestP2P.fee')}</Bottom_Content_Left>
                <Bottom_Content_Right>
                  {(Number(transactionAmount || 0) *
                    Number(hopedQuote) *
                    feeInfo.p2pFeeRate) /
                    100}{' '}
                  {t('requestP2P.won')}
                </Bottom_Content_Right>
              </Bottom_Content_Row>
            </Price_Box>

            <Warn_Fram>
              <Warn_Text>{t('requestP2P.feeGuide')}</Warn_Text>
            </Warn_Fram>
          </Frame>
          <Button_Wrapper>
            <Basic_Button marginTop={55} 
              onPress={registTransaction}
            >
              {t('requestP2P.requestTransaction')}
            </Basic_Button>
          </Button_Wrapper>
        </Main_Box>
      </Main_Wrap>
      <BottomMenu navigate={navigate} currentP={'p2p'} />
    </Container>
  );
};

const Container = styled(View)`
  flex: 1;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
`;


//border-radius: 10px;
const Price_Box = styled(View)`
  border-width: 1px;
  
  padding: 10px;
  border-color: #e7e7e7;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Main_Wrap = styled(ScrollView)`
  width: 100%;
  flex: 1;
  padding-top: 25;
  padding-bottom: 20;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  margin-top: 10px;
  background-color: #ffffff;
`;

const Main_Box = styled(View)`
  width: 100%;
`;

const Frame = styled(View)`
  padding-inline: 20;
  width: 100%;
  z-index: 5;
`;

const Warn_Fram = styled(View)`
  width: 100%;
  flex-direction: row;
  margin-bottom: 25px;
`;

const Warn_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: #f959b7;
`;

const Middle_Line_Thin = styled(View)`
  height: 2px;
  width: 100%;
  margin-bottom: 10px;
  background: #f8f8fa;
`;

const Button_Wrapper = styled(View)`
  width: 100%;
  padding-inline: 20;
`;

const Bottom_Input_Box = styled(View)`
  margin-top: 15;
  width: 100%;
  flex-direction: row;
  margin-bottom: 30;
  justify-content: space-between;
  align-items: center;
`;


//border-radius: 10px;
const Select_Box = styled(TouchableOpacity)<Interface_All>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-block: 20px;
  padding-inline: 15px;
  width: ${props => props.width || '95px'};
  height: 54px;
  background: #ffffff;
  border-width: 1px;
  border-color: #e7e7e7;  
`;

const Inner_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-right: 18px;
  color: #484d55;
`;


//border-radius: 10px;
const Price_Input = styled(TextInput)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-block: 17px;
  padding-inline: 20px;
  height: 55px;
  width: 65%;
  background: #ffffff;
  border-width: 1px;
  border-color: #e7e7e7;
  box-shadow: 0px 4px 6px rgba(208, 207, 207, 0.15);
  
`;

//border-radius: 10px;
const Price_Input2 = styled(TextInput)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-block: 17px;
  padding-inline: 20px;
  height: 55px;
  width: 40%;
  margin-right: 10px;
  background: #ffffff;
  border-width: 1px;
  border-color: #e7e7e7;
  box-shadow: 0px 4px 6px rgba(208, 207, 207, 0.15);  
`;

const Bottom_Content_Row2 = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 8px;
`;

const Bottom_Content_Row = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
  align-items: center;
`;

const Bottom_Content_Row3 = styled(View)`
  flex-direction: row;
  width: 100%;
  margin-bottom: 8px;
  align-items: center;
`;

const Bottom_Content_Row_Row = styled(View)`
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
`;

const Bottom_Content_Row_End = styled(View)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  align-items: flex-end;
`;

const Bottom_Content_Left = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #979797;
`;

const Bottom_Content_Right = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;
const Bottom_Content_Right_Blue = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #4b4bf3;
`;

const Bottom_Content_Right2 = styled(Bottom_Content_Right)`
  height: 20px;
`;

const Send_Speed_Radio_Button = styled(TouchableOpacity)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 20;
`;
const Send_Speed_Radio_Label = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 18;
  text-transform: capitalize;
  color: #000000;
`;


//border-radius: 10;
const List_Box = styled(View)<{ visible: boolean }>`
  display: flex;
  border-width: 1;
  z-index: 5;  
  overflow: hidden;
  border-color: #e7e7e7;
  flex-direction: column;
  align-items: center;
  padding: 0;
  width: 110;
  height: 90;
  right: 0;
  top: 42;
  position: absolute;
  transition: opacity 0.5s ease-in-out;
  opacity: ${props => (props.visible ? 1 : 0)};
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.1));
`;

const List = styled(TouchableOpacity)<{ background: string }>`
  box-sizing: border-box;
  display: flex;
  z-index: 5;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-block: 15;
  padding-inline: 20;
  width: 110;
  height: 44;
  background: ${props => props.background};
  flex: none;
`;
const Language = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 16;
  color: #000000;
`;

const Line = styled(View)`
  border-color: #ececec;
  border-width: 1;
  width: 110;
`;
export default Request;
