import React, { useState, useEffect } from 'react';
import { View, Dimensions, Text, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import TopNav from '@components/topNav/TopNav';
import {
  Basic_TextInput,
  Basic_TextInput_Login,
  TextInput_Button,
  Visiable_Label_TextInput,
} from '@components/textInput/TextInput';
import { Basic_Button } from '@components/button/Button';
import { useNavigate } from 'react-router-dom';
import SignController from '@controller/Sign/SignController';
import { useTranslation } from 'react-i18next';

const SignupInfo = () => {
  const { t } = useTranslation('locale');
  const navigate = useNavigate();
  const [changePwd, setChangePwd] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(true);
  const [passwordCheck, setPasswordCheck] = useState('');
  const [passwordCheckValid, setPasswordCheckValid] = useState(true);
  const [passwordCheckVisible, setPasswordCheckVisible] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [info, setInfo] = useState({
    address: { email: '', name: '', tel: '', referal: '', role: '' },
  });

  const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,16}$/;

  const changeBtn = () => setChangePwd(true);

  const getInfo = () => SignController.info(setInfo);

  const pwdUpdate = () =>
    SignController.pwdUpdate(
      currentPassword,
      password,
      passwordValid,
      passwordCheckValid,
      navigate,
      t,
    );

  useEffect(
    () => setPasswordCheckValid(password === passwordCheck),
    [password, passwordCheck],
  );

  useEffect(() => {
    getInfo();
  }, []);
  return (
    <Container>
      <TopNav navigate={navigate}>{t('signupInfo.signupInfo')}</TopNav>
      <Main_Wrap>
        <Main_Box>
          <Frame>
            <Frame_Top_Row>
              <Frame_Top_Text>{t('signupInfo.ID')}</Frame_Top_Text>
            </Frame_Top_Row>
            <Frame_Bottom_ROW>
              <Frame_Bottom_Text>
                {info.address && info.address.email}
              </Frame_Bottom_Text>
            </Frame_Bottom_ROW>
          </Frame>
          <Frame>
            <Frame_Top_Row>
              <Frame_Top_Text>{t('signupInfo.name')}</Frame_Top_Text>
            </Frame_Top_Row>
            <Frame_Bottom_ROW>
              <Frame_Bottom_Text>
                {info.address && info.address.name}
              </Frame_Bottom_Text>
            </Frame_Bottom_ROW>
          </Frame>
          {changePwd ? (
            <>
              <Frame_Top_Row>
                <Frame_Top_Text>{t('signupInfo.currentPwd')}</Frame_Top_Text>
              </Frame_Top_Row>
              <Basic_TextInput_Login
                width="100%"
                value={currentPassword}
                setValue={setCurrentPassword}
                placeholder={t('signupInfo.currentPwd')}
              />
              <Visiable_Label_TextInput
                title={t('signupInfo.newPwd')}
                width="90%"
                value={password}
                setValue={setPassword}
                visible={passwordVisible}
                setVisible={setPasswordVisible}
                placeholder={t('signupInfo.plzEnterNewPwd')}
                regex={passwordRegex}
                fontColor="#ABABAB"
                setValid={setPasswordValid}
                marginTop={40}
              />
              {!passwordValid && (
                <Valid_Label_Row>
                  <Valid_Label>
                    {t('signupInfo.pwdCondition')}
                    {/*, 특수기호 */}
                  </Valid_Label>
                </Valid_Label_Row>
              )}
              <Visiable_Label_TextInput
                title={t('signupInfo.checkNewPwd')}
                width="90%"
                value={passwordCheck}
                setValue={setPasswordCheck}
                fontColor="#ABABAB"
                visible={passwordCheckVisible}
                setVisible={setPasswordCheckVisible}
                placeholder={t('signupInfo.plzEnterNewPwdAgain')}
                marginTop={40}
              />
              {!passwordCheckValid && (
                <Valid_Label_Row>
                  <Valid_Label>{t('signupInfo.newPwdCondition')}</Valid_Label>
                </Valid_Label_Row>
              )}
              <Basic_Button
                marginTop={70}
                marginBottom={50}
                onPress={pwdUpdate}>
                {t('signupInfo.changeSuccess')}
              </Basic_Button>
            </>
          ) : (
            <Frame>
              <Frame_Top_Row>
                <Frame_Top_Text>{t('signupInfo.currentPwd')}</Frame_Top_Text>
              </Frame_Top_Row>
              <Basic_TextInput_Login
                width="80%"
                value={currentPassword}
                setValue={setCurrentPassword}
                placeholder={t('signupInfo.currentPwd')}>
                <TextInput_Button
                  title={t('signupInfo.change')}
                  width={66}
                  onPress={changeBtn}
                  backgroundColor="#ffffff"
                  borderColor="#1D1D56"
                />
              </Basic_TextInput_Login>
            </Frame>
          )}
        </Main_Box>
      </Main_Wrap>
    </Container>
  );
};

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;
const Container = styled(View)`
  flex: 1;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
`;

const Main_Wrap = styled(View)`
  width: 100%;
  flex: 1;
  padding-top: 35;
  padding-left: 20;
  padding-right: 20;
`;

const Main_Box = styled(View)`
  width: 100%;
`;

const Frame = styled(View)`
  width: 100%;
  height: 49;
  margin-bottom: 60;
`;

const Frame_Top_Row = styled(View)`
  width: 100%;
  height: 16;
  margin-bottom: 20;
`;

const Frame_Top_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 15;
  color: #ababab;
`;

const Frame_Bottom_ROW = styled(View)`
  height: 39;
  width: 100%;
  margin-bottom: 28;
  border-bottom-width: 1;
  border-bottom-color: #ededed;
`;

const Frame_Bottom_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding-left: 15;
  line-height: 15;
  color: #1e1e1e;
`;
const Valid_Label_Row = styled(View)`
  width: 100%;
  align-items: flex-start;
  margin-top: 15;
`;

const Valid_Label = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 400;
  font-size: 10;
  line-height: 10;
  text-transform: capitalize;
  color: #00ccfd;
`;

export default SignupInfo;
