import { Interface_All } from '@interface/Interface_All';
import React from 'react';
import { Image, StatusBar, Text, View, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
const topNav_back = require('@assets/img/topnav_back.png');
import { LinearGradient } from 'expo-linear-gradient';
interface MainProps extends Interface_All {
  navigate: any;
  children: any;
}
const TopNav = ({ navigate, children, ...style }: MainProps) => {
  return (
    <Top_Bar_Container {...style}>
      <Top_Bar>
        <StatusBar backgroundColor="#ffffff" />
        <Top_Bar_Back_Btn onPress={() => navigate(-1)}>
          <img src={topNav_back} alt="" />
        </Top_Bar_Back_Btn>
        <Top_Bar_Title>{children}</Top_Bar_Title>
        <Top_Bar_Right />
      </Top_Bar>
    </Top_Bar_Container>
  );
};

export const TopNav2 = ({ navigate, children, ...style }: MainProps) => {
  return (
    <Top_Bar_Container {...style}>
      <Top_Bar>
        <StatusBar backgroundColor="#ffffff00" />
        <Top_Bar_Back_Btn onPress={() => navigate(-1)}>
          <img src={topNav_back} alt="" />
        </Top_Bar_Back_Btn>
        <Top_Bar_Title2>{children}</Top_Bar_Title2>
        <Top_Bar_Right />
      </Top_Bar>
    </Top_Bar_Container>
  );
};

export const Rectangle = () => {
  return (
    <Back_Rectangle
      // colors={['#1d1d56 8.84%', '#1d1d56 52.35%', '#3f3fa7 98.45%']}
      colors={['#1d1d56', '#1d1d56', '#3f3fa7']}
      // useAngle
      // angle={116.26}
      start={{ x: 0, y: 0 }}
      end={{ x: 0.5, y: 0.5}}
    />
  );
};


// <Rectangle
// // colors={['#1d1d56 8%', '#1d1d56 43.24%', '#3f3fa7 89.63%']}

// start={{ x: 0, y: 0 }}
// end={{ x: 0.5, y: 0.5}}
// // useAngle
// // angle={113.99}
// >
// <Circle style={{ borderRadius: 100 }} />
// <Circle2  style={{ borderRadius: 70 }}  />
// </Rectangle>


const Back_Rectangle = styled(LinearGradient)`
  position: absolute;
  width: 100%;
  height: 141px;
  left: 0px;
  top: 0px;
  z-index: -10;
`;
const Top_Bar_Container = styled(View)<Interface_All>`
  width: 100%;
  padding-left: ${props => props.marginLeft || 0};
  padding-right: ${props => props.marginRight || 0};
  margin-bottom: ${props => props.marginBottom || 0};
`;
const Top_Bar = styled(View)`
  justify-content: space-between;
  align-items: center;
  height: 52;
  background-color: #ffffff00;
  width: 100%;
  flex-direction: row;
`;
const Top_Bar_Title = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 15;
  line-height: 20;
  color: #1e1e1e;
`;
const Top_Bar_Title2 = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 15;
  line-height: 20;
  color: #ffffff;
`;
const Top_Bar_Right = styled(View)`
  width: 40;
`;
const Top_Bar_Back_Btn = styled(TouchableOpacity)`
  width: 40;
  height: 40;
  justify-content: center;
  align-items: center;
`;

export default TopNav;
