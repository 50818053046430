import React, { useEffect, useState } from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  Modal,
  TextInput,
  Dimensions,
} from 'react-native';
import styled from 'styled-components/native';
import { Interface_All } from '@interface/Interface_All';
const main_check = require('@assets/img/main_check.png');
const select_arrow = require('@assets/img/select_arrow.png');
import Sign from '@model/user/Sign';
import { isBrowser } from 'react-device-detect';
import { Basic_Button } from '@components/button/Button';

interface Bank_Info_Modal_Interface extends Interface_All {
  children?: any;
  disabled?: boolean;
  visable: boolean;
  setVisable: any;
  t: any;
}

export const Bank_Info_Modal = ({
  t,
  visable,
  setVisable,
}: Bank_Info_Modal_Interface) => {
  const [bankVisible, setBankVisible] = useState(false);
  const [account, setAccount] = useState('');
  const [bank, setBank]: any = useState();
  const [owner, setOwner]: any = useState('');
  const selectBank = (itemValue: string) => {
    setBank(itemValue);
    setBankVisible(false);
  };

  const onClose = () => setVisable(false);

  const updateBankInfo = async () => {
    if (account === '') {
      alert('계좌를 입력해주세요.');
    } else if (owner === null || owner === '') {
      alert('예금주를 입력해주세요.');
    } else {
      try {
        if (confirm(`${bank} / ${account} / ${owner} ${t('bankInfo.check')}`)) {
          await Sign.updateBankInfo(bank, account, owner);
          onClose();
        }
      } catch (err: any) {
        if (err.response) {
          alert(err.response.data.message);
        } else if (err.message) {
          alert(err.message);
        }
      }
    }
  };

  const bankInfo = async () => {
    try {
      const data = await Sign.bankInfo();
      setBank(data.bank);
      setAccount(data.accountAddress);
      setOwner(data.owner);
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    bankInfo();
  }, [visable]);

  return (
    <Bank_Info_Modal_Wrap
      animationType="fade"
      transparent={true}
      visible={visable}>
      <Bank_Info_Modal_Wrap2>
        <Bank_Info_Modal_Wrap3>
          <Bottom_Content_End>
            <Bottom_Content_Right_X_Wrap onPress={onClose}>
              <Bottom_Content_Right_X>X</Bottom_Content_Right_X>
            </Bottom_Content_Right_X_Wrap>
          </Bottom_Content_End>
          <Bottom_Content_Row style={bankVisible && { zIndex: 5 }}>
            <Bottom_Content_Right>{t('requestP2P.bank')}</Bottom_Content_Right>
            <Select_Box
              width="55%"
              onPress={() => setBankVisible(!bankVisible)}>
              <Inner_Text>{bank || t('requestP2P.selectBank')}</Inner_Text>
              <img src={select_arrow as any} alt="" />
            </Select_Box>
            <List_Box2 visible={bankVisible}>
              {[
                '하나',
                'KB',
                'IBK',
                '농협',
                '제일',
                '우리',
                '신한',
                '지역농축협',
                '부산',
                '대구',
                '광주',
                '경남',
                '새마을',
                '전북',
                '한국씨티',
                '수협',
                '저축',
                '신협',
                'JP모간',
                '우체국',
                '카카오뱅크',
                '중국건설',
                '케이뱅크',
                '제주',
                'HSBC',
                '토스뱅크',
              ].map((el, key) => (
                <>
                  <List
                    key={`${key}bank`}
                    disabled={!bankVisible}
                    onPress={() => {
                      selectBank(el);
                    }}
                    background={bank === el ? '#EDEDED' : '#FFFFFF'}>
                    <Language>{el}</Language>
                    {bank === el && <img src={main_check as any} alt="" />}
                  </List>
                  <Line />
                </>
              ))}
            </List_Box2>
          </Bottom_Content_Row>
          <Bottom_Content_Row>
            <Bottom_Content_Right>
              {t('requestP2P.account')}
            </Bottom_Content_Right>
            <Price_Input
              value={account}
              keyboardType="numeric"
              onChangeText={(text: string) =>
                setAccount(text.replace(/[^0-9]/g, ''))
              }
              placeholder={t('requestP2P.plzEnterAccount') as ''}
              placeholderTextColor="#AAAAAA"
            />
          </Bottom_Content_Row>
          <Bottom_Content_Row>
            <Bottom_Content_Right>{t('requestP2P.owner')}</Bottom_Content_Right>
            <Price_Input
              value={owner}
              onChangeText={(text: string) => setOwner(text)}
              placeholderTextColor="#AAAAAA"
            />
          </Bottom_Content_Row>
          <Bottom_Content_Row style={{ marginTop: '20px' }}>
            <Basic_Button onPress={updateBankInfo}>
              {t('bankInfo.save')}
            </Basic_Button>
          </Bottom_Content_Row>
        </Bank_Info_Modal_Wrap3>
      </Bank_Info_Modal_Wrap2>
    </Bank_Info_Modal_Wrap>
  );
};

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

const Bank_Info_Modal_Wrap = styled(Modal)<Interface_All>``;
const Bank_Info_Modal_Wrap2 = styled(View)<Interface_All>`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #00000050;
`;

//border-radius: 15px;
const Bank_Info_Modal_Wrap3 = styled(View)<Interface_All>`
  width: ${_ => (isBrowser ? 375 : '100%')};
  background-color: #ffffff;
  padding: 20px;  
`;

const Bottom_Content_Row = styled(View)`
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 20px;
  align-items: center;
`;

const Bottom_Content_End = styled(View)`
  flex-direction: row;
  justify-content: flex-end;
  padding-block: 5px;
  margin-bottom: 20px;
  align-items: center;
`;

const Bottom_Content_Right = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

const Bottom_Content_Right_X_Wrap = styled(TouchableOpacity)`
  padding-inline: 5px;
  line-height: 20px;
  color: #000000;
`;

const Bottom_Content_Right_X = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

//border-radius: 10;
const List_Box2 = styled(View)<{ visible: boolean }>`
  display: flex;
  border-width: 1;
  z-index: 5;  
  overflow: hidden;
  border-color: #e7e7e7;
  flex-direction: column;
  align-items: center;
  padding: 0;
  width: 110;
  height: 200;
  right: 0;
  top: 42;
  overflow-y: auto;
  position: absolute;
  transition: opacity 0.5s ease-in-out;
  opacity: ${props => (props.visible ? 1 : 0)};
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.1));
`;

const List = styled(TouchableOpacity)<{ background: boolean }>`
  box-sizing: border-box;
  display: flex;
  z-index: 5;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-block: 15;
  padding-inline: 20;
  width: 110;
  height: 44;
  background: ${props => props.background};
  flex: none;
`;
const Language = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 16;
  color: #000000;
`;

const Line = styled(View)`
  border-color: #ececec;
  border-width: 1;
  width: 110;
`;

//border-radius: 10px;
const Select_Box = styled(TouchableOpacity)<Interface_All>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-block: 20px;
  padding-inline: 15px;
  width: ${props => props.width || '95px'};
  height: 54px;
  background: #ffffff;
  border-width: 1px;
  border-color: #e7e7e7;  
  margin-right: 10px;
`;

const Inner_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-right: 18px;
  color: #484d55;
`;

//border-radius: 10px;
const Price_Input = styled(TextInput)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-block: 17px;
  padding-inline: 20px;
  height: 55px;
  width: 60%;
  background: #ffffff;
  border-width: 1px;
  border-color: #e7e7e7;
  box-shadow: 0px 4px 6px rgba(208, 207, 207, 0.15);  
`;
