import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';
import styled from 'styled-components/native';
import { Rectangle, TopNav2 } from '@components/topNav/TopNav';
import { Basic_Button } from '@components/button/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Interface_All } from '@interface/Interface_All';
import P2PController from '@controller/P2P/P2PController';
import BottomMenu from '@components/bottomMenu/BottomMenu';
const Transaction = () => {
  const { t } = useTranslation('locale');
  const navigate = useNavigate();
  const tab1 = useRef(null);
  const tab2 = useRef(null);
  const tab3 = useRef(null);
  const [tabWidth, setTabWidth] = useState(0);
  const [left, setLeft] = useState(0);
  const [type, setType] = useState('S');
  const [transactionList, setTransactionList]: any = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [transactionList2, setTransactionList2]: any = useState([]);
  const [currentPage2, setCurrentPage2] = useState(0);
  const [totalCount2, setTotalCount2] = useState(0);
  const listPerPage = 5;

  const regexp = (num: number) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const getList = async (isMore = false) => {
    await P2PController.getList(
      isMore,
      type,
      listPerPage,
      currentPage,
      setTransactionList,
      transactionList,
      setCurrentPage,
      setTotalCount,
    );
  };

  const getGlobalList = async (isMore = false) => {
    await P2PController.getGlobalList(
      isMore,
      type,
      listPerPage,
      currentPage,
      setTransactionList,
      transactionList,
      setCurrentPage,
      setTotalCount,
    );
  };

  // const getSuccessList = async (isMore = false) => {
  //   await P2PController.getSuccessList(
  //     isMore,
  //     type,
  //     listPerPage,
  //     currentPage2,
  //     setTransactionList2,
  //     transactionList2,
  //     setCurrentPage2,
  //     setTotalCount2,
  //   );
  // };

  useEffect(() => {
    if (type === '') {
      getList();
    } else {
      getGlobalList();
      // getSuccessList();
    }
  }, [type]);

  useLayoutEffect(() => {
    const obj = { '': tab1 as any, B: tab2, S: tab3 };
    const tab = obj[type as ''];
    if (tab.current) {
      setTabWidth((tab.current.offsetWidth * 50) / 100);
      setLeft(tab.current.offsetLeft + (tab.current.offsetWidth * 25) / 100);
    }
  }, [type]);

  return (
    <Container>
      <TopNav2 navigate={navigate}>P2P</TopNav2>
      <Rectangle />
      <Top_Nav_Box>
        <Top_Nav_Frame>
          {[
            { ty: '', t: 'P2P.all', r: tab1 },
            { ty: 'B', t: 'P2P.buy', r: tab2 },
            { ty: 'S', t: 'P2P.sell', r: tab3 },
          ].map(el => (
            <Top_Nav_Tab onPress={() => setType(el.ty)} type={type} ref={el.r}>
              <Top_Nav_Text color={type === el.ty ? '#00CCFD' : ''}>
                {t(el.t)}
              </Top_Nav_Text>
            </Top_Nav_Tab>
          ))}
          <Top_Nav_Slider tabWidth={tabWidth} left={left} />
        </Top_Nav_Frame>
      </Top_Nav_Box>
      <Main_Wrap>
        <Main_Box>
          {transactionList.length ? (
            type === '' ? (
              <Transaction_Box>
                <Transaction_List>
                  {transactionList.map((el: any, key: number) => (
                    <Transaction_Row
                      key={`${key}list`}
                      onPress={() => navigate('/p2p/view', { state: el.pIdx })}>
                      <Transaction_Content_Row>
                        <Transaction_Content_Left>
                          <Transaction_Content_Left_Text>
                            {el.type === 'S' ? t('P2P.sell') : t('P2P.buy')}
                          </Transaction_Content_Left_Text>
                        </Transaction_Content_Left>
                        <Transaction_Content_Right>
                          <Transaction_Coin_Wrap>
                            <Transaction_Coin_Text>
                              {el.amount}{' '}
                              {el.symbol === 'BAU' ? 'BATY' : el.symbol}
                            </Transaction_Coin_Text>
                          </Transaction_Coin_Wrap>
                          <Transaction_Status_Wrap>
                            <Transaction_Status_Text>
                              {el.status === 'R' && t('P2P.transaction')}
                              {el.status === 'S' && t('P2P.success')}
                              {el.status === 'C' && t('P2P.cancel')}
                            </Transaction_Status_Text>
                          </Transaction_Status_Wrap>
                        </Transaction_Content_Right>
                      </Transaction_Content_Row>
                      <Transaction_Line />
                    </Transaction_Row>
                  ))}
                </Transaction_List>
                {totalCount <= transactionList.length || (
                  <Transaction_Button onPress={() => getList(true)}>
                    <Transaction_Button_Inner_Wrap>
                      <Transaction_Button_Inner_Text>
                        {t('P2P.more')}
                      </Transaction_Button_Inner_Text>
                    </Transaction_Button_Inner_Wrap>
                  </Transaction_Button>
                )}
              </Transaction_Box>
            ) : (
              <Transaction_Box>
                <Transaction_List>
                  <Transaction_Row2>
                    <Transaction_Content_Row>
                      {['id', 'amount', 'quote', 'price'].map(el => (
                        <Transaction_Coin_Wrap2>
                          <Transaction_Coin_Text>
                            {t(`P2P.${el}`)}
                          </Transaction_Coin_Text>
                        </Transaction_Coin_Wrap2>
                      ))}
                    </Transaction_Content_Row>
                    <Transaction_Line />
                  </Transaction_Row2>

                  {transactionList2.map((el: any, key: number) => (
                    <Transaction_Row3 key={`${key}list`}>
                      <Transaction_Content_Row_Mini>
                        {[
                          t('P2P.registAt') + ' :',
                          el.registAt &&
                            `${el.registAt.slice(0, 10)} ${el.registAt.slice(
                              11,
                              16,
                            )}`,
                          t('P2P.successAt') + ' :',
                          el.endAt &&
                            `${el.endAt.slice(0, 10)} ${el.endAt.slice(
                              11,
                              16,
                            )}`,
                        ].map((e, x) =>
                          x % 2 === 0 ? (
                            <Transaction_Coin_Wrap2>
                              <Transaction_Coin_Text3>
                                {e}
                              </Transaction_Coin_Text3>
                            </Transaction_Coin_Wrap2>
                          ) : (
                            <Transaction_Coin_Wrap3>
                              <Transaction_Coin_Text3>
                                {e}
                              </Transaction_Coin_Text3>
                            </Transaction_Coin_Wrap3>
                          ),
                        )}
                      </Transaction_Content_Row_Mini>
                      <Transaction_Content_Row>
                        {[
                          `${el.email && el.email.slice(0, 2)}****`,
                          `${el.amount && regexp(el.amount)}`,
                          `${el.hopedQuote && regexp(el.hopedQuote)}`,
                          `${el.price && regexp(el.price)}`,
                        ].map(e => (
                          <Transaction_Coin_Wrap2>
                            <Transaction_Coin_Text2>{e}</Transaction_Coin_Text2>
                          </Transaction_Coin_Wrap2>
                        ))}
                      </Transaction_Content_Row>
                      {transactionList2.length !== key + 1 ||
                      totalCount2 <= transactionList2.length ? (
                        <Transaction_Line />
                      ) : (
                        <Transaction_Line_Row
                          onPress={() => getSuccessList(true)}>
                          <Spread_Button>▼</Spread_Button>
                        </Transaction_Line_Row>
                      )}
                    </Transaction_Row3>
                  ))}

                  {transactionList.map((el: any, key: number) => (
                    <Transaction_Row2 key={`${key}list`}>
                      <Transaction_Content_Row>
                        {[
                          `${el.email && el.email.slice(0, 2)}****`,
                          `${el.amount && regexp(el.amount)}`,
                          `${el.hopedQuote && regexp(el.hopedQuote)}`,
                          `${el.price && regexp(el.price)}`,
                        ].map(e => (
                          <Transaction_Coin_Wrap2>
                            <Transaction_Coin_Text>{e}</Transaction_Coin_Text>
                          </Transaction_Coin_Wrap2>
                        ))}
                      </Transaction_Content_Row>
                      <Transaction_Line />
                    </Transaction_Row2>
                  ))}
                </Transaction_List>
                {totalCount <= transactionList.length || (
                  <Transaction_Button onPress={() => getGlobalList(true)}>
                    <Transaction_Button_Inner_Wrap>
                      <Transaction_Button_Inner_Text>
                        {t('P2P.more')}
                      </Transaction_Button_Inner_Text>
                    </Transaction_Button_Inner_Wrap>
                  </Transaction_Button>
                )}
              </Transaction_Box>
            )
          ) : (
            <No_Content_Box>
              <No_Content> {t('P2P.noContents')}</No_Content>
            </No_Content_Box>
          )}
          <Button_Wrap>
            <Basic_Button
              marginTop={130}
              onPress={() => navigate('/p2p/request', { state: type })}>
              {t('P2P.request')}
            </Basic_Button>
          </Button_Wrap>
        </Main_Box>
      </Main_Wrap>
      <BottomMenu navigate={navigate} currentP={'p2p'} />
    </Container>
  );
};

const Container = styled(View)`
  flex: 1;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
`;

const Main_Wrap = styled(ScrollView)`
  width: 100%;
  padding-bottom: 20;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  flex: 1;
`;

const Main_Box = styled(View)`
  width: 100%;
`;

const No_Content_Box = styled(View)`
  height: 100;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 50;
`;

const No_Content = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  padding-left: 15;
  line-height: 15;
  color: #1e1e1e;
`;

const Top_Nav_Text = styled(Text)<Interface_All>`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.color || 'rgba(255, 255, 255, 0.5)'}; ;
`;
const Top_Nav_Tab = styled(TouchableOpacity)<{ type: string; ref?: any }>`
  justify-content: center;
  align-items: center;
  padding-block: 14px;
  flex: 1;
  cursor: pointer;
  height: 42px;
  border-radius: 30px;
`;
const Top_Nav_Frame = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-block: 4px;
  padding-inline: 4px;
  width: 100%;
  height: 50px;
  border-radius: 30px;
`;
const Top_Nav_Box = styled(View)`
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  padding-inline: 20px;
`;

const Transaction_Coin_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;
const Transaction_Coin_Text2 = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #03a519;
`;
const Transaction_Coin_Text3 = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #03a519;
`;
const Transaction_Status_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #787878;
`;
const Transaction_Coin_Wrap = styled(View)`
  margin-right: 12px;
`;
const Transaction_Coin_Wrap2 = styled(View)`
  flex: 1;
`;
const Transaction_Coin_Wrap3 = styled(View)`
  flex: 2;
`;
const Transaction_Status_Wrap = styled(View)``;
const Transaction_Content_Right = styled(View)`
  flex-direction: row;
  justify-content: flex-end;
`;
const Transaction_Content_Left = styled(View)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-block: 4px;
  /* padding-inline: 11px; */
  width: 58px;
  height: 26px;
  background: #ffffff;
  border-width: 1px;
  border-color: #e7e7e7;
  border-radius: 28px;
`;
const Transaction_Content_Left_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #8b8b8b;
`;
const Transaction_Content_Row = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Transaction_Content_Row_Mini = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;
const Transaction_Line = styled(View)`
  border-color: #e7e7e7;
  border-width: 0.8px;
  width: 100%;
`;
const Transaction_Line_Half = styled(View)`
  border-color: #e7e7e7;
  border-width: 0.8px;
  width: 45%;
`;
const Transaction_Line_Row = styled(TouchableOpacity)`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  background-color: #00000010;
`;
const Spread_Button = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #474747;
`;
const Transaction_Row = styled(TouchableOpacity)`
  width: 100%;
  margin-bottom: 20px;
`;
const Transaction_Row2 = styled(View)`
  width: 100%;
  margin-bottom: 20px;
`;
const Transaction_Row3 = styled(View)`
  width: 100%;
  margin-top: -10px;
  padding-top: 10px;
  margin-bottom: 20px;
`;
const Transaction_List = styled(View)`
  width: 100%;
`;
const Transaction_Box = styled(View)`
  width: 100%;
  background: #ffffff;
  padding-top: 30px;
  padding-bottom: 20px;
  padding-inline: 20px;
  align-items: center;
`;

const Transaction_Button = styled(TouchableOpacity)`
  margin-top: 30px;
  border: 1px solid #e7e7e7;
  border-width: 1px;
  border-color: #e7e7e7;
  border-radius: 35px;
  width: 107px;
  height: 42px;
  justify-content: center;
  align-items: center;
`;

const Transaction_Button_Inner_Wrap = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`;

const Transaction_Button_Inner_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #7e7e7e;
`;

const Button_Wrap = styled(View)`
  padding-inline: 20px;
`;

const Top_Nav_Slider = styled(View)<{ tabWidth: number; left: number }>`
  position: absolute;
  bottom: 0;
  width: ${props => props.tabWidth || 0};
  left: ${props => props.left || 0};
  height: 4px;
  border-radius: 4px;
  background: #00ccfd;
  transition: left 0.3s ease;
`;

export default Transaction;
