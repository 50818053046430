const locale = {
  controller: {
    plzEnterAllSection: '모든 항목들을 입력해주세요',
    plzMatchFormat: '양식을 맞춰주세요',
    success: '완료하였습니다.',
  },

  main: {
    deposit: '입금',
    withdraw: '출금',
    myWalletList: '내 지갑 목록',
    onReady: '준비중',
  },

  withdraw: {
    cancel: '취소',
    withdrawal: '출금',
    OTPforWithdraw: '출금을 위해 OTP 등록이 필요합니다.',
    registOTP: 'OTP 등록',
    PwdforWithdraw: '출금을 위해 송금 비밀번호 설정이 필요합니다.',
    registPwd: '송금 비밀번호 설정',
    available: '출금 가능액',
    address: '주소',
    plzEnterAddress: '주소를 입력하세요',
    QRcode: 'QR코드',
    amount: '수량',
    plzEnterAmount: '수량을 입력하세요',
    sendSpeed: '전송속도',
    fast: '빠름',
    average: '보통',
    slow: '느림',
    plzEnterOTP: 'OTP를 입력하세요',
    plzEnterTransferPwd: '송금비밀번호를 입력하세요',
    withdraw: '출금 하기',
    confirmWithdraw: '출금 확인',
    withdrawDescription: `출금처리 되었습니다. ${'\n'}출금 완료는 네트워크 상황에따라 늦어질 수 있습니다`,
    confirmTransaction: '거래 확인',
    findAddressInput: '아이디를 정확히 입력해주세요',
    findAddressFindBtn: '찾기',
    findAddressId: '아이디',
    findAddressName: '이름',
    findAddressAddress: '주소',
    findAddressNothing: '검색결과가 없습니다.',
    findAddressConfirm: '확인',
    findAddressFindById: '아이디로 주소찾기',
    outOfGas: '가스비가 부족합니다. 보유하신 주소 (',
    outOfGas2: ') 으로 BNB를 전송하세요.',
  },

  transactionHistory: {
    history: '입출금 내역',
    noHistory: '입출금 내역이 없습니다',
    viewMore: '더보기',
    withdraw: '출금',
    deposit: '입금',
    checkTransaction: '거래 확인',
    receive: '받기',
    send: '보내기',
    confirm: '확인',
  },

  deposit: {
    toastMessage: '주소를 복사했습니다.',
    deposit: '입금',
    plzCheckAddress: '입금시 메인넷(Binace Smart Chain)과 주소를 확인하세요.',
    depositAddress: '입금 주소',
    address: '주소',
    copy: '복사',
    QRcode: 'QR 코드',
  },

  signupInfo: {
    signupInfo: '가입정보',
    email: '이메일',
    ID: 'ID',
    name: '이름',
    referal: '추천인',
    currentPwd: '현재 비밀번호',
    newPwd: '새 비밀번호',
    plzEnterNewPwd: '새 비밀번호를 입력하세요',
    pwdCondition: '*8~16자의 영문 대소문자, 숫자가 들어가야합니다.',
    checkNewPwd: '새 비밀번호 확인',
    plzEnterNewPwdAgain: '새 비밀번호를 다시 한번 입력하세요',
    newPwdCondition: '*비밀번호가 맞지 않습니다.',
    changeSuccess: '변경 완료',
    change: '변경',
  },

  signup: {
    signup: '회원가입',
    email: '이메일',
    id: 'ID',
    IDCondition: '*4~30자의 영문 소문자, 숫자만 가능합니다.',
    authenticate: '인증하기',
    emailCondition: '*올바른 이메일 형식이 아닙니다.',
    successAuthenticate: '*인증 되었습니다.',
    plzEnterAuthenticateCode: '인증번호를 입력하세요',
    checkAuthentication: '인증 확인',
    resendAuthenticateCode: '인증번호 재발송',
    pwd: '비밀번호',
    plzEnterPwd: '비밀번호를 입력하세요',
    pwdCondition: '*8~16자의 영문 대소문자, 숫자가 들어가야합니다.',
    checkPwd: '비밀번호 확인',
    plzEnterPwdAgain: '비밀번호를 다시 한번 입력하세요',
    pwdNotMatched: '*비밀번호가 맞지 않습니다.',
    name: '이름',
    plzEnterName: '이름을 입력하세요',
    nameCondition: '*한글, 영문 대소문자만 가능합니다.(특수기호, 공백 불가)',
    referal: '추천인',
    plzEnterReferal: '추천인이 있다면 추천인을 입력하세요',
    notUsedID: '*존재하지 않는 아이디 입니다.',
    tel: '전화번호',
    plzEnterTel: '전화번호를 입력하세요',
    plzEntertel2: '전화번호를 입력하세요',
  },

  settings: {
    logout: '로그아웃',
    logoutQuestion: '로그아웃 하시겠습니까?',
    cancel: '취소',
    confirm: '확인',
    settings: '설정',
    signupInfo: '가입정보',
    OTPSettings: 'OTP 설정',
    visitSite: '사이트 방문',
    contract: 'BAGO 구매 계약서',
    sign: '서명하기',
    successSign: '서명되었습니다.',
    bank: '은행계좌 등록',
    transferPwd: '송금 비밀번호 설정',
  },

  login: {
    email: '이메일',
    pwd: '비밀번호',
    saveEmail: '이메일 저장하기',
    login: '로그인',
    findPwd: '비밀번호 찾기',
    doNotHaveAcount: '계정이 없으신가요?',
    signup: '회원가입',
    ifBlank: '아이디, 비밀번호를 입력해주세요',
    saveId: '아이디 저장',
    autoLogin: '자동 로그인',
  },

  findPwd: {
    getIssuedTemporaryPwd: '임시 비밀번호 발급',
    temporaryPwdDescription: `임시 비밀번호를 발급합니다. ${'\n'}임시로 발급된 비밀번호는 1시간동안 유지됩니다.`,
    email: '이메일',
  },

  googleOTP: {
    setOTP: '구글 OTP 설정',
    description1: 'Google OTP 인증 앱을 설치합니다.',
    description2: 'QR코드를 스캔 또는 아래 키를 입력합니다.',
    description3: 'QR 코드 스캔이 불가능한 경우 아래키 입력',
    plzEnterCode: '코드를 입력해 주세요',
    copy: '복사',
    enterOTPCode: '구글 OTP 코드를 입력합니다.',
    OTPcode: 'OTP 코드',
    plzEnterOTPCode: 'OTP 코드를 입력하세요',
    set: '설정하기',
    cancelOTP1: 'OTP 등록을 취소합니다.',
    cancelOTP2: 'OTP 등록 취소',
  },

  staking1: {
    stakingAmount: '스테이킹 수량',
    miningAmount: '실시간 수익',
    available: '인출가능',
    plzEnterWithdrawal: '인출할 금액을 입력하세요.',
    withdrawal: '인출',
    startStaking: '스테이킹 시작하기',
    inducementQuantity: 'GB',
    withdrew: '인출하였습니다.',
    failedAmount: '올바른 코인양을 입력해주세요.',
  },

  staking2: {
    available: '스테이킹 가능 수량',
    autoSwap: 'BAGO는 스테이킹시 BATY로 자동으로 스왑됩니다.',
    swapRatio: '*스왑 비율',
    plzEnterPrice: '금액을 입력하세요',
    amount: '수량',
    period: '기간',
    interestRate: '이율',
    date: '일',
    month: '월',
    staking: '스테이킹',
    failedAmount: '올바른 코인양을 입력해주세요.',
    coinLow: '사용가능한 코인 양보다 많습니다.',
    startStaking: '스테이킹을 시작합니다.',
  },

  staking3: {
    history: 'Staking 내역',
    myAmount: '나의 BATY 잔고',
    stakingInterest: '스테이킹 수익',
    stakingInterestReferal: '스테이킹 수익',
    stakingStart: '스테이킹 시작',
    stakingChange: '스테이킹 전환',
    withdrawalChange: '인출 전환',
    withdrawal: '출금',
    stakingFinish: '스테이킹 만료',
    more: '더보기',
    noHistory: '스테이킹이나 인출 내역이 없습니다.',
  },

  P2P: {
    all: '내 거래',
    buy: '삽니다',
    sell: '팝니다',
    success: '거래완료',
    transaction: '거래중',
    cancel: '거래취소',
    more: '더보기',
    request: '거래요청',
    noContents: '거래가 없습니다.',
    id: '아이디',
    amount: '판매량',
    quote: '판매단가',
    price: '총 가격',
    registAt: '등록일',
    successAt: '성사일',
  },

  requestP2P: {
    requestP2P: 'P2P 거래 요청',
    coinPrice: '코인 시세',
    P2PTransaction: 'P2P 거래',
    buy: '삽니다',
    sell: '팝니다',
    available: '최대 판매 가능',
    tel: '연락처',
    price: '거래금액',
    fee: '수수료',
    won: '￦',
    feeGuide: '* 수수료는 안전거래를 지원하기 위한 비용입니다.',
    requestTransaction: '거래요청',
    selectCoin: '코인 선택',
    transactionAmount: '거래수량 & 단위당 희망 가격',
    plzEnterTranasactionAmount: '수량을 입력하세요',
    bank: '은행',
    selectBank: '은행 선택',
    account: '계좌번호',
    plzEnterAccount: '계좌번호를 입력하세요',
    plzEnterBank: '은행을 입력하세요',
    plzEnterOwner: '예금주를 입력하세요',
    plzEnterHopedPrice: '단위당 희망 가격을 입력해주세요',
    owner: '예금주',
    nameWarn: '* 본인명의가 아닐 경우 거래가 거부될 수 있습니다.',
    success:
      '등록했습니다. 3일동안 거래성사가 없을 시 자동으로 취소처리됩니다.',
    failedAmount: '올바른 코인양을 입력해주세요.',
    coinLow: '사용가능한 코인 양보다 많습니다.',
    bankChange: '계좌정보변경',
  },

  viewP2P: {
    viewP2P: 'P2P 거래 보기',
    buy: '삽니다',
    sell: '팝니다',
    price: '거래대금',
    fee: '수수료',
    requestAt: '요청일시',
    status: '거래 상태',
    success: '거래완료',
    transaction: '거래중',
    cancel: '거래취소',
    won: '￦',
    cancelTransaction: '거래 취소',
  },

  bankInfo: {
    save: '저장하기',
    check: '입력한 내용이 맞습니까?',
  },

  transferPwd: {
    signupInfo: '송금 비밀번호 설정',
    currentPwd: '비밀번호',
    newPwd: '송금 비밀번호',
    plzEnterNewPwd: '송금 비밀번호를 입력하세요',
    pwdCondition: '*6자의 숫자가 들어가야합니다.',
    checkNewPwd: '송금 비밀번호 확인',
    plzEnterNewPwdAgain: '송금 비밀번호를 다시 한번 입력하세요',
    newPwdCondition: '*비밀번호가 맞지 않습니다.',
    changeSuccess: '변경 완료',
    change: '변경',
  },
};

export default locale;
