export default class Auth {
  public accessToken;
  public checkSum;
  public userInfo;

  private static _instance: any;

  private constructor() {
    this.accessToken = '';
    this.checkSum = '';
    this.userInfo = '';
  }

  public static getInstance() {
    if (this._instance == null) {
      this._instance = new Auth();
    }

    return this._instance;
  }

  public checkSession() {
    let _accessToken: any = sessionStorage.accessToken;
    let _checkSum: any = sessionStorage.checkSum;
    if (_accessToken == null) {
      _accessToken = '';
    }

    if (_checkSum == null) {
      _checkSum = '';
    }

    this.accessToken = _accessToken;
    this.checkSum = _checkSum;

    if (this.accessToken.length > 1) {
      return true;
    }

    return false;
  }

  public getHeaderAuth() {
    return {accesstoken: this.accessToken, checksum: this.checkSum};
  }
}
