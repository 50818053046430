import React, { useState, useEffect } from 'react';
import { View, Dimensions, Image, Text, ToastAndroid } from 'react-native';
import styled from 'styled-components/native';
import TopNav from '@components/topNav/TopNav';
import {
  Label_TextInput,
  TextInput_Button,
} from '@components/textInput/TextInput';
import { Basic_Button } from '@components/button/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import SignController from '@controller/Sign/SignController';
import { QRCodeCanvas } from 'qrcode.react';
import useClipboard from 'react-use-clipboard';
const Coin_symbol_ETH_61 = require('@assets/img/coin_symbol_ETH_61.png');
const Coin_symbol_BAGO61 = require('@assets/img/coin_symbol_BAGO_61.png');
const transaction_warning = require('@assets/img/transaction_warning.png');
import { useTranslation } from 'react-i18next';

interface MainProps {
  navigation: any;
}
const Deposit = () => {
  const { t } = useTranslation('locale');
  const navigate = useNavigate();
  const coin = useLocation();

  const [address, setAddress] = useState({});
  const [balance, setBalance] = useState(0);
  const [, setCopied] = useClipboard(address.address);

  const getAddress = () => SignController.address(setAddress);

  const coinBalance = (_coin: string) =>
    SignController.coinBalanceOnly(_coin, setBalance);

  useEffect(() => {
    getAddress();
    coinBalance(coin.state);
  }, []);

  const [balanceList, setBalanceList] = useState({});
  const [priceList, setPriceList] = useState({});
  const balanceFunc = () => SignController.balance(setBalanceList);
  const price = () => SignController.price(setPriceList);

  const regexp = (num: number) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const regexpWon = (num: number) => {
    num = Math.floor(num);
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const bogoxTrans = () => {
    if (coin.state === 'BAGO' || coin.state === 'BAGOX') {
      return 'BAGO';
    } else {
      return coin.state;
    }
  };
  const copyToClipboard = () => {
    setCopied();
    // ToastAndroid.show(t('deposit.toastMessage'), ToastAndroid.SHORT);
  };
  useEffect(() => {
    balanceFunc();
    price();
  }, []);
  return (
    <Container>
      <TopNav navigate={navigate}>{t('deposit.deposit')}</TopNav>
      <Main_Wrap>
        <Token_Wrap>
          {coin.state === 'BNB' && <img src={Coin_symbol_ETH_61} alt="" />}
          {(coin.state === 'BAGO' ||
            coin.state === 'BAGOX' ||
            coin.state === 'BATY') && <img src={Coin_symbol_BAGO61} alt="" />}
          <Token_Balance>
            {balanceList[coin.state] && regexp(balanceList[coin.state])}{' '}
            {coin.state}
          </Token_Balance>
          <Token_Balance_Won>
            ₩{' '}
            {balanceList[coin.state] &&
              priceList[bogoxTrans()] &&
              regexpWon(balanceList[coin.state] * priceList[bogoxTrans()])}
          </Token_Balance_Won>
        </Token_Wrap>
        <Main_Box>
          <Main_Bottom>
            <Main_Bottom_Box>
              <Label_TextInput
                title={t('deposit.depositAddress')}
                fontColor="#000000"
                setValue={false}
                width="70%"
                marginBottom={15}
                value={address.address}
                placeholder={t('deposit.address')}>
                <TextInput_Button
                  title={t('deposit.copy')}
                  width={66}
                  onPress={copyToClipboard}
                  backgroundColor="#fafafa"
                  borderColor="#ededed"
                />
              </Label_TextInput>
              <Warn_Row>
                <img src={transaction_warning} alt="" />
                <Warn_Message>{t('deposit.plzCheckAddress')}</Warn_Message>
              </Warn_Row>
              <Content_Wrap>
                <Content_Title>{t('deposit.QRcode')}</Content_Title>
                <QR_Code_Wrap>
                  <QR_Code_Box>
                    <QRCodeCanvas size={157} value={address.address} />
                  </QR_Code_Box>
                </QR_Code_Wrap>
              </Content_Wrap>
            </Main_Bottom_Box>
          </Main_Bottom>
        </Main_Box>
      </Main_Wrap>
    </Container>
  );
};

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;
const Container = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #efefef;
`;

const Main_Wrap = styled(View)`
  width: 100%;
  flex: 1;
`;

const Main_Box = styled(View)`
  width: 100%;
`;

const Token_Wrap = styled(View)`
  width: 100%;
  padding-top: 26;
  padding-bottom: 26;
  align-items: center;
`;
const Token_Balance = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 600;
  line-height: 30;
  font-size: 27;
  color: #000000;
  margin-top: 17;
  margin-bottom: 12;
`;
const Token_Balance_Won = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 17;
  line-height: 20;
  color: #979797;
`;

const Warn_Row = styled(View)`
  flex-direction: row;
  align-items: center;
  margin-top: 15;
`;

const Warn_Message = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 13;
  line-height: 19;
  margin-left: 7;
  color: #979797;
`;
const Main_Bottom = styled(View)`
  background: #ffffff;
  width: 100%;
  flex: 1;
  min-height: ${600};
  padding-top: 25;
  padding-left: 20;
  padding-right: 20;
`;

const Main_Bottom_Box = styled(View)`
  width: 100%;
`;

const Content_Title = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 20;
  color: #000000;
`;

const Content_Wrap = styled(View)`
  margin-top: 30;
  width: 100%;
`;

const QR_Code_Wrap = styled(View)`
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 25;
`;
const QR_Code_Box = styled(View)`
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  padding-left: 7;
  padding-top: 7;
  padding-bottom: 7;
  padding-right: 7;
  border-width: 1;
  border-color: #ededed;
  border-radius: 5;
`;

const Coin_Box = styled(View)`
  width: 100%;
  height: 93;
  align-items: flex-start;
  padding-left: 20;
  justify-content: space-between;
  margin-top: 35;
`;

const Coin_Symbol_Container = styled(View)`
  width: 100%;
  height: 30;
`;

const Coin_Symbol = styled(View)`
  width: 30;
  height: 30;
  justify-content: center;
  align-items: center;
  border-radius: 100;
`;

const Coin_Balance_Container = styled(View)`
  flex-direction: row;
  align-items: flex-end;
  height: 48;
`;
const Coin_Balance = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 46;
  line-height: 46;
  color: #ffffff;
`;
const Coin_Symbol_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 15;
  line-height: 15;
  color: rgba(255, 255, 255, 0.6);
  margin-left: 10;
`;

export default Deposit;
