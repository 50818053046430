import React, { useEffect, useRef, useState } from 'react';
import { View, Modal, Text } from 'react-native';
import styled from 'styled-components/native';
import { Interface_All } from '@interface/Interface_All';
import { isBrowser } from 'react-device-detect';
import Sign from '@model/user/Sign';
import { Pressable } from 'react-native';

interface Pop_UP_Modal_Interface {
  visable: boolean;
  setVisable: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Pop_UP_Modal = ({
  visable,
  setVisable,
}: Pop_UP_Modal_Interface) => {
  const [popUpList, setPopUpList] = useState([]);
  const [page, setPage] = useState(0);
  const [length, setLength] = useState(0);
  const contentSize = useRef(null);

  const popUpNotice = async () => {
    let res = await Sign.popupNotice();
    setLength(res.length - 1);
    setPopUpList(res);
    if (res.length !== 0) {
      setVisable(true);
    }
  };

  const closePopup = (expireDays: any) => {
    let expire = new Date();
    expire.setTime(expire.getTime() + expireDays * 24 * 60 * 60 * 1000);
    localStorage.setItem('popupNoShow', expire.getTime());
  };

  const checkPopupClose = () => {
    const expireDay = localStorage.getItem('popupNoShow');
    let today = new Date();

    if (today.getTime() > expireDay) {
      return false;
    } else {
      return true;
    }
  };

  const closePopupToday = () => {
    closePopup(1); // 하루 동안 표시 안 할 것임을 명시
    setVisable(false); // 오늘 하루 안 보기 누름과 동시에 팝업 사라짐
  };

  useEffect(() => {
    checkPopupClose() ? setVisable(false) : popUpNotice();
  }, []);

  return (
    <Pop_UP_Modal_Wrap
      animationType="fade"
      transparent={true}
      visible={visable}>
      <Pop_UP_Modal_Wrap2>
        <Pop_UP_Modal_Wrap4>
          {page !== 0 && (
            <Past_Page onPress={() => setPage(page - 1)}>
              <Next_Text>〈</Next_Text>
            </Past_Page>
          )}
          {page !== length && (
            <Next_Page onPress={() => setPage(page + 1)}>
              <Next_Text>〉</Next_Text>
            </Next_Page>
          )}
          <Pop_UP_Modal_Wrap3 ref={contentSize}>
            <Carousel
              marginLeft={`-${
                contentSize.current && contentSize.current.offsetWidth * page
              }px`}>
              {popUpList.map(el => {
                return (
                  <div
                    style={{
                      width:
                        contentSize.current &&
                        contentSize.current.offsetWidth - 40,
                      padding: '20px',
                      backgroundPosition: '50% 50%',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      flex: 'none',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: el && el.content,
                    }}></div>
                );
              })}
            </Carousel>
          </Pop_UP_Modal_Wrap3>
          <Page_Line>
            {popUpList.map((_, num) => {
              if (num === page) {
                return (
                  <Dot_Container disabled>
                    <Dot>●</Dot>
                  </Dot_Container>
                );
              } else {
                return (
                  <Dot_Container onPress={() => setPage(num)}>
                    <Dot>○</Dot>
                  </Dot_Container>
                );
              }
            })}
          </Page_Line>
          <Button_Con>
            <Close_Button onPress={closePopupToday}>
              <Button_Text>24시간 안 보기</Button_Text>
            </Close_Button>
            <Middle_Line />
            <Close_Button onPress={() => setVisable(false)}>
              <Button_Text>닫기</Button_Text>
            </Close_Button>
          </Button_Con>
        </Pop_UP_Modal_Wrap4>
      </Pop_UP_Modal_Wrap2>
    </Pop_UP_Modal_Wrap>
  );
};

const Pop_UP_Modal_Wrap = styled(Modal)<Interface_All>``;
const Pop_UP_Modal_Wrap2 = styled(View)<Interface_All>`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #00000050;
`;

const Pop_UP_Modal_Wrap4 = styled(View)<Interface_All>`
  width: ${_ => (isBrowser ? 355 : '95%')};
  margin: 20px;
  height: 80%;
  overflow: hidden;
  border-radius: 15px;
`;

const Pop_UP_Modal_Wrap3 = styled(View)<Interface_All>`
  width: ${_ => (isBrowser ? 355 : '100%')};
  background-color: #ffffff;
  height: 86%;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Carousel = styled(View)<Interface_All>`
  flex-direction: row;
  transition: 1s;
  margin-left: ${props => props.marginLeft};
`;

const Button_Con = styled(View)`
  background-color: black;
  height: 10%;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Close_Button = styled(Pressable)`
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const Button_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: white;
`;

const Middle_Line = styled(View)`
  background-color: #ffffff50;
  width: 1px;
  height: 65%;
`;

const Dot_Container = styled(Pressable)``;

const Dot = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #6e6e6e90;
  margin-inline: 5px;
`;

const Page_Line = styled(View)`
  flex-direction: row;
  background-color: #ffffff;
  justify-content: center;
  padding-bottom: 5px;
  height: 4%;
`;

const Past_Page = styled(Pressable)`
  z-index: 10;
  position: absolute;
  top: 40%;
  left: 5px;
`;

const Next_Page = styled(Pressable)`
  z-index: 10;
  position: absolute;
  top: 40%;
  right: 5px;
`;

const Next_Text = styled(Text)`
  font-size: 100px;
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  line-height: 100px;
  color: #69696950;
`;
/* <Pop_UP_Modal_Text>{`공지사항

바고입니다.

2023년 5월부터 락업해제가 진행중입니다.
락업의 해제는 아래의 내용으로 진행이 됩니다.

1. 구매일로부터 120일 이후 해제
2. 최초 30일은 10%를 매일 분할 해제
3. 다음 30일은 15%를 매일 분할 해제
4. 2번 3번을 반복하여 락업 해제 진행
5. 락업에 해제된 물량은 거래소 판매 가능

추가로 복잡한 거래소 판매보다 쉽게 거래하기 위해
P2P 개인거래 부분 오픈을 준비중입니다.
P2P 거래는 6월 3주차 오픈 예정으로 복잡한 절차없이
개인간 거래를 지원합니다.
원래 6월 1주차 오픈을 목표로 하였으나,
메인거래처와의 협의 과정에서 6월 3주로 오픈이 연기되어 
많이 기다린 사용자 분들께 사과의 말씀 드립니다.

계속해서 성장하는 바고가 되겠습니다.
감사합니다.`}</Pop_UP_Modal_Text> */
