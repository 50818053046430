import React, { useState, useEffect } from 'react';
import {
  View,
  Dimensions,
  Text,
  Modal,
  TouchableOpacity,
  ActivityIndicator,
  StyleSheet,
  Platform,
  PermissionsAndroid,
} from 'react-native';
import styled from 'styled-components/native';
import TopNav from '@components/topNav/TopNav';
import {
  Closable_TextInput,
  Label_TextInput,
  TextInput_Button,
  Visiable_TextInput_Login,
} from '@components/textInput/TextInput';
import { Basic_Button } from '@components/button/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import SignController from '@controller/Sign/SignController';
import OTPController from '@controller/OTP/OTPController';
import QrReader from 'react-web-qr-reader';
import { useCameraPermissions } from 'expo-camera';
import * as Permissions from 'expo-permissions';


import TransactionController from '@controller/Transaction/TransactionController';
const Coin_symbol_ETH_61 = require('@assets/img/coin_symbol_ETH_61.png');
const Coin_symbol_BAGO61 = require('@assets/img/coin_symbol_BAGO_61.png');
const transaction_check = require('@assets/img/transaction_check.png');
import { useTranslation } from 'react-i18next';
import { isBrowser } from 'react-device-detect';
import { Interface_All } from '@interface/Interface_All';
const Withdraw = () => {
  const { t } = useTranslation('locale');
  const navigate = useNavigate();
  const coin = useLocation();
  const findAddressProto = {
    id: '',
    name: '',
    address: '',
  };
  const [passwordVisible, setPasswordVisible] = useState(true);
  const [addressModal, setAddressModal] = useState(false);
  const [findAddressText, setFindAddressText] = useState('');
  const [balance, setBalance] = useState(0);
  const [available, setBalanceAvailable] = useState(0);
  const [qrModal, setQrModal] = useState(false);
  const [address, setAddress] = useState('');
  const [amount, setAmount] = useState('');
  const [code, setCode] = useState('');
  const [checked, setChecked] = React.useState('third');
  const [OTPPossession, setOTPPossession] = useState(false);
  const [success, setSuccess] = useState(false);
  const [result, setResult] = useState('No result');
  const [txHash, setTxHash] = useState('');
  const [gasPrice, setGasPrice] = useState(10);
  const [isFind, setIsFind] = useState(false);
  const gasLimit = 72000;
  const [findAddressResult, setFindAddressResult] = useState(findAddressProto);
  const [loading, setLoading] = useState(false);
  const [gasOption, setGasOption]: any = useState({
    fast: 10,
    averge: 5,
    slow: 5,
  });
  const [myAddress, setMyAddress] = useState({ address: '' });
  const getAddress = () => SignController.address(setMyAddress);

  const [hasPermission, setHasPermission] = useState(false);
  const [scanned, setScanned] = useState(false);

  const checkOTP = () => OTPController.checkOTPonly(setOTPPossession);
  // const coin = {state: 'BNB'};
  const coinBalance = () =>
    SignController.coinBalanceOnly(coin.state, setBalance);
  const coinBalanceAvailable = () =>
    SignController.coinBalanceAvailable(coin.state, setBalanceAvailable);

  const coinTransfer = () =>
    TransactionController.transfer(
      coin,
      address,
      amount,
      gasPrice,
      gasLimit,
      code,
      setSuccess,
      setTxHash,
      coinBalance,
      setLoading,
      t,
      myAddress.address,
    );

  const handleScan = result => {
    if (result) {
      setResult(result);
      setAddress(result.data);
      setQrModal(false);
    }
  };
  const handleError = error => {
    // alert(error);
    console.log(error);
  };

  const setGas = (speed: string) => {
    if (speed === 'fast') {
      setChecked('first');
      setGasPrice(gasOption[speed]);
    }
    if (speed === 'averge') {
      setChecked('second');
      setGasPrice(gasOption[speed]);
    }
    if (speed === 'slow') {
      setChecked('third');
      setGasPrice(gasOption[speed]);
    }
  };
  useEffect(() => {
    checkOTP();
    coinBalance();
    coinBalanceAvailable();
  }, []);

  const [balanceList, setBalanceList] = useState({});
  const [priceList, setPriceList] = useState({});
  const balanceFunc = () => SignController.balance(setBalanceList);
  const price = () => SignController.price(setPriceList);

  const regexp = (num: number) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const regexpWon = (num: number) => {
    num = Math.floor(num);
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const bogoxTrans = () => {
    if (coin.state === 'BAGO' || coin.state === 'BAGOX') {
      return 'BAGO';
    } else {
      return coin.state;
    }
  };

  const closeFindModal = () => {
    setAddressModal(false);
    setIsFind(false);
    setFindAddressResult(findAddressProto);
    setAddress(findAddressResult.address);
  };

  const findAddress = async () =>
    SignController.findAddress(
      findAddressText,
      setIsFind,
      setFindAddressResult,
      findAddressProto,
    );

  useEffect(() => {
    balanceFunc();
    price();
    getAddress();
  }, []);


  const [cameraPermission, askForCameraPermission] = useCameraPermissions();
  const [hasCameraPermission, setHasCameraPermission] = useState(false);
  

  useEffect(() => {
    Permissions.askAsync(Permissions.CAMERA).then(({ status }) =>
      console.log(status)
        // alert(status)
        //setHasCameraPermissions(status === 'granted'),
    );
}, []);

  return (
    <Container>
      <QR_Scan_Modal animationType="fade" transparent={true} visible={qrModal}>
        <QR_Wrap>
          <QrReader
            delay={1000}
            onError={handleError}
            style={{ height: 320, width: 320 }}
            onScan={handleScan}
            facingMode="environment"
            showViewFinder={false}
          />
          <Basic_Button_Wrap>
            <Basic_Button onPress={() => setQrModal(false)}>
              {t('withdraw.cancel')}
            </Basic_Button>
          </Basic_Button_Wrap>
        </QR_Wrap>
      </QR_Scan_Modal>
      <Find_Address_Modal
        animationType="fade"
        transparent={true}
        visible={addressModal}>
        <Find_Address_Wrap>
          <Find_Address_Box>
            <Find_Address_Input_Row>
              <Find_Address_Input_Box>
                <Closable_TextInput
                  placeholder={t('withdraw.findAddressInput')}
                  value={findAddressText}
                  setValue={setFindAddressText}
                />
              </Find_Address_Input_Box>
              <Find_Address_Button>
                <Find_Address_Button_Text onPress={findAddress}>
                  {t('withdraw.findAddressFindBtn')}
                </Find_Address_Button_Text>
              </Find_Address_Button>
            </Find_Address_Input_Row>
            <Find_Address_Table>
              {findAddressResult.id ? (
                <Find_Address_Table_Top_Row>
                  <Find_Address_Table_Top_Cell>
                    <Find_Address_Table_Top_Cell_Text>
                      {t('withdraw.findAddressId')}
                    </Find_Address_Table_Top_Cell_Text>
                  </Find_Address_Table_Top_Cell>
                  <Find_Address_Table_Top_Cell marginBottom={10}>
                    <Find_Address_Table_Top_Cell_Text>
                      {findAddressResult.id}
                    </Find_Address_Table_Top_Cell_Text>
                  </Find_Address_Table_Top_Cell>

                  <Find_Address_Table_Top_Cell>
                    <Find_Address_Table_Top_Cell_Text>
                      {t('withdraw.findAddressName')}
                    </Find_Address_Table_Top_Cell_Text>
                  </Find_Address_Table_Top_Cell>
                  <Find_Address_Table_Top_Cell marginBottom={10}>
                    <Find_Address_Table_Top_Cell_Text>
                      {findAddressResult.name}
                    </Find_Address_Table_Top_Cell_Text>
                  </Find_Address_Table_Top_Cell>

                  <Find_Address_Table_Top_Cell>
                    <Find_Address_Table_Top_Cell_Text>
                      {t('withdraw.findAddressAddress')}
                    </Find_Address_Table_Top_Cell_Text>
                  </Find_Address_Table_Top_Cell>
                  <Find_Address_Table_Top_Cell>
                    <Find_Address_Table_Top_Cell_Text>
                      {findAddressResult.address}
                    </Find_Address_Table_Top_Cell_Text>
                  </Find_Address_Table_Top_Cell>
                </Find_Address_Table_Top_Row>
              ) : isFind ? (
                <Find_Address_No_Wrap>
                  <Find_Address_No_Wrap_Text>
                    {t('withdraw.findAddressNothing')}
                  </Find_Address_No_Wrap_Text>
                </Find_Address_No_Wrap>
              ) : (
                ''
              )}
            </Find_Address_Table>
          </Find_Address_Box>
          <Basic_Button_Wrap>
            <Basic_Button onPress={closeFindModal}>
              {findAddressResult.address
                ? t('withdraw.findAddressConfirm')
                : t('withdraw.cancel')}
            </Basic_Button>
          </Basic_Button_Wrap>
        </Find_Address_Wrap>
      </Find_Address_Modal>
      <TopNav navigate={navigate}>{t('withdraw.withdrawal')}</TopNav>
      <Main_Wrap>
        <Token_Wrap>
          {coin.state === 'BNB' && <img src={Coin_symbol_ETH_61} alt="" />}
          {(coin.state === 'BAGO' ||
            coin.state === 'BAGOX' ||
            coin.state === 'BATY') && <img src={Coin_symbol_BAGO61} alt="" />}
          <Token_Balance>
            {balanceList[coin.state] && regexp(balanceList[coin.state])}{' '}
            {coin.state}
          </Token_Balance>
          <Token_Balance_Won>
            ₩{' '}
            {balanceList[coin.state] &&
              priceList[bogoxTrans()] &&
              regexpWon(balanceList[coin.state] * priceList[bogoxTrans()])}
          </Token_Balance_Won>
        </Token_Wrap>
        <Main_Box>
          <Main_Bottom>
            <Main_Bottom_Box>
              {!OTPPossession ? (
                <>
                  <Request_Container>
                    <Request_Text>{t('withdraw.PwdforWithdraw')}</Request_Text>
                  </Request_Container>
                  <Basic_Button
                    marginBottom={25}
                    marginTop={45}
                    onPress={() => navigate('/transferPwd')}>
                    {t('withdraw.registPwd')}
                  </Basic_Button>
                </>
              ) : !success ? (
                <>
                  <Possable_Withdraw_Wrap>
                    <Possable_Withdraw_Wrap_Label>
                      {t('withdraw.available')}
                    </Possable_Withdraw_Wrap_Label>
                    <Possable_Withdraw_Wrap_balance_Wrap>
                      <Possable_Withdraw_Wrap_balance>
                        {available}
                      </Possable_Withdraw_Wrap_balance>
                      <Possable_Withdraw_Wrap_balance_Symbol>
                        {' '}
                        {coin.state}
                      </Possable_Withdraw_Wrap_balance_Symbol>
                    </Possable_Withdraw_Wrap_balance_Wrap>
                  </Possable_Withdraw_Wrap>
                  <Input_Area>
                    <Label_TextInput
                      title={t('withdraw.address')}
                      fontColor="#000000"
                      setValue={setAddress}
                      width="68%"
                      value={address}
                      placeholder={t('withdraw.plzEnterAddress')}>
                      <TextInput_Button
                        title={t('withdraw.QRcode')}
                        width={86}
                        onPress={() => setQrModal(true)}
                        backgroundColor="#fafafa"
                        borderColor="#ededed"
                      />
                    </Label_TextInput>
                    <FindAddress_Row>
                      <FindAddress_Button onPress={() => setAddressModal(true)}>
                        <FindAddress_Text>
                          {t('withdraw.findAddressFindById')}
                        </FindAddress_Text>
                      </FindAddress_Button>
                    </FindAddress_Row>
                    <Label_TextInput
                      title={t('withdraw.amount')}
                      width="100%"
                      value={amount}
                      setValue={setAmount}
                      placeholder={t('withdraw.plzEnterAmount')}
                      marginTop={30}
                    />
                    <Send_Speed_Wrap>
                      <Send_Speed_Label>
                        {t('withdraw.sendSpeed')}
                      </Send_Speed_Label>
                      <Send_Speed_Radio_Wrap>
                        <Send_Speed_Radio_Button onPress={() => setGas('fast')}>
                          {/* <RadioButton
                        value="first"
                        color="orange"
                        onPress={() => setChecked('first')}
                        status={checked === 'first' ? 'checked' : 'unchecked'}
                      /> */}
                          <input
                            type="radio"
                            id="radio"
                            name="radio"
                            checked={checked === 'first'}
                            onChange={() => {
                              setGas('fast');
                            }}
                          />
                          <Send_Speed_Radio_Label>
                            {t('withdraw.fast')}
                          </Send_Speed_Radio_Label>
                        </Send_Speed_Radio_Button>
                        <Send_Speed_Radio_Button
                          onPress={() => setGas('averge')}>
                          {/* <RadioButton
                        value="second"
                        color="orange"
                        onPress={() => setChecked('second')}
                        status={checked === 'second' ? 'checked' : 'unchecked'}
                      /> */}
                          <input
                            type="radio"
                            id="radio"
                            name="radio"
                            checked={checked === 'second'}
                            onChange={() => {
                              setGas('averge');
                            }}
                          />
                          <Send_Speed_Radio_Label>
                            {t('withdraw.average')}
                          </Send_Speed_Radio_Label>
                        </Send_Speed_Radio_Button>
                        <Send_Speed_Radio_Button onPress={() => setGas('slow')}>
                          {/* <RadioButton
                        value="third"
                        color="orange"
                        onPress={() => setChecked('third')}
                        status={checked === 'third' ? 'checked' : 'unchecked'}
                      /> */}
                          <input
                            type="radio"
                            id="radio"
                            name="radio"
                            checked={checked === 'third'}
                            onChange={() => {
                              setGas('slow');
                            }}
                          />
                          <Send_Speed_Radio_Label>
                            {t('withdraw.slow')}
                          </Send_Speed_Radio_Label>
                        </Send_Speed_Radio_Button>
                      </Send_Speed_Radio_Wrap>
                    </Send_Speed_Wrap>
                    <Visiable_TextInput_Login
                      visible={passwordVisible}
                      setVisible={setPasswordVisible}
                      placeholder={t('withdraw.plzEnterTransferPwd')}
                      value={code}
                      setValue={setCode}
                      marginTop={30}
                    />
                  </Input_Area>
                  <Basic_Button
                    marginBottom={25}
                    marginTop={70}
                    onPress={loading ? () => { } : coinTransfer}>
                    {loading ? <ActivityIndicator /> : t('withdraw.withdraw')}
                  </Basic_Button>
                </>
              ) : (
                <>
                  <Withdraw_Check_Container>
                    <Withdraw_Check_Title_Row>
                      <img src={transaction_check} alt="" />
                      <Withdraw_Check_Title>
                        {t('withdraw.confirmWithdraw')}
                      </Withdraw_Check_Title>
                    </Withdraw_Check_Title_Row>
                    <Withdraw_Check_Description>
                      {t('withdraw.withdrawDescription')}
                    </Withdraw_Check_Description>
                  </Withdraw_Check_Container>
                  <Transaction_Wrap>
                    <Transaction_Label>TxHash</Transaction_Label>
                    <Transaction_Hash>{txHash}</Transaction_Hash>
                  </Transaction_Wrap>
                  <Basic_Button
                    onPress={() =>
                      navigate('/transactionHistory', { state: coin.state })
                    }
                    marginBottom={25}
                    marginTop={25}>
                    {t('withdraw.confirmTransaction')}
                  </Basic_Button>
                </>
              )}
            </Main_Bottom_Box>
          </Main_Bottom>
        </Main_Box>
      </Main_Wrap>
    </Container>
  );
};

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;
const Container = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Main_Wrap = styled(View)`
  width: 100%;
  flex: 1;
`;

const Main_Box = styled(View)`
  width: 100%;
`;

const Main_Bottom = styled(View)`
  width: 100%;
  flex: 1;
  min-height: ${height - 72 - 188};
  background: #ffffff;
  width: 100%;
  flex: 1;
  padding-top: 25;
  padding-left: 20;
  padding-right: 20;
`;

const Main_Bottom_Box = styled(View)`
  width: 100%;
`;
const Token_Wrap = styled(View)`
  width: 100%;
  padding-top: 26;
  padding-bottom: 26;
  align-items: center;
`;
const Token_Balance = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 600;
  line-height: 30;
  font-size: 27;
  color: #000000;
  margin-top: 17;
  margin-bottom: 12;
`;
const Token_Balance_Won = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 17;
  line-height: 20;
  color: #979797;
`;

const Possable_Withdraw_Wrap = styled(View)`
  width: 100%;
`;
const Possable_Withdraw_Wrap_Label = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 20;
  color: #000000;
`;
const Possable_Withdraw_Wrap_balance_Wrap = styled(View)`
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const Possable_Withdraw_Wrap_balance = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 19;
  line-height: 28;
  color: #000000;
`;
const Possable_Withdraw_Wrap_balance_Symbol = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 19;
  line-height: 28;
  color: #000000;
`;

const Input_Area = styled(View)`
  width: 100%;
  margin-top: 25;
  padding-top: 25;
  border-top-width: 0.8;
  border-top-color: #e7e7e7;
`;

const Frame_Top_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 20;
  color: #000000;
`;

const Frame_Current_Password = styled(View)`
  width: 100%;
  flex-direction: row;
  margin-top: 15;
`;

const Frame_Current_Password_Input_Wrap = styled(View)`
  width: ${width - 20 - 15 - 90 - 20};
  flex-direction: row;
`;

const Frame_Current_Password_Change_Button = styled(View)`
  justify-content: center;
  align-items: center;
  margin-left: 15;
  height: 55;
  border-radius: 12;
  border-width: 1;
  width: 90;
`;

const Basic_Button_Wrap = styled(View)`
  width: 320;
`;

const Frame_Current_Password_Change_Button_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 14;
  line-height: 14;
  color: orange;
`;

const Send_Speed_Wrap = styled(View)`
  width: 100%;
  margin-top: 35;
`;
const Send_Speed_Label = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 20;
  color: #000000;
`;
const Send_Speed_Radio_Wrap = styled(View)`
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-top: 25;
`;

const Send_Speed_Radio_Button = styled(TouchableOpacity)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 20;
`;
const Send_Speed_Radio_Label = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 18;
  text-transform: capitalize;
  color: #000000;
`;
const Request_Container = styled(View)`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 326px;
`;
const Request_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 15;
  line-height: 20;
  text-align: center;
  color: #1e1e1e;
`;

const Withdraw_Check_Container = styled(View)`
  width: 100%;
`;
const Withdraw_Check_Title_Row = styled(View)`
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20;
`;
const Withdraw_Check_Title = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 15;
  line-height: 20;
  margin-left: 10;
  color: #000000;
`;
const Withdraw_Check_Description = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 20;
  color: #979797;
`;

const Transaction_Wrap = styled(View)`
  padding-left: 20;
  padding-right: 20;
  padding-top: 20;
  padding-bottom: 20;
  margin-top: 45;
  background: #f4f4f4;
  border-radius: 12;
`;

const Transaction_Label = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 20;
  color: #979797;
`;

const Transaction_Hash = styled(Text)`
  width: 100%;
  margin-top: 15;
  padding-top: 15;
  border-top-width: 0.8;
  border-top-color: #636363;
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 20;
  color: #1e1e1e;
`;

const QR_Scan_Modal = styled(Modal)``;

const QR_Wrap = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #00000050;
`;

const QR_Inner = styled(View)`
  width: 70%;
  height: 60%;
`;
// modalView: {
//   margin: 20,
//   backgroundColor: 'white',
//   borderRadius: 20,
//   padding: 30,
//   alignItems: 'center',
//   shadowColor: '#000',
//   shadowOffset: {
//     width: 0,
//     height: 2,
//   },
//   shadowOpacity: 0.25,
//   shadowRadius: 4,
//   elevation: 5,
//   width: '80%',
//   overflow: 'hidden',
// },

const FindAddress_Row = styled(View)`
  width: 100%;
  align-items: flex-end;
  margin-bottom: -15;
  padding-right: 10;
`;
const FindAddress_Button = styled(TouchableOpacity)`
  padding-inline: 7;
  padding-block: 7;
`;
const FindAddress_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 20;
  color: #1e1e1eb9;
`;

const Find_Address_Modal = styled(Modal)``;

const Find_Address_Wrap = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #00000050;
`;

const Find_Address_Box = styled(View)`
  width: ${_ => (isBrowser ? 375 : width)};
  border-radius: 15;
  padding-inline: 10;
  padding-block: 10;
  margin-bottom: 20;
  background-color: #ffffff;
`;

const Find_Address_Input_Box = styled(View)`
  width: 78%;
  margin-right: 2%;
`;

const Find_Address_Button = styled(TouchableOpacity)`
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 55;
  border-width: 1;
  border-color: '#1D1D56';
  border-radius: 20;
`;
const Find_Address_Button_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 20;
  color: #1d1d56;
`;
const Find_Address_Input_Row = styled(View)`
  flex-direction: row;
  align-items: center;
`;

const Find_Address_Table = styled(View)`
  width: 100%;
`;

const Find_Address_No_Wrap = styled(View)`
  width: 100%;
  height: 200;
  align-items: center;
  justify-content: center;
`;

const Find_Address_No_Wrap_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 20;
  margin-top: -20;
  color: #1d1d56;
`;

const Find_Address_Table_Top_Row = styled(View)`
  width: 100%;
  height: 200;
  align-items: center;
  margin-top: 10;
  margin-bottom: 20;
`;

const Find_Address_Table_Top_Cell = styled(View) <Interface_All>`
  width: 100%;
  height: 30;
  border-right-width: ${props => props.borderRight || 0};
  border-bottom-width: ${props => props.borderBottom || 0};
  padding-block: 8;
  align-items: center;
  margin-bottom: ${props => props.marginBottom || 0};
`;
const Find_Address_Table_Top_Cell_Text = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 14;
  line-height: 20;
  color: #1d1d56;
`;



const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  paragraph: {
    fontSize: 16,
    marginBottom: 40,
  },
  cameraContainer: {
    width: '80%',
    aspectRatio: 1,
    overflow: 'hidden',
    borderRadius: 10,
    marginBottom: 40,
  },
  camera: {
    flex: 1,
  },
  button: {
    backgroundColor: 'blue',
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 5,
  },
  buttonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
  },
});


export default Withdraw;
