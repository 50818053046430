import OTP from '@model/otp/OTP';

export default class OTPController {
  static async removeOTP(navigate: any) {
    try {
      await OTP.remove();
      navigate('/main');
    } catch (err: any) {
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  }

  static async setOTP(secret: string, code: string, navigate: any) {
    try {
      await OTP.set(secret, code);
      navigate('/main');
    } catch (err: any) {
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  }

  static async getQR(
    setImg: React.Dispatch<React.SetStateAction<string>>,
    setSecret: React.Dispatch<React.SetStateAction<string>>,
  ) {
    try {
      const res = await OTP.img();
      const { secret: resSecret, img: resImg } = res;
      setImg(resImg);
      setSecret(resSecret);
    } catch (err: any) {
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  }

  static async checkOTPonly(
    setOTPPossession: React.Dispatch<React.SetStateAction<boolean>>,
  ) {
    try {
      const res = await OTP.check();
      setOTPPossession(res);
      return res;
    } catch (err: any) {
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  }

  static async checkOTP(
    setOTPPossession: React.Dispatch<React.SetStateAction<boolean>>,
    setImg: React.Dispatch<React.SetStateAction<string>>,
    setSecret: React.Dispatch<React.SetStateAction<string>>,
  ) {
    try {
      const res = await OTP.check();
      setOTPPossession(res);
      if (!res) {
        this.getQR(setImg, setSecret);
      }
    } catch (err: any) {
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  }
}
