import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import styled from 'styled-components/native';
import { Interface_All } from '@interface/Interface_All';
import { LinearGradient } from 'expo-linear-gradient';
interface Basic_TextInput extends Interface_All {
  children?: any;
  disabled?: boolean;
  liner?: boolean;
  onPress?: Function;
}
export const Basic_Button = ({
  children,
  disabled,
  liner,
  onPress,
  ...style
}: Basic_TextInput) => {
  return (
    <Button_Box
      {...style}
      disabled={disabled}
      onPress={() => (onPress as () => {})()}>
      <Button_Liner
        colors={disabled ? ['#DADADA', '#DADADA'] : ['#1D1D56', '#1D1D56']}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        style={{ borderRadius: 35 }}
      >
        {liner ? (
          <Button_Inner backgroundColor={style.backgroundColor}
            style={{ borderRadius: 35 }}
          >
            <Button_Text fontColor="#02D3C7">{children}</Button_Text>
          </Button_Inner>
        ) : (
          <Button_Text>{children}</Button_Text>
        )}
      </Button_Liner>
    </Button_Box>
  );
};

const Button_Box = styled(TouchableOpacity) <Interface_All>`
  width: ${props => props.width || '100%'};
  height: 55;
  background-color: none;
  margin-top: ${props => props.marginTop || 0};
  margin-bottom: ${props => props.marginBottom || 0};
`;

const Button_Liner = styled(LinearGradient)`
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-left: 2;
  padding-right: 2;
  padding-top: 2;
  padding-bottom: 2;
  align-items: center;
`;
const Button_Inner = styled(View) <Interface_All>`
  background-color: ${props => props.backgroundColor || '#ffffff'};
  width: 100%;
  height: 100%; 
  justify-content: center;
  align-items: center;
`;

const Button_Text = styled(Text) <Basic_TextInput>`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 17;
  line-height: 17;
  color: ${props => props.fontColor || '#FFFFFF'};
`;
