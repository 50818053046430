import React, { useState, useEffect } from 'react';
import { View, Dimensions, Text } from 'react-native';
import styled from 'styled-components/native';
import TopNav from '@components/topNav/TopNav';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isBrowser, isWindows } from 'react-device-detect';
import SignController from '@controller/Sign/SignController';
import Sign from '@model/user/Sign';
import { Basic_Button } from '@components/button/Button';

const Contract = () => {
  const { t } = useTranslation('locale');
  const navigate = useNavigate();  
  const [check, setCheck] = useState(false);
  const [success, setSuccess] = useState(false);
  const [info, setInfo] = useState({
    address: {
      email: 'ghdrlfehd43',
      name: '홍길동',
      tel: '010-0101-0101',
      referal: '',
    },
  });
  const [date2, setDate2] = useState();

  const date = new Date();
  const iframePrivacyPart = () => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (date2) {
      const d = new Date(date2);
      year = d.getFullYear();
      month = d.getMonth() + 1;
      day = d.getDate();
    }

    return {
      __html: `<iframe src="/src/assets/Contract.html?name=${
        info.address.name
      }&year=${`${year}년`}&month=${`${month}월`}&date=${`${day}일`}&id=${
        info.address.email
      }&tel=${info.address.tel}&width=${
        isBrowser ? 375 : width
      }&height=${isBrowser ? 375 * 1.414 : width * 1.414}" width=${
        isBrowser ? 375 : width
      } height=${
        isBrowser ? 375 * 1.414 : width * 1.414
      } style="-webkit-transform:scale(1.00); border: none"></iframe>`,
    };
  };
  const [iframe, setIframe] = useState(
    <div dangerouslySetInnerHTML={iframePrivacyPart()}></div>,
  );

  const getInfo = () => SignController.info(setInfo);  

  const getContractCheck = async () => {
    try {
      const result = await Sign.getContractCheck();
      setCheck(!result.isCheck);
      setSuccess(result.isCheck);
      setDate2(result.timeAt);
    } catch (err) {}
  };
  const registContract = async () => {
    try {
      await Sign.registContract();
      getInfo();      
      getContractCheck();
      alert(t('settings.successSign'));
    } catch (err: any) {
      if (err.response) {
        alert(err.response.data.message);
      } else if (err.message) {
        alert(err.message);
      }
    }
  };

  useEffect(() => {
    getInfo();
    
    getContractCheck();
  }, []);

  useEffect(() => {
    setIframe(<div dangerouslySetInnerHTML={iframePrivacyPart()}></div>);
  }, [info]);
  return (
    <Container>
      <TopNav navigate={navigate}>{t('settings.contract')}</TopNav>
      <Main_Wrap>
        <AppBrowserRouter>{iframe}</AppBrowserRouter>
        {check && (
          <Basic_Button marginTop={20} onPress={registContract}>
            {t('settings.sign')}
          </Basic_Button>
        )}
        {success && (
          <View
            style={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <Success style={{ color: 'green' }}>계약이 완료되었습니다.</Success>
          </View>
        )}
      </Main_Wrap>
    </Container>
  );
};
const width = Dimensions.get('window').width - 20;
const Container = styled(View)`
  flex: 1;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
`;

const Main_Wrap = styled(View)`
  width: 100%;
  flex: 1;
  padding-top: 30;
  padding-inline: 10;
`;

const AppBrowserRouter = styled(View)`
  height: ${_ => (isBrowser ? 375 * 1.414 : width * 1.414)};
  width: ${_ => (isBrowser ? 375 : width)};
  background-color: #6b6b6bcf;
  border-width: 1;
  overflow: hidden;
`;

const Success = styled(Text)`
  font-family: 'Noto Sans KR, Apple SD Gothic Neo, sans-serif';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
`;
export default Contract;
